import React from 'react';
import GridColumn from './gridColumn.component';
const GridRow = (props) => {
     function open() {
          props.open(props.rowData.id)
     }

     function handleDelete() {
          props.delete(props.rowData.id)
     }

     return (
          <tr onClick={() => props.dataFromRow(props.rowData)} >
               {props.gridCouumn.map(coulmn => (
                    <GridColumn
                         type={coulmn.type}
                         enums={coulmn.enums}
                         listSelect={coulmn.listSelect}
                         coulmn={props.rowData[coulmn.title]}
                         open={open}
                         delete={handleDelete}
                         noDelete={props.noDelete}
                         noEdit={props.noEdit}
                         isActive={props.isActive}
                    />
               ))}
          </tr>
     );
}
export default GridRow;
