import React, { useEffect, useState } from 'react'
import CustomerService from '../../../services/customer.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../../formDynamic/formDynamic'
import { CustomerTemplate } from './customerTemplate.tsx'

const Customer = (props) => {
    const { close, state } = props;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({});

    const handleClose = (id,firstName, lastName,cellPhoneNumber,code) => {
        close(id,firstName, lastName,cellPhoneNumber,code);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const send = () => {
        setLoading(true)
        if (state.id) {
            CustomerService.Update(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        //console.log('send',response.response)
                        enqueueSnackbar('ویرایش شد', { variant: 'success' })
                        handleClose(response.response.id, response.response.firstName, response.response.lastName,response.response.cellPhoneNumber,response.response.code)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
        else {
            CustomerService.Create(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        //console.log('send',response)
                        enqueueSnackbar(`مراجعه کننده ${response.response.firstName} ${response.response.lastName} ذخیره شد`, { variant: 'success' })
                        handleClose(response.response.id, response.response.firstName, response.response.lastName,response.response.cellPhoneNumber,response.response.code)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    const get = () => {
        setLoading(true)
        CustomerService.Get(state.id)
            .then(response => {
                setLoading(false)
                setInputsData(response.data.result);
            },
                error => {
                    setLoading(false)
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }

    useEffect(() => {
        if (state.state) {
            get()
        }
        return () => {
            setInputsData({});
            setLoading(false)
            return "";
        }
    }, [state]);

    return (
        <FormDynamic
            formTitle={"اضافه کردن مراجعه کننده"}
            inputs={CustomerTemplate}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default Customer


