import axios from 'axios';
import authHeader from './auth-header';
import ApiUrl from './api-url';
import { organizationId } from './organizationConfig';

const API_URL = ApiUrl() + 'services/app/Product';

class ProductService {

    GetAll(skipCount, maxResultCount, search, isActive, soldOut) {
        return axios.get(API_URL + "/GetAll?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount  + "&isActive=" + isActive + "&soldOut=" + soldOut+ "&search=" + search, { headers: authHeader() });
    }
    GetAllPublic(skipCount, maxResultCount, productTypeId, soldOut, search) {
        //console.log('GetAllPublic', productTypeId, soldOut, search)
        return axios.get(API_URL + "/GetAllPublic?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&organizationId=" + organizationId() + "&isActive=true&productTypeId=" + productTypeId + "&soldOut=" + soldOut + "&search=" + search, { headers: authHeader() });
    }

    Create(model) {
        return axios.post(API_URL + '/Create', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }

    SoldOut(id) {
        return axios.post(API_URL + '/SoldOut', { id: id }, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }

    Update(model) {
        return axios.put(API_URL + '/Update', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Get(id) {
        return axios.get(API_URL + "/Get?id=" + id, { headers: authHeader() });
    }
    Delete(id) {
        return axios.delete(API_URL + '/Delete?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }

    GetAllProductType(skipCount, maxResultCount, search, isActive) {
        return axios.get(API_URL + "/GetAllProductType?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&search=" + search + "&isActive=" + isActive, { headers: authHeader() });
    }
    GetAllProductTypePublic() {
        return axios.get(API_URL + "/GetAllProductTypePublic?skipCount=0&maxResultCount=20&organizationId=" + organizationId() + "&isActive=true", { headers: authHeader() });
    }

    CreateImage(model) {
        return axios.post(API_URL + '/CreateImage', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }

    DeleteImage(id) {
        return axios.delete(API_URL + '/DeleteImage?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }
}

export default new ProductService();