import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { GetDirection, getLocalizationTitle } from '../../localization/localization';
import { useSnackbar } from 'notistack';
import Button from '../../button/button';
import BackButton from '../../button/backButton';
import ProductService from '../../../services/product.service'
import QuestionDialog from '../../alertsDialog/QuestionDialog';

const ProductImages = (props) => {
    const { close, state, inputs } = props;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = (id) => {
        close(id);
    };

    const deleteImage = (id) => {
        console.log(id)
        ProductService.DeleteImage(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                                   }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const [stateDeleteDialog, setStateDeleteDialog] = useState(
        {
            open: false,
            content: getLocalizationTitle("AreYouDelete"),
            falseButton: getLocalizationTitle("No"),
            trueButton: getLocalizationTitle("Yes"),
            id: 0
        });
    function handleDeleteDialogOpen(id) {
        setStateDeleteDialog(state => ({ ...state, open: true, id: id }))
    }
    function handleDeleteDialogCloseFalseState() {
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }

    function handleDeleteDialogCloseTrueState() {
        deleteImage(stateDeleteDialog.id)
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }

    useEffect(() => {
        if (state.id && state.state) {

        }
        return () => {
            return "";
        }
    }, [state.id]);

    return (
        <Dialog fullWidth={true} open={props.state.state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => handleClose()}
                />
                <div>{props.formTitle}</div>
                <div></div>
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                {(state.productFiles?.length > 0) ? (state.productFiles?.map((imageItem, imageIndex) => (
                    <div className="homeProductImage">
                        <img src={`data:image/jpeg;base64,${imageItem.imageFileData}`} onClick={() => handleDeleteDialogOpen(imageItem.id)} />
                    </div>
                ))) : null}
            </div>
            <QuestionDialog
                questionState={stateDeleteDialog}
                handleTrue={handleDeleteDialogCloseTrueState}
                handleFalse={handleDeleteDialogCloseFalseState}
            />
        </Dialog>
    );
}

export default ProductImages


