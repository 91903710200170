import entityTemplateDto from '../entityTemplateDto'

export const ProductImageTemplate: entityTemplateDto[] = [
    {
        title: "imageFileData",
        type: "image",
        fileId: "imageId",
        label: "تصویر",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "fileType", types: "jpg jpeg" }],
        showForm: true,
        showEdit: false,
        showGrid: true,
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        filter: {},
        minWidth: "80px",
        showGrid: true
    }
];