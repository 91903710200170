import React, { useEffect, useState } from 'react'
import { organizationId } from '../../services/organizationConfig';
import './platformMajPictureZoom.css'
import { useSelector } from 'react-redux'

const PlatformMajPictureZoom = (props) => {
    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    
    const [height, setHeight] = useState(((props.item.length * props.item.distance) - props.item.distance))

    const itemScrollHeight = () => {
        return height + innerSize.height;
    };

    const setId = () => {
        return `pmAnimationLogo${props.item.id}`;
    };

    const setScrollItem = () => {
        return Math.floor(((scrollY - startItem()) / props.item.distance)) - 1;
    };

    const startItem = () => {
        return document.getElementById(setId())?.offsetTop;
    };
    const EndItem = () => {
        return startItem() + height;
    };

    const [justifyContent, setJustifyContent] = useState("flex-start")//flex-end//flex-start
    const [position, setPosition] = useState("fixed")//fixed//static//relative//absolute//sticky
    const [frame, setFrame] = useState({
        width: innerSize.width,
        height: innerSize.height,
        borderWidth: 0
    })

    const minSizeFrame = () => {
        return props.item.minSizeFrame;
    };

    const maxSizeFrame = () => {
        return innerSize;
    };

    const distanceMaxAndMin = () => {
        return {
            width: maxSizeFrame().width - minSizeFrame().width,
            height: maxSizeFrame().height - minSizeFrame().height
        };
    }

    const distanceBetween = () => {
        return {
            width: distanceMaxAndMin().width / props.item.length,
            height: distanceMaxAndMin().height / props.item.length
        }
    }

    const handleMaxFrame = () => {
        return {
            width: innerSize.width,
            height: innerSize.height,
            borderWidth: 0
        };
    }

    const handleMinFrame = () => {
        return handleSetFrame(props.item.length - 2);
    }

    const handleSetFrame = (item) => {
        let width = (maxSizeFrame().width - (item * distanceBetween().width));
        let height = (maxSizeFrame().height - (item * distanceBetween().height));
        let borderWidthX = ((item * distanceBetween().width) / 2);
        let borderWidthY = ((item * distanceBetween().height) / 2);
        //console.log(width,borderWidthX,innerSize.width)
        return {
            width: width,
            height: height,
            borderWidth: borderWidthX + 0.5,
            borderHeight: borderWidthY + 1,
        };
    }

    const [filterGrayscale, setFilterGrayscale] = useState(0)

    useEffect(() => {
        //console.log('PlatformMajPictureZoom', innerSize.width)
        if (scrollY < startItem()) {
            setPosition("relative")
            setJustifyContent("flex-start")
            setFrame(handleMaxFrame())
            setFilterGrayscale(0)
        }
        else if (scrollY > EndItem()) {
            setPosition("relative")
            setJustifyContent("flex-end")
            setFrame(handleMinFrame())
            setFilterGrayscale(100)
        }
        else {
            setPosition("fixed")
            setJustifyContent("flex-start")
            setFrame(handleSetFrame(setScrollItem()))
            setFilterGrayscale(((setScrollItem() + 3) * 100) / props.item.length)
        }
        return () => {
            return "";
        }
    }, [scrollY, innerSize]);

    return (
        <div
            className="pmItemScrollContainer"
            style=
            {{
                height: itemScrollHeight(),
                minHeight: itemScrollHeight(),
                justifyContent: justifyContent,
                backgroundColor: props.item.backColor,
                position: "relative"
            }}
            id={setId()} >
            {(props.item.title || props.item.description) && (
                <div className=" pmPictureZoomTitle">
                    {props.item.title && (<h2>{props.item.title}</h2>)}
                    <div
                        dangerouslySetInnerHTML={{ __html: props.item.description }}
                    />
                </div>
            ) }
            <div className="pmItemContainer pmPictureZoomContainer"
                style={{
                    position: position,
                    height: innerSize.height,//, zIndex:-7 //props.item.index
                    backgroundImage: `url(organization${organizationId()}/${props.item.id}${props.item.fileFormat})`,
                    filter: `grayscale(${filterGrayscale}%)`,
                    webkitFilter: `grayscale(${filterGrayscale}%)`
                }}
            >
                {(!(scrollY < startItem() + 200) && !(scrollY > EndItem() + innerSize.height)) ? (
                    <div className="pmPictureZoomFrame"
                        style={{
                            width: frame.width,
                            height: frame.height,
                            borderWidth: `${frame.borderHeight}px ${frame.borderWidth}px`,// frame.borderWidth,
                            borderColor: props.item.backColor,
                            zIndex: 2
                        }}>
                    </div>
                ) : (null)}
                {(!(scrollY < startItem() + 200) && !(scrollY > EndItem() + innerSize.height)) ? (
                    <div
                        className="pmPictureZoomFrame2"
                        style={{
                            width: frame.width,
                            height: frame.height,
                            borderWidth: "20px",
                            borderColor: props.item.backColor,
                            borderRadius: "40px",
                            borderStyle: "solid",
                            zIndex: 3,
                            top: (frame.borderHeight) - 20,
                            left: (frame.borderWidth) - 20
                        }}></div>
                ) : (null)}
                {(props.item.logo ) ? (
                    <img src={`organization${organizationId()}/${props.item.logo}`}
                        style={{
                            width: 250,
                            height: 250,
                            opacity:(scrollY > (startItem() + 400))?1:0,
                            transition: "1s"
                        }}
                    ></img>
                ) : (null)}
            </div>
        </div>
    )
}
export default PlatformMajPictureZoom
