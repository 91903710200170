export const checkAllValidations = (value, validations, field) => {
    if (validations) {
        let result = {
            error: false,
            message: ""
        };
        let e = {
            error: false,
            message: ""
        };
        validations.forEach((validation, index) => {
            switch (validation.type) {
                case "Required":
                    e = RequiredValidation(value, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "MaxLength":
                    e = MaxLengthValidation(value, validation.length, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "MinLength":
                    e = MinLengthValidation(value, validation.length, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "MaxRange":
                    e = MaxRangeValidation(value, validation.length, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "MinRange":
                    e = MinRangeValidation(value, validation.length, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "Email":
                    e = EmailValidation(value, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                case "JustNumber":
                    e = JustNumber(value, field)
                    if (e.error) {
                        result = e;
                    }
                    break;
                default:
                    result = {
                        error: false,
                        message: ""
                    };
            }

        })
        return result;
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const RequiredValidation = (value, field) => {
    //console.log('RequiredValidation.field:',value, field)
    if (!value) {
        return {
            error: true,
            message: `${field ? field : "This field"} is required!`
        };
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const MaxLengthValidation = (value, length, field) => {
    //console.log('MaxLengthValidation.field:', field)
    if (value) {
        if (value.length > length) {
            return {
                error: true,
                message: `Maximum length of ${field ? field : "this field"} is ${length} characters`
            };
        }
        else {
            return {
                error: false,
                message: ""
            };
        }
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const MinLengthValidation = (value, length, field) => {
    //console.log('MinLengthValidation.field:', field)
    if (value) {
        if (value.length < length) {
            return {
                error: true,
                message: `Minimum length of ${field ? field : "this field"} is ${length} characters`
            };
        }
        else {
            return {
                error: false,
                message: ""
            };
        }
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const MaxRangeValidation = (value, length, field) => {
    //console.log('MaxRangeValidation.field:', field)
    if (value) {
        if (value > length) {
            return {
                error: true,
                message: `Maximum Range of ${field ? field : "this field"} is ${length} characters`
            };
        }
        else {
            return {
                error: false,
                message: ""
            };
        }
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const MinRangeValidation = (value, length, field) => {
    //console.log('MinRangeValidation.field:', field)
    if (value) {
        if (value < length) {
            return {
                error: true,
                message: `Minimum Range of ${field ? field : "this field"} is ${length} characters`
            };
        }
        else {
            return {
                error: false,
                message: ""
            };
        }
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const EmailValidation = (value, field) => {
    if (!value) {
        return {
            error: false,
            message: ""
        };
    }
    else if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
        return {
            error: true,
            message: `value of ${field ? field : "This field"} is not email!`
        };
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const JustNumber = (value, field) => {
    if (!value) {
        return {
            error: false,
            message: ""
        };
    }
    else if (!new RegExp(/^\d+$/).test(value)) {
        return {
            error: true,
            message: `value of ${field ? field : "This field"} is not Number!`
        };
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}

export const ConfirmPasswordValidation = (value1, value2) => {
    if (!value1) {
        return {
            error: false,
            message: ""
        };
    }
    else if (value1 !== value2) {
        return {
            error: true,
            message: `You have to confirm your email address.`
        };
    }
    else {
        return {
            error: false,
            message: ""
        };
    }
}