import entityTemplateDto from '../entityTemplateDto'

export const ProductTemplate: entityTemplateDto[] = [
    {
        title: "title",
        type: "text",
        label: "عنوان",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }, { type: "Required" }],
        showForm: true,
        showGrid: true
    },
    {
        title: "model",
        type: "text",
        label: "مدل",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }],
        showForm: true,
        showGrid: true
    },
    {
        title: "price",
        type: "currency",
        label: "قیمت",
        showList: 1,
        filter: {},
        minWidth: "70px",
        showForm: true,
        showGrid: true
    },
    {
        title: "description",
        type: "text",
        label: "توضیحات",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }],
        showForm: true,
        showGrid: false
    },
    {
        title: "link",
        type: "text",
        label: "آدرس",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }],
        showForm: true,
        showGrid: false
    },
    {
        title: "productTypeId",
        type: "enum",
        label: "نوع",
        filter: {},
        enums: [
            { value: 4, text: "پذیرایی" },
            { value: 5, text: "اتاق خواب" },
            { value: 6, text: "6" },
            { value: 7, text: "زیبایی" },
            { value: 8, text: "آشپزخانه برقی" },
            { value: 9, text: "ظروف پذیرایی" },
            { value: 10, text: "ظروف آشپزخانه" },
            { value: 11, text: "سرگرمی و ورزش" },
            { value: 12, text: "برقی" },
            { value: 13, text: "13" },
            { value: 14, text: "14" },
            { value: 15, text: "15" },
            { value: 16, text: "16" },
            { value: 17, text: "17" },
            { value: 18, text: "18" },
            { value: 19, text: "19" },
            { value: 20, text: "کالاهای دیگر" },
        ],
        showForm: true,
        showGrid: true
    },
    {
        title: "soldOut",
        type: "enum",
        label: "فروخته شده",
        filter: {},
        enums: [
            { value: true, text: "فروخته شده" },
            { value: false, text: "فروخته نشده" }
        ],
        showGrid: true
    },
    {
        title: "imageFileData",
        type: "image",
        fileId: "imageId",
        label: "تصویر",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "fileType", types: "jpg jpeg" }],
        showForm: true,
        showEdit: false,
        showGrid: true,
    },
    /* {
         title: "productFiles",
         type: "images",
         fileId:"imageId",
         label: "تصاویر",
         filter: {},
         showList: 0,
         minWidth: "130px",
         validations: [{ type: "fileType", types:"jpg jpeg" }],
         showForm: false,
         showEdit: false,
         showGrid: true,
     },*/
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        filter: {},
        minWidth: "80px",
        showGrid: true
    }
    ,
    {
        title: "image",
        type: "editColumnCustom",
        showList: 1,
        label: "تصاویر",
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }
];