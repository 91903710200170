import entityTemplateDto from '../entityTemplateDto'

export const ImageTemplate: entityTemplateDto[] = [
    {
        title: "takeDateTime",
        type: "date",
        label: "تاریخ تصویر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "groupId",
        type: "select",
        label: "گروه",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showForm: true,
        showGrid: true,
        listSelect: [
            //{ value: 1, text: "مطب" },
            //{ value: 2, text: "قبل و بعد" },
            //{ value: 3, text: "بلیچینگ" },
            //{ value: 4, text: "مراجعه کنندگان" },
            //{ value: 5, text: "کامپوزیت" },
            //{ value: 6, text: "دکتر" },
            { value: 7, text: "پکیج‌های اموزشی طراحی" },
            { value: 8, text: "پکیج ژل سالنی" },
            { value: 9, text: "خدمات" },
            { value: 10, text: "طراحی چهره" }
        ]
    },
    {
        title: "isPublic",
        type: "enum",
        label: "دسترسی",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showForm: true,
        showGrid: true,
        enums: [
            { value: 1, text: "عمومی" },
            { value: 0, text: "خصوصی" },
        ]
    },
    {
        title: "fileData",
        type: "image",
        fileId: "imageId",
        label: "تصویر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "fileType", types: "jpg jpeg" }],
        showForm: true,
        showGrid: true,
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true,
    },
];