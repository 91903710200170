import entityTemplateDto from '../../entityTemplateDto'

export const CustomerImageTemplate: entityTemplateDto[] = [
    {
        title: "takeDateTime",
        type: "date",
        label: "تاریخ تصویر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "fileData",
        type: "image",
        fileId: "fileId",
        label: "تصویر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "fileType", types: "jpg jpeg" }],
        showForm: true,
        showGrid: true,
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true,
    },
    {
        title: "image",
        type: "editColumnCustom",
        showList: 1,
        label: "نمایش تصویر",
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }
];