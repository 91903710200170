import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Button from '../components/button/button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const ShowExperienceWithScrollProject = (props) => {

    useEffect(() => {
        return () => {
            return "";
        }
    }, [props.state]);

    return (
        <Dialog open={props.state.state} >
            {/* <div style={{
                    margin: '10px',
                }}>
                    <Button
                        icon={<ArrowBackIosIcon />}
                        click={() => props.close()}
                        label="Back"
                    />
                </div> */}
            <div className="dialogHeader ShowExperienceWithScrollProjectHeader"
                >
                <div>
                    <Button
                        icon={<ArrowBackIosIcon />}
                        click={() => props.close()}
                        label="Back"
                    />
                </div>
                <div>
                
                </div>
                <div>

                </div>
            </div>
            <div 
            // onClick={() => props.close()}
                className="ShowExperienceWithScrollProjectContainer"
                style={{
                    marginTop: '50px',
                }}
                >
                {props.state.tags}
            </div>
        </Dialog>
    );
}
export default ShowExperienceWithScrollProject