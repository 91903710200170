import React from 'react'
import Login from '../components/login/Login'
import Register from '../components/login/Register'
import Home from '../components/home/Home'
import Organization from '../components/dashbourd/organization/organization'
import Dashboard from '../components/dashbourd/Dashboard'
import Customers from '../components/dashbourd/customer/customers'
import Visitors from '../components/dashbourd/visotor/visitors'
import Images from '../components/dashbourd/image/images'
import Users from '../components/dashbourd/user/users'
import DailyDashbourd from '../components/dashbourd/dailyDashbourd/dailyDashbourd'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GetDirection } from '../components/localization/localization'
import { GetTheme } from '../components/theme/theme'
import Roles from '../components/dashbourd/role/roles'
import Products from '../components/dashbourd/product/products'
import Blogs from '../components/dashbourd/blog/blogs'
import Notes from '../components/dashbourd/note/notes'

const routes = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/home",
    component: Home
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/dashboard",
    component: Dashboard,
    routes: [
      {
        path: "/dashboard/organization",
        component: Organization
      }
      ,
      {
        path: "/dashboard/customer",
        component: Customers
      }
      ,
      {
        path: "/dashboard/visitor",
        component: Visitors
      }
      ,
      {
        path: "/dashboard/dailyDashbourd",
        component: DailyDashbourd
      }
      ,
      {
        path: "/dashboard/image",
        component: Images
      }
      ,
      {
        path: "/dashboard/user",
        component: Users
      }
      ,
      {
        path: "/dashboard/role",
        component: Roles
      }
      ,
      {
        path: "/dashboard/product",
        component: Products
      }
      ,
      {
        path: "/dashboard/blog",
        component: Blogs
      }
      ,
      {
        path: "/dashboard/note",
        component: Notes
      }
    ]
  }
];

export default class Routing extends React.Component {
  constructor(props) {
    super(props);
    //console.log('GetTheme() Routing',GetTheme())
  }
  
  render() {
    return (
      <div className={`myApp theme${GetTheme()}`} dir={GetDirection()}/* theme={GetTheme()}*/>
        <Router>
          <div>
            <Switch>
              <Route exact path="/" component={Home} />
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}