import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from '@material-ui/core/Select';
import './visitTime.css'
import Button from '../../button/button';

const VisitTimeVisits = (props) => {
    const [valueSelect, setValueSelect] = useState('');
    const { setInputsValue } = props;

    const sendValueToInputs = (workItId) => {
        setInputsValue(workItId, 0)
    }

    const changeNumberTasks = (workItId, operator) => {
        setInputsValue(workItId, operator)
    }

    useEffect(() => {
        return () => {
            return "";
        }
    }, [props.visits]);

    return (
        <div>
            <div className="SelectVisitTimeVisitsContainer">
                <Select className="SelectVisitTimeVisits"
                    size="small"
                    displayEmpty
                    value={valueSelect}
                    id={"visitsSelect"}
                    onChange={e => sendValueToInputs(e.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={props.loading}
                >
                    <MenuItem value="" style={{ display: "none" }} disabled>
                        انتخاب وظیفه:
                    </MenuItem>
                    {props.workIts
                        .filter(w => !(props.visits.find(v => v.workItId == w.id && v.numberTasks > 0)))
                        .map(item => (
                            <MenuItem key={item.id} value={item.id} >{item.fullTitle}</MenuItem>
                        ))}
                </Select>
                {props.visits?.filter(v => v.numberTasks > 0).map(item => (
                    <div style={{ margin: "5px", display: "inline-block" }}>
                        <Button
                            icon={<AddIcon />}
                            deleteIcon={<RemoveIcon />}
                            label={`${item.workIt?.title} تعداد: ${item.numberTasks} مبلغ:${item.axpenseAmount}`}
                            click={() => changeNumberTasks(item.workItId, 1)}
                            delete={() => changeNumberTasks(item.workItId, -1)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VisitTimeVisits;