import entityTemplateDto from "../dashbourd/entityTemplateDto";
import { themeList } from "../theme/theme";

export const UserMyselfTemplate: entityTemplateDto[] = [
    {
        title: "theme",
        type: "enumColor",
        label: "رنگ",
        enums: themeList(),
        showForm: true,
    },
    {
        title: "language",
        type: "enum",
        label: "زبان",
        enums: [
            { value: "fa", text: "Persian" },
            { value: "en", text: "English" },
        ],
        showForm: true,
    },
    {
        title: "image",
        type: "image",
        fileId:"imageId",
        label: "تصویر",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "fileType", types:"jpg jpeg" }],
        showForm: true,
    }
];