import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import './dailyDashbourd.css'
import VisitTimeService from '../../../services/visitTime.service'
import CloseIcon from '@material-ui/icons/Close';
import VisitorService from '../../../services/visitor.service'
import FormDynamicInputDate from '../../formDynamic/formDynamicInputDate'
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SelectCustomer from './selectCustomer'
import AddIcon from '@material-ui/icons/Add';
import PersonAdd from '@material-ui/icons/PersonAdd';
import FormDynamicInputSelect from '../../formDynamic/formDynamicInputSelect'
import Customer from '../customer/customer'
import Button from '../../button/button';
import { getLocalizationTitle, LocalizationJson } from '../../localization/localization';
import QuestionDialog from '../../alertsDialog/QuestionDialog';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

let curentDate = new Date();
//let curentDate = new Date('2021-07-17');

const dateToFormat = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

const DailyDashbourd = (props) => {
    const [content, setContent] = useState([]);

    const [hours, setHours] = useState([
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30'
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const [dateDashbourd, setDateDashbourd] = useState(dateToFormat(curentDate));
    const [loading, setLoading] = useState(true);


    const getAll = (date, visitorIdInput) => {
        setLoading(true)
        VisitTimeService.GetAllByDate(date, visitorIdInput).then(
            response => {
                //console.log('VisitTimeService.GetAllByDate', response.data.result.items)
                setContent(response.data.result.items)
                setLoading(false)

            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const HandleSetVisitorId = (visitorIdInput) => {
        setVisitorId(visitorIdInput)
        //getAll(dateDashbourd, visitorIdInput)
    }

    const send = (customerId, firstName, lastName, hour, cellPhoneNumber, code) => {
        VisitTimeService.Create({
            visitorId: visitorId,
            customerId: customerId,
            accessibility: 1,
            date_Time: `${dateDashbourd} ${hour}:0`
        }).then(
            (response) => {
                if (response.success) {
                    //console.log('response VisitTimeService.Create', response.response.id, response.success)
                    enqueueSnackbar(`ساعت برای ${firstName} ${lastName} ذخیره شد`, { variant: 'success' })
                    //getAll(dateDashbourd, visitorId)

                    if (response.response.id) {
                        setContent(oldArray => [...oldArray, {
                            time: hour,
                            customer: {
                                firstName: firstName,
                                lastName: lastName,
                                id: customerId,
                                cellPhoneNumber: cellPhoneNumber,
                                code: code
                            },
                            customerId: customerId,
                            id: response.response.id,
                            visitorId: visitorId
                        }])
                    }
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }
    const [selectCustomerState, setSelectCustomerState] = useState(false);
    const [selectHour, setSelectHour] = useState(0);

    const openSelectCustomer = (hour) => {
        setSelectCustomerState({ state: true })
        setSelectHour(hour)
    }

    const closeSelectCustomer = (customerId, firstName, lastName, cellPhoneNumber, code) => {
        //console.log(customerId, selectHour)
        if (customerId) {
            send(customerId, firstName, lastName, selectHour, cellPhoneNumber, code)
        }
        setSelectCustomerState(false)
    }

    const [stateDeleteDialog, setStateDeleteDialog] = useState(
        {
            open: false,
            content: getLocalizationTitle("AreYouDelete"),
            falseButton: getLocalizationTitle("No"),
            trueButton: getLocalizationTitle("Yes"),
            id: 0
        });
    function handleDeleteDialogOpen(id) {
        setStateDeleteDialog(state => ({ ...state, open: true, id: id }))
    }
    function handleDeleteDialogCloseFalseState() {
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }
    function handleDeleteDialogCloseTrueState() {
        deleteVisitTime(stateDeleteDialog.id)
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }
    function showDetail(code, cellPhoneNumber) {
        enqueueSnackbar(`شماره:${code} - شماره تماس:${cellPhoneNumber}`, { variant: 'success' })
    }
    const deleteVisitTime = (id) => {
        if (id) {
            //setLoading(true)
            //console.log(id)
            VisitTimeService.Delete(id).then(
                (response) => {
                    if (response.success) {
                        enqueueSnackbar('حذف شد', { variant: 'success' })
                        //getAll(dateDashbourd, visitorId)
                        setContent(content.filter(item => item.id !== id));
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
        else {
            //console.log('در حال ذخیره سازی')
            enqueueSnackbar('در حال ذخیره سازی', { variant: 'error' })
        }
    }

    const handleSetDateDashbourd = (target, property) => {
        //console.log(target, property)
        setDateDashbourd(dateToFormat(target))
    };
    const nextDateDashbourd = (input) => {
        //console.log(input, dateDashbourd)
        setDateDashbourd(dateToFormat(new Date(new Date(dateDashbourd).getTime() + (24 * 60 * 60 * 1000 * input))))
    };

    const [visitorId, setVisitorId] = useState(0);
    const [visitors, setVisitors] = useState([]);
    const getAllVisitor = (skipCount, maxResultCount) => {
        VisitorService.GetAll(0, 200, '', true).then(
            response => {
                setVisitors(response.data.result.items.map((item, index) => ({
                    value: item.id,
                    index: index,
                    text: LocalizationJson(item.specialty) + ' ' + LocalizationJson(item.firstName) + ' ' + LocalizationJson(item.lastName),
                    image: `data:image/jpeg;base64,${item.imageFileData}`
                })));

                setVisitorId(response.data.result.items[((response.data.result.items?.length - 1)??0)]?.id)
                //getAll(dateDashbourd, response.data.result.items[(response.data.result.items.length - 1)].id)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }
    function getVisitorImage(id) {
        //console.log(id)
        //console.log(visitors.filter(row => row.value==id)[0]?.image)
        return visitors.filter(row => row.value == id)[0]?.image;
    }

    const [stateCustomer, setStateCustomer] = useState({ state: false, id: 0 });

    function openCustomer(hour) {
        //console.log('open id', id)
        setStateCustomer({ state: true, id: 0 })
        setSelectHour(hour)
    }
    function closeCustomer(customerId, firstName, lastName, cellPhoneNumber, code) {
        setStateCustomer({ state: false, id: 0 });
        if (customerId) {
            send(customerId, firstName, lastName, selectHour, cellPhoneNumber, code)
        }
    }

    useEffect(() => {
        /*if (visitorId) {
            getAll(dateDashbourd, visitorId)
        }
        else {
            getAllVisitor()
        }*/
        getAll(dateDashbourd, 0)
        if (!visitors.length) {
            getAllVisitor()
        }

        return () => {
            return "";
        }
    }, [dateDashbourd]);
    return (
        <div>
            <div className="dailyDashbourd">
                <div className="dailyDashbourdVisitorSelect">
                    <FormDynamicInputSelect
                        title={"ملاقات کننده"}
                        id={"visitorId"}
                        value={visitorId}
                        setInputsValue={HandleSetVisitorId}
                        listSelect={visitors}
                        disabled={loading}
                    //error={error.error}
                    //helperText={error.message}
                    />
                </div>
                <div className="dailyDashbourdDateSelect">
                    {!loading ? (
                        <div className="dailyDashbourdDateSelectItem">
                            <Button
                                icon={<SkipNextIcon />}
                                click={() => nextDateDashbourd(7)} />
                        </div>
                    ) : ("")}
                    {!loading ? (
                        <div className="dailyDashbourdDateSelectItem">
                            <Button
                                icon={<NavigateNextIcon />}
                                click={() => nextDateDashbourd(1)} />
                        </div>
                    ) : ("")}
                    <div className="dailyDashbourdDateSelectItem">
                        <FormDynamicInputDate
                            textAlign="center"
                            id={"date_Time"}
                            value={dateDashbourd}
                            setInputsValue={handleSetDateDashbourd}
                            disabled={loading}
                            dateFormat={"jYYYY/jMM/jDD dddd"}
                        />
                    </div>
                    {!loading ? (
                        <div className="dailyDashbourdDateSelectItem">
                            <Button
                                icon={<NavigateBeforeIcon />}
                                click={() => nextDateDashbourd(-1)} />
                        </div>
                    ) : ("")}
                    {!loading ? (
                        <div className="dailyDashbourdDateSelectItem">
                            <Button
                                icon={<SkipPreviousIcon />}
                                click={() => nextDateDashbourd(-7)} />
                        </div>
                    ) : ("")}
                </div>
            </div>
            {loading ?
                (
                    <div>
                        <div className="homeCircularProgress" >
                            <CircularProgress />
                        </div>
                    </div>
                )
                :
                (
                    <div className="dailyDashbourdHourContainer">
                        {hours.map((hourItem, hourIndex) => (
                            <div className="dailyDashbourdHourItem">
                                <div className="dailyDashbourdHourItemHeader">
                                    <div className="dailyDashbourdHourItemHeaderItem dailyDashbourdHourItemHeaderHour">
                                        <Button
                                            icon={<AddIcon />}
                                            click={() => openSelectCustomer(hourItem)}
                                            label={`${hourItem}`}
                                        />
                                    </div>
                                    <div className="dailyDashbourdHourItemHeaderItem">
                                        <Button
                                            icon={<PersonAdd />}
                                            click={() => openCustomer(hourItem)}
                                        />
                                    </div>
                                    {/*</div>
                                <div className="dailyDashbourdHourItemContent">*/}
                                    {content
                                        .filter(v => (hourItem == '07:00' && v.time <= '07:00') || (hourItem != '07:00' && v.time == hourItem))
                                        .map((item, index) => (
                                            <Tooltip title={`Code:${item.customer?.code} Phone:${item.customer?.cellPhoneNumber}`} placement="bottom">
                                                <div className="dailyDashbourdHourItemContentItem">
                                                    <Button
                                                        avatar={<Avatar alt="Natacha" src={getVisitorImage(item.visitorId)} />}
                                                        variant="outlined"
                                                        deleteIcon={<CloseIcon />}
                                                        label={`${item.customer?.firstName} ${item.customer?.lastName}`}
                                                        delete={() => handleDeleteDialogOpen(item.id)}
                                                        click={() => showDetail(item.customer?.code, item.customer?.cellPhoneNumber)}
                                                    />
                                                </div>
                                            </Tooltip>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            <SelectCustomer
                state={selectCustomerState}
                close={closeSelectCustomer}
            />
            <Customer
                state={stateCustomer}
                close={closeCustomer}
            //inputs={CustomerTemplate}
            />
            <QuestionDialog
                questionState={stateDeleteDialog}
                handleTrue={handleDeleteDialogCloseTrueState}
                handleFalse={handleDeleteDialogCloseFalseState}
            />
        </div>
    )
}

export default DailyDashbourd