import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import HomeHeaderList from './homeHeaderList'
import './headerSideBar.css'
import HeaderUser from './headerUser';
import AuthService from "../../services/auth.service";
import { isExpired } from '../../services/auth-header';
import { HistoryPushAfterLogout, organizationId } from '../../services/organizationConfig';
import { getLocalizationTitle } from '../localization/localization';

const HeaderSideBar = (props) => {
    const [stateMenu, setStateMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    let history = useHistory();
    const locationPathName = useLocation().pathname;
    const menuList = HomeHeaderList().filter(row => (props.isHome && row.showHome) || (props.isDashboard && row.showDashboard));

    const [currentUser, setCurrentUser] = useState("");
    function logOut() {
        setCurrentUser("")
        AuthService.logout();
        if (props.isDashboard) {
            history.push(HistoryPushAfterLogout());
        }
        //window.location.reload();
    }

    function logIn() {
        history.push("/login");
        //setCurrentUser("")
        //AuthService.logout();
    }
    const register = () => {
        history.push("/register");
    }

    function checkAuthentication() {
        if (isExpired()) {
            localStorage.removeItem("user");
            return false;
        }
        else {
            return true;
        }
    }

    const toggleMenu = () => {
        setStateMenu(!stateMenu)
    }

    const closeMenu = () => {
        setStateMenu(false)
    }
    const handleHome = () => {
        history.push("/home");
    }
    const handleHistoryPush = (input) => {
        history.push(input);
    }

    const handleJustSideBarAndisMobile = () => {
        //console.log('handleJustSideBarAndisMobile',((props.justSideBar ?? false) || isMobile))
        return ((props.justSideBar ?? false) || isMobile);
    }

    useEffect(() => {
        if (checkAuthentication()) {
            const user = AuthService.getCurrentUser();
            if (user) {
                setCurrentUser(user.result.userFullName)
            }
            else {
                setCurrentUser("")
            }
        }

        if (window.innerWidth < 750) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
        //console.log(("#jhdf").indexOf("#"))
        return () => {
            return "";
        }
    }, []);

    window.addEventListener('scroll', () => {
        if (window.scrollY < 100) {
            setShowMenu(true);
        }
        else {
            setShowMenu(false);
        }
    });
    window.addEventListener('resize', () => {
        if (window.innerWidth < 750) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
            setStateMenu(false);
        }
    });

    return (
        <header className={`myHeader ${showMenu ? 'myHeaderShow' : null}`}  >
            <div
                className={`${handleJustSideBarAndisMobile() ? 'MobilenavMenuIcon' : 'navMenuIcon'} ${stateMenu ? 'navMenuIconOpen' : null}`}
                onClick={() => toggleMenu()}
            >
                <div>   </div>
                <div>   </div>
                <div>   </div>
            </div>
            <div className={`navMenuLogo ${handleJustSideBarAndisMobile() ? 'MobilenavMenuLogo' : null}`} onClick={() => handleHome()}>
                <a href="#" >
                    <img src={process.env.PUBLIC_URL + `/organization${organizationId()}/headerLogo.png`} />
                </a>
            </div>
            <nav className={`${handleJustSideBarAndisMobile() ? (stateMenu ? 'MobilenavMenu' : 'MobilenavMenu navMenuClose') : 'navMenu'}`} >
                {menuList.map((item, index) =>
                    item.isLogin && !currentUser ?
                        (null) : (
                            <div className="MenuItem" onClick={() => closeMenu()}>
                                {handleJustSideBarAndisMobile() ? (item.icon) : (null)}
                                {item.href.indexOf("#") >= 0 ? (
                                    <a
                                        href={item.href}
                                    >
                                        {getLocalizationTitle(item.title)}
                                    </a>
                                ) : (
                                    <a
                                        onClick={() => handleHistoryPush(item.href)}
                                    >
                                        {getLocalizationTitle(item.title)}
                                    </a>
                                )}

                            </div>
                        ))}

            </nav>
            {!props.dontShowUser ? (<HeaderUser
                closeMenu={closeMenu}
                isDashboard={props.isDashboard}
                logOut={logOut}
                logIn={logIn}
                register={register}
                currentUser={currentUser}
                locationPathName={locationPathName}
            />): (<div className="HeaderUser" ></div>)}
        </header>
    )
}
export default HeaderSideBar
