import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import MyFileService from '../../../services/myFile.service'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../button/button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const HomeImageSepand = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [image, setImage] = useState({});
    const [loading, setLoading] = useState(false);

    const get = () => {
        //console.log('get',props.state.id)
        setLoading(true)
        MyFileService.GetImagePublic(props.state.id)
            .then(response => {
                //console.log('response',response.data.result)
                //setImage(response.data.result);
                setImage({ image: `data:image/jpeg;base64,${response.data.result.fileData}` });
                setLoading(false)
            },
                error => {
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }

    useEffect(() => {
        //console.log('useEffect',props.state.id)
        if (props.state.id && props.state.state) {
            get()
        }
        return () => {
            //console.log('return')
            setImage({});
            setLoading(false)
            return "";
        }
    }, [props.state]);

    return (
        <Dialog fullScreen open={props.state.state} className="homeImageItemPreview">
            <div className="homeImageItemPreviewBack">
                <Button
                    icon={<ArrowBackIosIcon />}
                    click={() => props.close()}
                />
            </div>
            <div className="homeImageItemPreviewContainer" >
                {loading ? (
                    <div className="homeCircularProgress">
                        <CircularProgress />
                    </div>
                ) : (
                    <img src={image.image} />
                )}
            </div>
        </Dialog>
    );
}
export default HomeImageSepand