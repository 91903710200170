import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

const FormDynamicInputNumber = (props) => {
    const [value, setValue] = useState(props.value ? props.value : 0);
    const { setInputsValue } = props;

    const sendValueToInputs = (value) => {
        setValue(value)
        setInputsValue(value, props.id)
    }

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    return (
        <div className={(props.className ?? "col-12 col-sm-12")}  >
            <TextField
                fullWidth
                size="small"
                type="number"
                id={props.id}
                label={props.title}
                value={value}
                onChange={e => sendValueToInputs(e.target.value)}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled || props.loading}
            />
        </div>
    );
}

export default FormDynamicInputNumber;