import React from 'react';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from './../button/button';
import { useSnackbar } from 'notistack';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const GridColumnList = (props) => {
     const { enqueueSnackbar } = useSnackbar();

     const copyData = (data) => {
          navigator.clipboard.writeText(data)
          enqueueSnackbar(` ${data} کپی شد.`, { variant: 'success' })
     };

     switch (props.type) {
          case "date":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>{(new Date(props.coulmn).toLocaleDateString('fa-IR'))}
                    </div>
               );
          case "image":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>
                         {props.coulmn ? (
                              <img src={`data:image/jpeg;base64,${props.coulmn}`} style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                         ) : (
                              ""
                         )}
                    </div>
               );
          case "currency":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>{(parseInt(props.coulmn).toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 0 }))}
                    </div>
               );
          case "enum":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>{props.enums.filter(x => x.value == props.coulmn)[0]?.text}
                    </div>
               );
          case "enumColor":
               return (
                    (props.enums.filter(x => x.value == props.coulmn)[0]?.text) ?
                         (
                              <div style={{
                                   width: "100%",
                                   height: "30px",
                                   backgroundColor: (props.enums.filter(x => x.value == props.coulmn)[0]?.text),
                                   display: "inline-block",
                                   color: "black",
                                   textShadow: "0 0 3px white, 0 0 5px white",
                                   textAlign: "center",
                                   borderRadius: "5px"
                              }}>
                                   <span className="titleGridColumnList">{props.label}</span>
                              </div>
                         ) : (null)
               );
          case "select":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>{props.listSelect.filter(x => x.value == props.coulmn)[0]?.text}
                    </div>
               );
          case "editColumn":
               return (
                    <div>
                         <span className="titleGridColumnList">{props.label}:&nbsp;</span>
                         {!props.noEdit ? (
                              <Button
                                   icon={<EditOutlined />}
                                   click={() => props.open()} />) : (null)}
                         {!props.noDelete ? (
                              <Button
                                   icon={props.isActive ? <DeleteOutlineIcon /> : <RestoreFromTrashIcon />}
                                   click={() =>
                                        props.delete()} />
                         ) : (null)}
                    </div>
               );
          case "editColumnCustom":
               return (
                    <div>
                         <span style={{ display: "inline-block" }} className="titleGridColumnList">{props.label}:&nbsp;</span>{props.coulmn}
                    </div>
               );
          default:
               return (
                    <div>
                         <span style={{ display: "inline" }} className="titleGridColumnList">{props.label}:&nbsp;</span>
                         <span
                              className="GridColumnText"
                              onClick={() => copyData(props.coulmn)}
                         >{props.coulmn}</span>
                    </div>
               );
     }
}
export default GridColumnList;