import React, { useEffect, useState, useRef } from 'react'
import { organizationId } from '../../services/organizationConfig'
import './platformMajAnimationLogo.css'
import { useSelector } from 'react-redux'

const PlatformMajAnimationLogo = (props) => {
    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    
    const [picLogo, setPicLogo] = useState(0)

    const [position, setPosition] = useState("fixed")//fixed//static//relative//absolute//sticky
    const [justifyContent, setJustifyContent] = useState("flex-start")//flex-end//flex-start

    const [height, setHeight] = useState(((props.item.length * props.item.distance) - props.item.distance))

    const setId = () => {
        return `pmAnimationLogo${props.item.id}`;
    };

    const startItem = () => {
        return document.getElementById(setId())?.offsetTop;
    };

    const scrollYInItem = () => {
        return scrollY - startItem()
    };

    const EndItem = () => {
        return startItem() + height;
    };

    const itemScrollHeight = () => {
        return height + innerSize.height;
    };

    const lastLogoItem = () => {
        return Math.floor(props.item.length - 1);
    };

    const setLogoItem = () => {
        return Math.floor((scrollY - startItem()) / props.item.distance);
    };

    const handleSetPicLogo = (input) => {
        setPicLogo(input);
    };

    const [descriptions, setDescriptions] = useState(props.item.descriptions)

    const withDescription = () => {
        return props.item.withDescription;
    };

    const descriptionType = () => {
        return props.item.descriptionType ?? 1;
    };

    const lastDescriptionItem = () => {
        return descriptions.length - 1;
    };

    const setDescriptionItem = () => {
        return descriptions.indexOf(descriptions?.filter(row => row.start + startItem() > scrollY)[0]) ?? 0
    };

    const resetDescriptionType1 = (input) => {
        //console.log('resetDescriptionType1',props.index,props.item.descriptions)
        setDescriptions(descriptions.map(row => ({
            start: row.start,
            description: row.description,
            onScroll: input,
        })))
    };

    const handleDescriptionType1 = (input) => {
        //console.log('handleDescriptionType1',props.index,input)
        if (input >= 0 && input <= lastDescriptionItem()) {
            let descriptionsCopy = [...descriptions];
            descriptionsCopy[input].onScroll = true
            setDescriptions(descriptionsCopy)
        }
    }


    const [descriptionId, setDescriptionId] = useState(0)
    const [opacity, setOpacity] = useState(0)

    const handleDescriptionId = (id) => {
        setDescriptionId(id);
        /*console.log(
            "id", id,
            `(${scrollY} - ${(props.item.descriptions[id-1]?.start??0)} - ${startItem()}) / ${(props.item.descriptions[id]?.start??0)}-${(props.item.descriptions[id-1]?.start??0)}`
            ,((scrollY - (props.item.descriptions[id-1]?.start??0) - startItem())) / ((props.item.descriptions[id]?.start??0)-(props.item.descriptions[id-1]?.start??0)))
*/
        setOpacity(((scrollY - (props.item.descriptions[id - 1]?.start ?? 0) - startItem())) / ((props.item.descriptions[id]?.start ?? 0) - (props.item.descriptions[id - 1]?.start ?? 0)) - 0.1)
    };

    const ShowdescriptionType1 = (start) => {
        if (scrollYInItem() >= start) {
            return true;
        }
        else {
            return false;
        }
    };

    const images = () => {
        let menuItems = [];

        for (var i = 0; i < props.item.length; i++) {
            menuItems.push(<img style={{ display: picLogo != i ? "none" : "inline-block" }} src={process.env.PUBLIC_URL + `organization${organizationId()}/${props.item.id}/${i}${props.item.fileFormat}`}></img>)
        }

        return <div className="pmAnimationLogoItem">{menuItems}</div>;
    }

    useEffect(() => {
        //console.log('useEffect',props.index,descriptions)
        if (scrollY < startItem()) {
            handleSetPicLogo(0)
            setPosition("relative")
            setJustifyContent("flex-start")
        }
        else if (scrollY > EndItem()) {
            handleSetPicLogo(lastLogoItem())
            setPosition("relative")
            setJustifyContent("flex-end")
        }
        else {
            handleSetPicLogo(setLogoItem())
            setPosition("fixed")
            setJustifyContent("flex-start")
        }

        if (withDescription()) {
            switch (descriptionType()) {
                case 1:
                    if (scrollY < startItem()) {
                        resetDescriptionType1(false)
                    }
                    else if (scrollY > EndItem()) {
                        resetDescriptionType1(true)
                    }
                    else {
                        handleDescriptionType1(setDescriptionItem())
                    }
                    break;

                case 2:
                    if (scrollY < startItem()) {
                        handleDescriptionId(0)
                    }
                    else if (scrollY > EndItem()) {
                        handleDescriptionId(lastDescriptionItem())
                    }
                    else {
                        handleDescriptionId(setDescriptionItem())
                    }
                    break;

                default:
                    break;
            }
        }

        return () => {

            return "";
        }
    }, [scrollY, innerSize]);

    return (
        <section
            className="homeSection homeSectionColor pmItemScrollContainer"
            style=
            {{
                height: itemScrollHeight(),
                minHeight: itemScrollHeight(),
                justifyContent: justifyContent,
                backgroundColor: props.item.backColor
            }}
            id={setId()}
        >
            <div className="pmItemContainer pmAnimationLogoContainer"
                style={{
                    position: position,

                    zIndex: props.item.index
                }}>
                {images()}
                {withDescription() ? (
                    <div>
                        {(descriptionType() == 1) ?
                            descriptions.map((desItem) => (
                                <div
                                    className={`pmAnimationLogoDescriptionType1 ${ShowdescriptionType1(desItem.start) ? "pmAnimationLogoDescriptionType1Transform" : ""}`}
                                    dangerouslySetInnerHTML={{ __html: desItem.description }}
                                />
                            ))
                            : (
                                <div
                                    style={{ opacity: opacity }}
                                    dangerouslySetInnerHTML={{ __html: props.item.descriptions[descriptionId]?.description }}
                                />
                            )
                        }
                    </div>
                ) : null}
            </div>
        </section>
    )
}
export default PlatformMajAnimationLogo
