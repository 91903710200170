import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { enumFilter } from '../gridData/enumFilter'
import { bitwiseOperators } from '../gridData/bitwiseOperators'
import Button from '@material-ui/core/Button';
import './gridfFilter.css'
import RefreshIcon from '@material-ui/icons/Refresh';

const GridfFilterEnum = (props) => {

  const [filterValue, setInputs] = useState(props.filterValue);
  const { onClose, open, couumnLabel, enums } = props;

  const handleClose = () => {
    onClose(filterValue);
  };

  const clear = () => {
    setInputs(state => ({ state, value1: "" }))
    setInputs(state => ({ state, value2: "" }))
    onClose({
      value1: "",
      operator1: "eq",
      value2: "",
      operator2: "eq",
      logic: 'and'
    });
  };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={open}>
      <DialogTitle>
        <div className="filterTitle">
          نشان دادن&nbsp;<span style={{ fontWeight: "bold" }}>{couumnLabel}</span>&nbsp;در شرایطی که:
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="container filterContainer">
          <div className="row">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.operator1} onChange={({ target }) => setInputs(state => ({ ...state, operator1: target.value }))}>
                {enumFilter.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-sm-6">
              <TextField autoFocus fullWidth size="small" select
                value={filterValue.value1} onChange={({ target }) => setInputs(state => ({ ...state, value1: target.value }))}>
                {enums.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.logic} onChange={({ target }) => setInputs(state => ({ ...state, logic: target.value }))}>
                {bitwiseOperators.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.operator2} onChange={({ target }) => setInputs(state => ({ ...state, operator2: target.value }))}>
                {enumFilter.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.value2} onChange={({ target }) => setInputs(state => ({ ...state, value2: target.value }))}>
                {enums.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Button fullWidth variant="contained" size="small" onClick={() => handleClose()}>
                اعمال
            </Button>
            </div>
            <div className="col-sm-3">
              <Button fullWidth variant="contained" size="small" onClick={() => clear()}>
                <RefreshIcon />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default GridfFilterEnum
