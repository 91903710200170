import axios from 'axios';
import authHeader from './auth-header';
import ApiUrl from './api-url';
import  { organizationId } from './organizationConfig';

const API_URL = ApiUrl() + 'services/app/Visitor';

class VisitorService {

    GetAll(skipCount, maxResultCount, search, isActive) {
        return axios.get(API_URL + "/GetAll?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&search=" + search + "&isActive=" + isActive, { headers: authHeader() });
    }
    GetAllPublic() {
        return axios.get(API_URL + "/GetAllPublic?skipCount=0&maxResultCount=20&organizationId=" + organizationId()+ "&isActive=true" , { headers: authHeader() });
    }

    Create(model) {
        return axios.post(API_URL + '/Create', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Update(model) {
        return axios.put(API_URL + '/Update', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Get(id) {
        return axios.get(API_URL + "/Get?id=" + id, { headers: authHeader() });
    }
    Delete(id) {
        return axios.delete(API_URL + '/Delete?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }
}

export default new VisitorService();