import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import TextField from '@material-ui/core/TextField';
import Button from './../button/button';
import { useSnackbar } from 'notistack';
import './Login.css'
import { checkAllValidations } from '../validastions/validastions'
import Footer from '../footer/footer'
import { GetDirection, GetLocalizationGuest, getLocalizationTitle, SetLocalization } from '../localization/localization'
import { isExpired, userLanguage, userTheme } from '../../services/auth-header';
import { HistoryPushAfterLogin, organizationId } from '../../services/organizationConfig';
import { GetTheme, GetThemeGuest, SetTheme } from '../theme/theme';
//google
import { googleReCaptcaSiteKey } from '../../services/googleReCaptcaSiteKey';
import ReCAPTCHA from "react-google-recaptcha";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const Login = (props) => {
    const recaptchaRef = React.useRef();
    const [inputs, setInputs] = useState({
        username: "",
        password: "",
    });
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory();

    const handleRecaptcha = () => {
        //console.log('handleRecaptcha',errorUserName.error , errorPassword.error)
        //google
        if (!(!errorUserName.error && !errorPassword.error)) {
            if (errorUserName.error) {
                enqueueSnackbar(errorUserName.message, { variant: 'error' })
            }
            if (errorPassword.error) {
                enqueueSnackbar(errorPassword.message, { variant: 'error' })
            }
        }
        else {
            recaptchaRef.current.executeAsync().then((token) => {
                handleLogin(token);
            })
        }
        //handleLogin("")
    }

    const handleLogin = (token) => {
        setLoading(true);
        AuthService.login(inputs.username, inputs.password, token).then(
            (result) => {
                if (result.result.accessToken) {
                    //window.location.reload();

                    history.push(HistoryPushAfterLogin());

                    enqueueSnackbar('Welcome', { variant: 'success' })
                }
                else {
                    enqueueSnackbar(result.content, { variant: 'error' })
                }
                setLoading(false);
            },
            (error) => {
                enqueueSnackbar((error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString(), { variant: 'error' })
                setLoading(false);
            }
        ).then(
            (result) => {
                //console.log('then then', GetTheme(), GetThemeGuest(), userTheme(),userLanguage())
                if (userLanguage()) {
                    if (GetThemeGuest() != userTheme() || GetLocalizationGuest() != userLanguage()) {
                        SetLocalization(userLanguage())
                        SetTheme(userTheme())
                        window.location.reload();
                    }
                }
            }
        );
    }

    const handleRegister = () => {
        history.push("/register");
    }
    const handleHome = () => {
        history.push("/home");
    }



    const errorUserName = (checkAllValidations(inputs.username,
        [{ type: "Required" }, { type: "MaxLength", length: 50 }, /*{ type: "Email" }*/],
        "Username"));

    const errorPassword = (checkAllValidations(inputs.password,
        [{ type: "Required" }, { type: "MaxLength", length: 12 }, { type: "MinLength", length: 4 }],
        "Password"));

    const organizationName = () => {
        switch (organizationId()) {
            case 1:
                return (
                    <p>
                        <h1>Amin Dental</h1>
                        <h4>Dr. Mohammad HajiBabaei</h4>
                    </p>
                );
            case 2:
                return (
                    <p>
                        <h1>Sepand Dental</h1>
                        <h4>DR. Mahtab Chaharkameh</h4>
                    </p>
                );
            case 3:
                return (
                    <p>
                        <h1>Engineer Majid Ghajari</h1>
                        <h4>Senior Software Developer</h4>
                    </p>
                );
            case 4:
                return (
                    <p>
                        <h1 style={{ display: "none" }}>Ontime</h1>
                        <h4>{getLocalizationTitle("ReserveAppointmentSystem")}</h4>
                    </p>
                );
            case 5:
                return (
                    <p>
                        <h1>MA Neda HajiBabaei</h1>
                        <h4>Senior Front-End Developer</h4>
                    </p>
                );
            case 6:
                return (
                    <p>
                        <h1>in4mationIn1</h1>
                        <h4>By Majid Ghajari</h4>
                    </p>
                );
            default:
                return (
                    <p>
                        <h1 >Majid Ghajari</h1>
                    </p>
                );
        }
    }

    const showRegister = () => {
        switch (organizationId()) {
            case 4:
                return (
                    <Button
                        variant="outlined"
                        icon={<NavigateNextIcon />}
                        label={getLocalizationTitle("Register")}
                        disabled={loading}
                        click={() => handleRegister()} >
                    </Button>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        if (!(isExpired())) {
            history.push(HistoryPushAfterLogin());
        }
        /*window.addEventListener('scroll', () => {
            //console.log(window.scrollY)
            if (window.scrollY > 70) {
                handleShow(true);
            } else handleShow(false);
        });*/
        return () => {
            return "";
        }
    }, []);

    return (
        <div className="loginContainer" dir={GetDirection()} >
            <div className="loginTop">
                <div className="loginAbout" >
                    <div onClick={() => handleHome()}>
                        <img src={process.env.PUBLIC_URL + `organization${organizationId()}/homeTopTheme${GetTheme()}.png`} />
                        {organizationName()}
                    </div>
                </div>
                <div className="loginInputs">
                    <div>
                        <div className="form-group">
                            <TextField
                                fullWidth
                                type="text"
                                name="username"
                                label={getLocalizationTitle("Username")}
                                value={inputs.username}
                                onChange={({ target }) => setInputs(state => ({ ...state, username: target.value }))}
                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                fullWidth
                                type="password"
                                name="password"
                                label={getLocalizationTitle("Password")}
                                value={inputs.password}
                                onChange={({ target }) => setInputs(state => ({ ...state, password: target.value }))}
                            />
                        </div>
                        <div className="form-group login-button-in4">
                            <Button
                                label={getLocalizationTitle("LogIn")}
                                disabled={loading}
                                click={() => handleRecaptcha()} >
                            </Button>
                            {/* {showRegister()} */}

                        </div>
                        {/*google*/}<ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={googleReCaptcaSiteKey()}
                            theme="dark"
                            type="image"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Login