import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from "react-number-format";

const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator
        // isNumericString
        />
    );
}

const FormDynamicInputCurrency = (props) => {
    const [value, setValue] = useState(props.value ? props.value : 0);
    const { setInputsValue } = props;

    const sendValueToInputs = (value) => {
        //console.log(value)
        setValue(parseInt(value))
        setInputsValue(parseInt(value), props.id)
        /*
        setValue((parseInt(value.replace(',','')).toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 0 })))
        setInputsValue(value.replace(',',''), props.id)
        */
    }

    useEffect(() => {
        setValue(parseInt(props.value))
        //setValue((parseInt((props.value??"0").replace(',','')).toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 0 })))
    }, [props.value]);

    return (
        <div className={(props.className ?? "col-12 col-sm-12")}  >
            <TextField
                fullWidth
                size="small"
                //type="text"
                id={props.id}
                label={props.title}
                value={value}
                onChange={e => sendValueToInputs(e.target.value)}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled || props.loading}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
            />
        </div>
    );
}

export default FormDynamicInputCurrency;