import entityTemplateDto from '../entityTemplateDto'

export const UserTemplate: entityTemplateDto[] = [
    {
        title: "userName",
        type: "text",
        label: "نام کاربری",
        labelEn: "User Name",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" },{ type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "name",
        type: "text",
        label: "نام",
        labelEn: "Name",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" },{ type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "surname",
        type: "text",
        label: "فامیلی",
        labelEn: "Surname",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" },{ type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "emailAddress",
        type: "text",
        label: "ایمیل",
        labelEn: "Email",
        filter: {},
        showList: 1,
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Email" }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "isActive",
        type: "enum",
        label: "فعال بودن",
        labelEn: "Is Active",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showForm: true,
        showGrid: true,
        enums: [
            { value: true, text: "Active" },
            { value: false, text: "Inactive" },
        ]
    },
    {
        title: "password",
        type: "text",
        label: "کلمه عبور",
        labelEn: "password",
        filter: {},
        showList: 1,
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }],
        showGrid: false,
        showForm: true
    },
    {
        title: "lastLoginTime",
        type: "date",
        label: "آخرین ورود",
        labelEn: "Last login time",
        filter: {},
        minWidth: "70px",
        showGrid: true,
        showList: 1,
    },
    {
        title: "creationTime",
        type: "date",
        label: "تاریخ ایجاد",
        labelEn: "Creation Time",
        filter: {},
        minWidth: "70px",
        showGrid: true,
        showList: 1,
    },
    {
        title: "roleNamesGrid",
        type: "string",
        label: "نقش ها",
        labelEn: "Role Names",
        filter: {},
        minWidth: "150px",
        showGrid: true,
        showList: 1,
    },
    {
        title: "roleNames",
        type: "multiSelect",
        label: "نقش ها",
        labelEn: "Role Names",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showForm: true,
        listSelect: [],
        validations: [{ type: "Required" }],
    },
    {
        title: "organizationId",
        type: "select",
        label: "واحد",
        labelEn: "Organization",
        filter: {},
        minWidth: "130px",
        showForm: true,
        listSelect: []
    },
    {
        title: "organizationTitle",
        type: "text",
        label: "واحد",
        labelEn: "Organization",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showGrid: true
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }
];