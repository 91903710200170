import React, { useEffect, useState } from 'react'
import { Switch } from "react-router-dom";
import GridContainer from '../../grid/gridContainer.component'
import BlogService from '../../../services/blog.service'
import { NoteTemplate } from './noteTemplate'
import { useSnackbar } from 'notistack';
import Note from './note'
import { getLocalizationTitle } from '../../localization/localization';
import { TextField } from '@material-ui/core';
import axios from "axios";
import FormDynamicInputMultiSelect from '../../formDynamic/formDynamicInputMultiSelect';

const Notes = (props) => {
    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({text:"",isActive:true});
    const { enqueueSnackbar } = useSnackbar();


    const [state, setState] = useState({ state: false, id: 0 });

    function open(id) {
        setState({ state: true, id: id })
    }
    function handleDelete(id) {

        BlogService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }
    const [refresh, setRefresh] = useState(0);
    function close(id) {
        setState({ state: false, id: 0 });

        if (id) {
            setRefresh(refresh + 1)
        }
    }


    const getAll = (skipCount, maxResultCount) => {
        setTotal(-1);
        BlogService.GetAll(skipCount, maxResultCount, search.text,search.isActive).then(
            response => {
                setContent(response.data.result.items);
                setTotal(response.data.result.totalCount)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        getAll(skipCount, maxResultCount)
    };

    const [noteInputData, setNoteInputsData] = useState({});

    const getdatafromtext = async() => {

        let tt=await axios.get('http://localhost:3000/noteType.txt');
        let ttt=await tt.data;
        console.log('test25',ttt);
    };


    useEffect(() => {
        //exportUserInfo({name:"majid",email:"ghajari3z2gmail.com"})
        getdatafromtext()
        getAll(0, 10)
        return () => {
            return "";
        }
    }, [search, refresh]);

    return (
        <div>
            <Switch>
                <div>
                <FormDynamicInputMultiSelect
                    title="KeyWords"
                    id={noteInputData.id}
                    value={noteInputData.keyWords}
                    //setInputsValue={setInputsValue}
                    //error={error.error}
                    //helperText={error.message}
                    listSelect={[{"index":"1","value":"dfged"},{"index":"2","value":"dfged"}]}
                    loading={props.loading}
                    isMultiple={true}
                />
                <TextField
                autoFocus="true"
                fullWidth
                multiline
                size="small"
                type="text"
                id={noteInputData.id}
                label="Title"
                value={noteInputData.title}
                //onChange={e => sendValueToInputs(e.target.value)}
                //error={props.error}
                //helperText={props.helperText}
                //disabled={props.disabled || props.loading}
            />
            <TextField
                autoFocus="true"
                fullWidth
                multiline
                size="small"
                type="text"
                id={noteInputData.id}
                label="Description"
                value={noteInputData.description}
                //onChange={e => sendValueToInputs(e.target.value)}
                //error={props.error}
                //helperText={props.helperText}
                //disabled={props.disabled || props.loading}
            />
            
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={NoteTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        //noIsActive={true}
                        open={open}
                        delete={handleDelete}
                        //noDelete={true}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Notes")}`}
                    />
                    <Note
                        state={state}
                        close={close}
                        inputs={NoteTemplate}
                    />

                </div>
            </Switch>
        </div>
    );
}

export default Notes


