import React, { useEffect, useState } from 'react'
import { Switch } from "react-router-dom";
import GridContainer from '../../grid/gridContainer.component'
import ProductService from '../../../services/product.service'
import { ProductTemplate } from './productTemplate'
import { useSnackbar } from 'notistack';
import Product from './product'
import { getLocalizationTitle } from '../../localization/localization';
import ProductImage from './productImage';
import Button from '../../button/button';
import PhotoIcon from '@material-ui/icons/Photo';
import { ProductImageTemplate } from './productImageTemplate';
import ProductImages from './productImages';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

const Products = (props) => {
    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({ text: "", isActive: true, soldOut: false });
    const { enqueueSnackbar } = useSnackbar();


    const [state, setState] = useState({ state: false, id: 0 });

    function open(id) {
        setState({ state: true, id: id })
    }
    function handleDelete(id) {
        //console.log(id);

        ProductService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const soldOut = (id) => {
        ProductService.SoldOut(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('فروخته شد', { variant: 'success' })
                    getAll(0, 10)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const [refresh, setRefresh] = useState(0);
    function close(id) {
        setState({ state: false, id: 0 });

        if (id) {
            setRefresh(refresh + 1)
        }
    }

    const [stateProductImage, setStateProductImage] = useState({ state: false, id: 0 });
    function openProductImage(productId) {
        setStateProductImage({ state: true, id: 0, productId: productId })
    }
    function closeProductImage() {
        setStateProductImage({ state: false, id: 0, customerId: 0 });
    }

    const [stateProductImages, setStateProductImages] = useState({ state: false, id: 0 });
    function openProductImages(productFiles) {
        setStateProductImages({ state: true, id: 0, productFiles: productFiles })
    }
    function closeProductImages() {
        setStateProductImages({ state: false, id: 0, productFiles: null });
    }

    const handleSoldOut = () => {
        //console.log('handleSoldOut', search.isActive, !search.soldOut)
        setSearch({ text: "", isActive: search.isActive, soldOut: !search.soldOut })
    }

    const getAll = (skipCount, maxResultCount) => {
        setTotal(-1);
        ProductService.GetAll(skipCount, maxResultCount, search.text, search.isActive, search.soldOut).then(
            response => {
                setContent(response.data.result.items.map((item, index) => ({
                    id: item.id,
                    title: item.title,
                    model: item.model,
                    price: item.price,
                    productTypeId: item.productTypeId,
                    imageFileData: item.productFiles[0]?.imageFileData,
                    productFiles: item.productFiles,
                    soldOut: item.soldOut,
                    image: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<PhotoLibraryIcon />} click={() => openProductImages(item.productFiles)} />
                            <Button icon={<PhotoIcon />} click={() => openProductImage(item.id)} />
                            <Button label="فروش رفته" click={() => soldOut(item.id)} />
                        </div>
                    ),
                    /*
                    contact: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<CallIcon />} click={() => copyCellPhoneNumber(item.cellPhoneNumber)} />
                            <Button icon={<SmsIcon />} click={() => chooseMessage(item.id)} />
                        </div>
                    )*/
                })));
                setTotal(response.data.result.totalCount)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        getAll(skipCount, maxResultCount)
    };

    useEffect(() => {
        getAll(0, 10)
        return () => {
            return "";
        }
    }, [search, refresh]);

    return (
        <div>
            <Switch>
                <div>
                    <Button
                        variant={!search.soldOut ? null : "outlined"}
                        label={!search.soldOut ? "نماش فروخته شده ها" : "عدم نمایش فروخته شده ها"}
                        click={handleSoldOut} />
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={ProductTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        //noIsActive={true}
                        open={open}
                        delete={handleDelete}
                        //noDelete={true}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Products")}`}
                    />

                    <Product
                        state={state}
                        close={close}
                        inputs={ProductTemplate}
                    />
                    <ProductImage
                        state={stateProductImage}
                        close={closeProductImage}
                        inputs={ProductImageTemplate}
                    />
                    <ProductImages
                        state={stateProductImages}
                        close={closeProductImages}
                    />
                </div>
            </Switch>
        </div>
    );
}

export default Products


