
import React, { useState, useEffect } from 'react';
import GridRow from './gridRow.component';
import GridRowList from './gridRowList.component';
import GridHead from './gridHead.component';
import GridPaging from './gridPaging.component';
import './gridContainer.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircle from '@material-ui/icons/Add';
import GridContainerSearch from './gridContainerSearch.component';
import QuestionDialog from '../alertsDialog/QuestionDialog';
import Switch from '@material-ui/core/Switch';
import Button from './../button/button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getLocalizationTitle } from '../localization/localization';

const GridContainer = (props) => {

    const [gridData, setGridData] = useState([]);
    const [gridCouumn, setGridCouumn] = useState(props.gridCouumn.filter(r => r.showGrid == true));
    const [take, setTake] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState(props.gridSort);
    const dataFromHead = (field, filter) => {
        let gc = gridCouumn;
        gc.filter(x => x.title == field)[0].filter = filter;
        setGridCouumn(gc);
        //search()
    };

    const setPageFromGridPaging = (value) => {
        setPage(value);
        setSkip((value - 1) * take);
        props.setTotal(-1)
        props.dataFromGrid((value - 1) * take, take/*{
            take: take,
            skip: (value - 1) * take,
            page: value,
            pageSize: pageSize,
            sort: sort,
            filter: getFilter()
        }*/);
    }



    /*const search = () => {
        props.setTotal(-1)
        props.dataFromGrid({
            take: take,
            skip: skip,
            page: page,
            pageSize: pageSize,
            sort: sort,
            filter: getFilter()
        });
    };*/
    const getFilter = () => {
        let filters = gridCouumn.map(item => (
            item.filter
        )).filter(x => x.filters);
        let filter = {};
        if (filters.length > 0) {
            filter = {
                filters: filters,
                logic: "and"
            };
        }
        return filter;
    }

    function open(id) {
        props.open(id)
    }

    const [stateDeleteDialog, setStateDeleteDialog] = useState(
        {
            open: false,
            content: getLocalizationTitle("AreYouDelete"),
            falseButton: getLocalizationTitle("No"),
            trueButton: getLocalizationTitle("Yes"),
            id: 0
        });
    function handleDeleteDialogOpen(id) {
        setStateDeleteDialog(state => ({ ...state, open: true, id: id }))
    }
    function handleDeleteDialogCloseFalseState() {
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }

    function handleDeleteDialogCloseTrueState() {
        props.delete(stateDeleteDialog.id)
        setStateDeleteDialog(state => ({ ...state, open: false, id: 0 }))
    }

    //const [isActive, setIsActive] = useState(true);


    const setSearchText = (text) => {
        props.setSearch(state => ({ ...state, text: text }))
    }

    const setSearchIsActive = () => {
        props.setSearch(state => ({ ...state, isActive: !props.search.isActive }))
    }

    useEffect(() => {
        setGridData(props.gridData)
    }, [props.gridData, props.total]);

    return (
        <div className="gridContainer">
            <div>
                <div className="gridHeaderTitle">{props.title}</div>
                <div className="gridHeaderSearch">
                    <div>
                        <Button
                            label={getLocalizationTitle("Add")}
                            icon={<AddCircle />}
                            click={() => open(0)}
                        />
                    </div>
                    {!props.noIsActive ? (
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={props.search.isActive}
                                        onChange={setSearchIsActive}
                                        name="isActive"
                                    />
                                }
                                label={props.search.isActive ? getLocalizationTitle("Active") : getLocalizationTitle("Inactive")}
                            />
                        </div>
                    ) : (null)}
                </div>
                <div className="gridHeaderSearch">
                    <GridContainerSearch
                        setSearch={setSearchText}
                        search={props.search.text}
                    />
                </div>
                <div className="row">
                    {!(props.total > -1) ?
                        (
                            <div className="col-12 col-sm-12">
                                <div className="homeCircularProgress" >
                                    <CircularProgress />
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="col-12 col-sm-12" >
                                <div className="gridContainerTable">
                                    <table className="table table-bordered table-striped" style={{ overflow: "auto", fontSize: "small" }}>
                                        <thead>
                                            <tr>
                                                {gridCouumn.map(item => (
                                                    <GridHead couumn={item} dataFromHead={dataFromHead} />
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {gridData.map(item => (
                                                <GridRow
                                                    rowData={item}
                                                    dataFromRow={(dataFromRow)}
                                                    gridCouumn={(gridCouumn)}
                                                    open={open}
                                                    delete={handleDeleteDialogOpen}
                                                    noDelete={props.noDelete}
                                                    noEdit={props.noEdit}
                                                    isActive={props.search.isActive}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="gridContainerList">
                                    {gridData.map(item => (
                                        <GridRowList
                                            rowData={item}
                                            dataFromRow={(dataFromRow)}
                                            gridCouumn={(gridCouumn)}
                                            open={open}
                                            delete={handleDeleteDialogOpen}
                                            noDelete={props.noDelete}
                                            noEdit={props.noEdit}
                                            isActive={props.search.isActive}
                                        />
                                    ))}
                                </div>
                                <div>
                                    <GridPaging
                                        pages={(Math.floor((props.total - 1) / take) + 1)}
                                        setPageFromGridPaging={setPageFromGridPaging}
                                        total={props.total}
                                        page={page}
                                    />
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <QuestionDialog
                questionState={stateDeleteDialog}
                handleTrue={handleDeleteDialogCloseTrueState}
                handleFalse={handleDeleteDialogCloseFalseState}
            />
        </div>
    );
}

const dataFromRow = (rowData) => {

}

export default GridContainer;
