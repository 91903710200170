import React, { useEffect, useState } from 'react'
import RoleService from '../../../services/role.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../../formDynamic/formDynamic'
import { RoleTemplate } from './roleTemplate'

const Role = (props) => {
    const { close, state } = props;
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState(RoleTemplate);
    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({ MinSize: 200, MaxSize: 300, relationType: 1 });

    const handleClose = (id) => {
        close(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const GetAllPermissions = () => {
        RoleService.GetAllPermissions().then(
            response => {
                /*console.log(response.data.result.items.map((item, index) => (
                    { value: item.name, text: item.displayName }
                )))*/
                let inputsCopy = [...inputs];
                inputsCopy.filter(v => v.title === "grantedPermissions")[0].listSelect = response.data.result.items.map((item, index) => (
                    { value: item.name, text: item.displayName }
                ));
                setInputs(inputsCopy)

            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const send = () => {
        setLoading(true)
        if (state.id) {
            RoleService.Update(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ویرایش شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
        else {
            RoleService.Create(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ذخیره شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    const get = () => {
        setLoading(true)
        RoleService.Get(state.id)
            .then(response => {
                setLoading(false)
                setInputsData(response.data.result);
            },
                error => {
                    setLoading(false)
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }

    useEffect(() => {
        //console.log('Role useEffect',state)
        if (state.id && state.state) {
            get()
        }
        if (state.state) {
            GetAllPermissions()
        }
        return () => {
            setInputsData({ relationType: 1 });
            return "";
        }
    }, [state]);

    return (
        <FormDynamic
            formTitle={"اضافه کردن کاربر"}
            inputs={inputs}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default Role


