import React, { useEffect, useState } from 'react'
import VisitTimeService from '../../../services/visitTime.service'
import WorkItService from '../../../services/workIt.service'
import VisitorService from '../../../services/visitor.service'
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import FormDynamicInputText from '../../formDynamic/formDynamicInputText'
import FormDynamicInputDate from '../../formDynamic/formDynamicInputDate'
import FormDynamicInputSelect from '../../formDynamic/formDynamicInputSelect'
import VisitTimeVisits from './visitTimeVisits'
import FormDynamicInputCurrency from '../../formDynamic/formDynamicInputCurrency'
import Button from '../../button/button';
import { GetDirection, LocalizationJson } from '../../localization/localization';
import BackButton from '../../button/backButton';

const VisitTime = (props) => {
    const { closeVisitTime, stateVisitTime } = props;
    const [workIts, setWorkIts] = useState([]);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState(
        {
            visitorId: 0,
            customerId: 0,
            accessibility: 1,
            payment: { customerId: 0, totalAmount: 0, id: 0 },
            clubScore: { customerId: 0, discountAmount: 0, discountPercent: 5, id: 0 }
        });
    const [inputsDataVisits, setInputsDataVisits] = useState([{ workItId: -1, axpenseAmount: 0, numberTasks: 0 }]);

    const handleClose = (id) => {
        closeVisitTime(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const handleSetInputsDataVisits = (workItId, operator) => {
        let visitsCopy = [...inputsDataVisits];
        if (operator) {
            visitsCopy.filter(v => v.workItId == workItId)[0].numberTasks = visitsCopy.filter(v => v.workItId == workItId)[0].numberTasks + operator;
            setInputsDataVisits(visitsCopy)
        }
        else {
            var workIt = workIts.filter(o => o.id == workItId)[0];

            var visit = inputsDataVisits?.filter(o => o.workItId == workItId)[0];
            if (visit) {
                visitsCopy.filter(v => v.workItId == workItId)[0].numberTasks = 1;
                setInputsDataVisits(visitsCopy)

            }
            else {
                visitsCopy.push({
                    workItId: workItId,
                    axpenseAmount: workIt?.axpenseAmount,
                    id: 0,
                    numberTasks: 1,
                    workIt: { title: workIt?.title },
                    isActive: true,
                    numberTasks: 1
                })
                setInputsDataVisits(visitsCopy);
            }
        }
        SetAmountByVisits(visitsCopy);
        setInputsData(state => ({ ...state, visits: visitsCopy.filter(v => v.numberTasks > 0 || v.id > 0) }))
    };

    const SetAmountByVisits = (target) => {
        let sumAxpenseAmount = target?.map(t => t.axpenseAmount * t.numberTasks).reduce((a, b) => a + b, 0);
        let totalAmount = (sumAxpenseAmount - (inputsData.discountAmount ? inputsData.discountAmount : 0));
        setInputsData(state => ({
            ...state,
            axpenseAmount: sumAxpenseAmount,
            totalAmount: totalAmount,
            payment: {
                customerId: stateVisitTime.customerId,
                totalAmount: totalAmount,
                id: state.payment?.id
            },
            clubScore: {
                customerId: stateVisitTime.customerId,
                discountAmount: (state.clubScore?.discountPercent ?? 0) * 0.01 * (totalAmount ?? 0),
                discountPercent: state.clubScore?.discountPercent ?? 0,
                id: state.clubScore?.id ?? 0
            }
        }))
    }

    const handleSetInputsDataDiscountAmount = (target) => {
        let sumAxpenseAmount = inputsDataVisits.map(t => t.axpenseAmount * t.numberTasks).reduce((a, b) => a + b, 0);
        let totalAmount = sumAxpenseAmount - target ?? 0;
        setInputsData(state => ({
            ...state,
            discountAmount: target ?? 0,
            totalAmount: totalAmount,
            payment: {
                customerId: stateVisitTime.customerId,
                totalAmount: totalAmount,
                id: state.payment?.id
            },
            clubScore: {
                customerId: stateVisitTime.customerId,
                discountAmount: (state.clubScore?.discountPercent ?? 0) * 0.01 * (totalAmount ?? 0),
                discountPercent: state.clubScore?.discountPercent ?? 0,
                id: (state.clubScore?.id ?? 0)
            }
        }))
    };

    const handleSetInputsData_payment_totalAmount = (target) => {
        setInputsData(state => ({ ...state, payment: { id: inputsData.paymentId, totalAmount: target ?? 0 } }))
    };

    const handleSetInputsData_clubScore_discountPercent = (target) => {
        setInputsData(state => ({
            ...state,
            clubScore: {
                id: inputsData.clubScoreId,
                discountPercent: target ?? 0,
                discountAmount: (state.payment?.totalAmount ?? 0) * 0.01 * (target ?? 0),
                id: state.clubScore?.id ?? 0
            }
        }))
    };

    const send = () => {
        if (!(inputsData.visitorId > 0)) {
            enqueueSnackbar("ملاقات کننده را انتخاب نمایید.", { variant: 'error' })
        }
        else if (!(inputsData.visits?.filter(row => row.numberTasks > 0)[0]?.numberTasks)) {
            enqueueSnackbar("لطفا یک وظیفه را انتخاب نمایید.", { variant: 'error' })
        }
        else {
            setLoading(true)
            if (stateVisitTime.id) {
                VisitTimeService.Update(inputsData).then(
                    (response) => {
                        setLoading(false)
                        if (response.success) {
                            enqueueSnackbar('ویرایش شد', { variant: 'success' })
                            handleClose(response.response.id)
                        }
                        else {
                            enqueueSnackbar(response.response, { variant: 'error' })
                        }
                    }
                );
            }
            else {
                VisitTimeService.Create(inputsData).then(
                    (response) => {
                        setLoading(false)
                        if (response.success) {
                            enqueueSnackbar('ذخیره شد', { variant: 'success' })
                            handleClose(response.response.id)
                        }
                        else {
                            enqueueSnackbar(response.response, { variant: 'error' })
                        }
                    }
                );
            }
        }
    }

    const get = (visitorId) => {
        if (stateVisitTime.id) {
            VisitTimeService.Get(stateVisitTime.id)
                .then(response => {
                    setLoading(false)
                    setInputsData(response.data.result);
                    setInputsDataVisits(response.data.result.visits);
                },
                    error => {
                        setLoading(false)
                        enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                    }
                );
        }
        else {
            setLoading(false)
            setInputsData({
                visitorId: visitorId,
                customerId: stateVisitTime.customerId,
                accessibility: 1,
                payment: { customerId: stateVisitTime.customerId, totalAmount: 0, id: 0 },
                clubScore: { customerId: stateVisitTime.customerId, discountAmount: 0, discountPercent: 5, id: 0 }
            })
        }
    }

    const getAllWorkIt = (visitorId) => {
        WorkItService.GetAll(0, 200, '').then(
            response => {
                setWorkIts(response.data.result.items.map((item, index) => (
                    {
                        title: item.title,
                        fullTitle: `${item.title} ${item.axpenseAmount ?? 0} ریال`,
                        id: item.id,
                        axpenseAmount: item.axpenseAmount
                    }
                )));
                get(visitorId)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const [visitors, setVisitors] = useState([]);
    const getAllVisitor = (skipCount, maxResultCount) => {
        setLoading(true)
        VisitorService.GetAll(0, 200, '', true).then(
            response => {
                setVisitors(response.data.result.items.map((item, index) => ({
                    value: item.id,
                    index: index,
                    text: LocalizationJson(item.specialty) + ' ' + LocalizationJson(item.firstName) + ' ' + LocalizationJson(item.lastName)
                })));
                getAllWorkIt(response.data.result.items[0].id)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    useEffect(() => {
        if (stateVisitTime.state) {
            getAllVisitor()
        }

        return () => {
            setInputsData({
                visitorId: 0,
                customerId: 0,
                accessibility: 1,
                payment: { customerId: 0, totalAmount: 0, id: 0 },
                clubScore: { customerId: 0, discountAmount: 0, discountPercent: 5, id: 0 }
            });
            setInputsDataVisits([{ workItId: -1, axpenseAmount: 0, numberTasks: 0 }]);
            return "";
        }
    }, [stateVisitTime]);

    return (
        <Dialog fullWidth={true} open={stateVisitTime.state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => handleClose()}
                />
                <div>اضافه کردن ملاقات</div>
                <Button
                    icon={<DoneSharpIcon />}
                    click={() => send()}
                    disabled={props.loading}
                />
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                <div className="row rowBuySellOpinionDialog" >
                    <div className="col " >
                        <div className="row" >
                            <FormDynamicInputSelect
                                title={"ملاقات کننده"}
                                id={"visitorId"}
                                value={inputsData.visitorId}
                                setInputsValue={handleSetInputsData}
                                listSelect={visitors}
                                loading={loading}
                            />
                            <FormDynamicInputDate
                                title={"تاریخ"}
                                id={"date_Time"}
                                value={inputsData.date_Time}
                                setInputsValue={handleSetInputsData}
                                loading={loading}
                            />
                            <VisitTimeVisits
                                workIts={workIts}
                                visits={inputsDataVisits}
                                setInputsValue={handleSetInputsDataVisits}
                                loading={loading}
                            />
                            <FormDynamicInputCurrency
                                title={"صورتحساب"}
                                id={"axpenseAmount"}
                                value={inputsData.axpenseAmount}
                                setInputsValue={handleSetInputsData}
                                disabled={true}
                                loading={loading}
                            />
                            <FormDynamicInputCurrency
                                title={"تخفیف"}
                                id={"discountAmount"}
                                value={inputsData.discountAmount}
                                setInputsValue={handleSetInputsDataDiscountAmount}
                                loading={loading}
                            />
                            <FormDynamicInputText
                                title={"توضیحات"}
                                id={"description"}
                                value={inputsData.description}
                                setInputsValue={handleSetInputsData}
                                loading={loading}
                            />
                            <FormDynamicInputCurrency
                                title={"مبلغ نهایی"}
                                id={"totalAmount"}
                                value={inputsData.totalAmount}
                                setInputsValue={handleSetInputsData}
                                disabled={true}
                                loading={loading}
                            />
                            <FormDynamicInputCurrency
                                title={"مبلغ پرداختی"}
                                id={"payment_totalAmount"}
                                value={inputsData.payment?.totalAmount}
                                setInputsValue={handleSetInputsData_payment_totalAmount}
                                loading={loading}
                            />
                            <FormDynamicInputText
                                title={"درصد شارژ باشگاه مشتریان"}
                                id={"clubScore_discountPercent"}
                                value={inputsData.clubScore?.discountPercent}
                                setInputsValue={handleSetInputsData_clubScore_discountPercent}
                                loading={loading}
                            />
                            <FormDynamicInputCurrency
                                title={"مبلغ شارژ باشگاه مشتریان"}
                                id={"clubScore_discountAmount"}
                                value={inputsData.clubScore?.discountAmount}
                                setInputsValue={handleSetInputsData}
                                disabled={true}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default VisitTime