import React, { useState, useEffect } from 'react';
import FormDynamicInputText from './formDynamicInputText'
import FormDynamicInputDate from './formDynamicInputDate'
import FormDynamicInputNumber from './formDynamicInputNumber'
import { checkAllValidations } from '../validastions/validastions'
import FormDynamicInputImage from './formDynamicInputImage';
import FormDynamicInputSelect from './formDynamicInputSelect';
import FormDynamicInputMultiSelect from './formDynamicInputMultiSelect';
import FormDynamicInputCurrency from './formDynamicInputCurrency'
import { getLocalizationTitleForInput } from '../localization/localization';

const FormDynamicInputs = (props) => {
    const { setInputsValues, setInputsvalidations } = props;
    const [error, setError] = useState({ error: false, message: "" });
    const setInputsValue = (target, property) => {
        //console.log('FormDynamicInputs',target, props.input.title)
        setInputsValues(target, property)
        setError(checkAllValidations(target, props.input.validations, props.input.title))
        setInputsvalidations(checkAllValidations(target, props.input.validations, props.input.title), props.input.title)
    }

    useEffect(() => {
        //console.log('props.value',props.value, 'props.input.title',props.input.title)
        setInputsvalidations(checkAllValidations(props.value, props.input.validations, props.input.title), props.input.title)
        if (!props.initallValidationsIsNotActive) {
            setError(checkAllValidations(props.value, props.input.validations, props.input.title))
        }
        return () => {
            return "";
        }
    }, [props.value]);

    switch (props.input.type) {
        case "number":
            return (
                <FormDynamicInputNumber
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    loading={props.loading}
                />
            );
        case "text":
            return (
                <FormDynamicInputText
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    loading={props.loading}
                    type={props.input.type}
                />
            );
        case "password":
            return (
                <FormDynamicInputText
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    loading={props.loading}
                    type={props.input.type}
                />
            );
        case "date":
            return (
                <FormDynamicInputDate
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    loading={props.loading}
                />
            );
        case "image":
            return (
                <FormDynamicInputImage
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    fileId={props.input.fileId}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    loading={props.loading}
                />
            );
        case "select":
            return (
                <FormDynamicInputSelect
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    listSelect={props.input.listSelect}
                    loading={props.loading}
                />
            );
        case "multiSelect":
            return (
                <FormDynamicInputMultiSelect
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    listSelect={props.input.listSelect}
                    loading={props.loading}
                    isMultiple={true}
                />
            );
        case "enum":
            return (
                <FormDynamicInputSelect
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    listSelect={props.input.enums}
                    loading={props.loading}
                />
            );
        case "enumColor":
            return (
                <FormDynamicInputSelect
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    listSelect={props.input.enums}
                    loading={props.loading}
                    isEnumColor={true}
                />
            );
        case "currency":
            return (
                <FormDynamicInputCurrency
                    title={getLocalizationTitleForInput(props.input)}
                    id={props.input.title}
                    value={props.value}
                    setInputsValue={setInputsValue}
                    error={error.error}
                    helperText={error.message}
                    listSelect={props.input.enums}
                    loading={props.loading}
                />
            );

        default:
            return (<div></div>)
    }
}

export default FormDynamicInputs;