import { isExpired, setUserLanguage, userLanguage } from "../../services/auth-header";
import { organizationId } from "../../services/organizationConfig";
import { localizationData } from './localizationData.tsx'
export const DefaultLanguage = () => {
    if (organizationId()===3 || organizationId()===4 ) {
        return "en";
    }
    return "fa";
}

export const SetLocalization = (lng) => {
    //console.log(lng,isExpired())

    localStorage.setItem("language", lng);
    if (!isExpired()) {
        setUserLanguage(lng)
    }
}

export const GetLocalization = () => {
    //console.log('GetLocalization',isExpired())
    if (isExpired()) {
        return GetLocalizationGuest();
    }
    else {
        return userLanguage();
    }
}

export const GetLocalizationGuest = () => {
    let lng = localStorage.getItem('language')
    if (lng) {
        return lng;
    }
    else {
        localStorage.setItem("language", DefaultLanguage());
        return DefaultLanguage();
    }
}

export const GetDirection = () => {
    return CreateDirection(GetLocalization())
}

export const CreateDirection = (lng) => {
    if (lng == 'fa') {
        return "rtl";
    }
    else {
        return "ltr";
    }
}

export const languageList = () => {
    return [
        { value: "en", text: "English" },
        { value: "fa", text: "پارسی" }
    ]
}

export const getLocalizationTitle = (title) => {
    var lng = GetLocalization()

    var data = localizationData.filter(o => o.title == title)[0];

    if (data) {
        switch (lng) {
            case "en":
                return data.en;
            case "fa":
                return data.fa;
            default:
                return title
        }
    }
    else {
        return title;
    }
}

export const getLocalizationTitleByData = (data) => {

    var lng = GetLocalization()

    if (data) {
        switch (lng) {
            case "en":
                return data.en;
            case "fa":
                return data.fa;
            default:
                return data.en
        }
    }
    else {
        return data.en;
    }
}


export const getLocalizationTitleForInput = (props) => {

    var lng = GetLocalization()

    switch (lng) {
        case "en":
            return (props.labelEn ?? props.title);
        case "fa":
            return props.label;
        default:
            return props.title
    }

}


export const LocalizationJson = (json) => {

    var lng = GetLocalization()
    try {
        var object = JSON.parse(json)

        switch (lng) {
            case "en":
                return object?.en;
            case "fa":
                return object?.fa;
            default:
                return ""
        }
    } catch (e) {
        return json
    }
}