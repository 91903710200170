import axios from 'axios';
import authHeader from './auth-header';
import ApiURL from './api-url';

const API_URL = ApiURL() + 'services/app/ContactUs';
class ContactUsService {

    Create(model) {
        return axios.post(API_URL + '/Create', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }
}

export default new ContactUsService();