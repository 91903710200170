
import React, { useState, useEffect } from 'react';
import FormDynamicInputText from '../formDynamic/formDynamicInputText';
import { getLocalizationTitle } from '../localization/localization';

const GridContainerSearch = (props) => {
    const [search, setSearch] = useState(props.search);
    const [firstRun, setFirstRun] = useState(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!firstRun) {
                props.setSearch(search);
            }
            setFirstRun(false)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [search])

    return (
        <div>
            <FormDynamicInputText
                autoFocus
                title={getLocalizationTitle("Search")}
                setInputsValue={setSearch} />
        </div>
    );
}
export default GridContainerSearch;
