import axios from 'axios';
import authHeader from './auth-header';
import ApiURL from './api-url';
import { organizationId } from './organizationConfig';

const API_URL = ApiURL() + 'services/app/MyFile';
class MyFileService {

    GetAll(skipCount, maxResultCount, search, fileType, isActive) {
        return axios.get(API_URL + "/GetAll?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&search=" + search + "&fileType=" + fileType + "&isActive=" + isActive, { headers: authHeader() });
    }
    GetAllPublic(skipCount, maxResultCount, groupId, fileType) {
        //console.log('GetAllPublic',organizationId())
        return axios.get(API_URL + "/GetAllPublic?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&organizationId=" + organizationId() + "&groupId=" + groupId + "&fileType=" + fileType, { headers: authHeader() });
    }

    GetAllGroup(skipCount, maxResultCount, isActive) {
        //console.log('GetAllPublic',organizationId())
        return axios.get(API_URL + "/GetAllGroup?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&organizationId=" + organizationId() + "&isActive=" + isActive, { headers: authHeader() });
    }

    CreateImage(model) {
        return axios.post(API_URL + '/CreateImage', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Delete(id) {
        return axios.delete(API_URL + '/Delete?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }
    /*
    Update(model) {
        return axios.put(API_URL+'/Update', model, { headers: authHeader()} )
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }*/
    GetImage(id) {
        return axios.get(API_URL + "/GetImage?id=" + id, { headers: authHeader() });
    }
    GetImagePublic(id) {
        return axios.get(API_URL + "/GetImagePublic?id=" + id, { headers: authHeader() });
    }
}

export default new MyFileService();