import React, { useState, useEffect } from 'react';
import JalaliUtils from "@date-io/jalaali";
import { DatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";

const FormDynamicInputDate = (props) => {
    const [value, setValue] = useState(props.value ? props.value : "");
    const { setInputsValue } = props;

    const sendValueToInputs = (value) => {
        if (value) {
            setValue(value.toDate())
            setInputsValue(value.toDate(), props.id)
        }
        else {
            setValue("")
            setInputsValue("", props.id)
        }
    }

    useEffect(() => {
        setValue(props.value ? props.value : "")
    }, [props.value]);

    return (
        <div className={(props.className ?? "col-12 col-sm-12")}>
            <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
                <DatePicker
                    fullWidth
                    size="small"
                    inputProps={props.textAlign ? { min: 0, style: { textAlign: props.textAlign } } : ''} // the change is here
                    //clearable
                    label={props.title}
                    okLabel="تأیید"
                    cancelLabel="لغو"
                    clearLabel="پاک کردن"
                    labelFunc={props.dateFormat ? (date => (date ? date.format(props.dateFormat) : "")) : (date => (date ? date.format("jYYYY/jMM/jDD") : ""))}
                    value={value}
                    onChange={sendValueToInputs}
                    error={props.error}
                    helperText={props.helperText}
                    disabled={props.disabled || props.loading}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default FormDynamicInputDate;