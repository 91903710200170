import educationDto from './educationDto'

export const educationData: educationDto[] = [
    {
        field: "IT Engineering - BE",
        grade: "Bachelor of Engineering - BE",
        school: "University of Applied Science and Technology (Amir Kabir)",
        degree: "3.1/4",
        address: "Tehran, Iran",
        fieldOfStudy: "Information Technology - Electronic commerce",
        //startDate: new Date(2011, 12, 1),
        //endDate: new Date(2013, 12, 1),
        endDate:"Feb 2015",
        description: "Electronic commerce, Concept of Software Engineering, Database, Algorithm design, Computer architecture, Logic circuit, data structure, Business Intelligence, E-Commerce Security, Electronic marketing, Programming language: C++, C#, VB",
        webAddress:"https://www.acmt.ac.ir/"
    }
];