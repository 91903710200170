import entityTemplateDto from '../entityTemplateDto'

export const RoleTemplate: entityTemplateDto[] = [
    {
        title: "name",
        type: "text",
        label: "نام",
        labelEn: "Name",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" }, { type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "displayName",
        type: "text",
        label: "نام نمایش",
        labelEn: "Display Name",
        filter: {},
        minWidth: "130px",
        validations: [{ type: "Required" }, { type: "MaxLength", length: 50 }],
        showForm: true
    },
    {
        title: "description",
        type: "text",
        label: "توضیحات",
        labelEn: "description",
        filter: {},
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }],
        showForm: true
    },
    {
        title: "grantedPermissionsGrid",
        type: "string",
        label: "دسترسی ها",
        labelEn: "Permissions",
        filter: {},
        minWidth: "150px",
        showGrid: true,
        showList: 1,
    },
    {
        title: "grantedPermissions",
        type: "multiSelect",
        label: "دسترسی ها",
        labelEn: "Permissions",
        filter: {},
        showList: 1,
        minWidth: "130px",
        showForm: true,
        listSelect: [],
        validations: [{ type: "Required" }],
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }
];