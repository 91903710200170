import React, { useState, useEffect } from 'react';
import CustomerService from '../../../services/customer.service'
import Dialog from '@material-ui/core/Dialog';
import GridContainerSearch from '../../grid/gridContainerSearch.component'
import './selectCustomer.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../button/button';
import { useSnackbar } from 'notistack';
import { GetDirection } from '../../localization/localization';
import BackButton from '../../button/backButton';

const SelectCustomer = (props) => {
    const [content, setContent] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const { close, state } = props;
    const { enqueueSnackbar } = useSnackbar();

    const handleSetSearchValue = (value) => {
        //console.log(value)
        setSearchValue(value)
        if (value) {
            getAll(value)
        }
    };


    const handleClose = (customerId, firstName, lastName, cellPhoneNumber, code) => {
        close(customerId, firstName, lastName, cellPhoneNumber, code);
    };

    const getAll = (value) => {
        setLoading(true)
        setMessage("مراجعه کننده پیدا نشد")
        CustomerService.GetAll(0, 20, value, true).then(
            response => {
                setLoading(false)
                //console.log(response.data.result.items)
                setContent(response.data.result.items);
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    useEffect(() => {

        return () => {
            setSearchValue("")
            setContent([])
            setLoading(false)
            setMessage("لطفا جستجو نمایید")
            return "";
        }
    }, [state]);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("لطفا جستجو نمایید");

    return (
        <Dialog fullWidth={true} open={state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => handleClose(0, '')}
                />
                <div>انتخاب مراجعه کننده</div>
                <div></div>
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                <div>
                    <GridContainerSearch
                        setSearch={handleSetSearchValue}
                        search={searchValue}
                    />
                </div>
                <div>
                    {loading ?
                        (
                            <div>
                                <div className="homeCircularProgress" >
                                    <CircularProgress />
                                </div>
                            </div>
                        )
                        :
                        (content?.length > 0)
                            ? content.map((item, index) => (
                                <div
                                    className="SelectCustomer"
                                    onClick={() => handleClose(item.id, item.firstName, item.lastName, item.cellPhoneNumber, item.code)}
                                >
                                    <div className="SelectCustomerItem" >{`${item.code} `}</div>
                                    <div className="SelectCustomerItem" >{`${item.firstName} ${item.lastName} ${item.cellPhoneNumber}`}</div>
                                </div>
                            ))
                            : (<div style={{ textAlign: "center" }}>{message}</div>)
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default SelectCustomer;