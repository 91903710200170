import React, { useEffect, useState } from 'react'
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaletteIcon from '@material-ui/icons/Palette';
import LanguageIcon from '@material-ui/icons/Language';
import './headerUser.css'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UserMyself from './userMyself';
import CheckIcon from '@material-ui/icons/Check';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useHistory } from "react-router-dom";
import { GetLocalization, getLocalizationTitle, languageList, SetLocalization } from '../localization/localization';
import { GetTheme, SetTheme, themeList } from '../theme/theme'
import { isExpired, userImage } from '../../services/auth-header';
import UserService from '../../services/user.service'
import { useSnackbar } from 'notistack';
import { HistoryPushAfterLogin, organizationId } from '../../services/organizationConfig';
import HomeIcon from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';

const HeaderUser = (props) => {
    const [userState, setUserState] = useState({ state: false });
    function openUser() {
        setUserState({ state: true })
    }
    function closeUser() {
        setUserState({ state: false });
    }

    function getImage() {
        return userImage();
    }


    let history = useHistory();
    function handleHistory(link) {
        history.push(link);

    }

    const getLanguageSelect = () => {
        return GetLocalization();
    }

    const setLanguage = (lng) => {
        SetLocalization(lng)
        window.location.reload();
    }

    const getThemeSelect = () => {
        return GetTheme();
    }

    const handelSetTheme = (input) => {
        SetTheme(input)
        //SetThemeCSS(input)
        window.location.reload();
    }

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const UpdateUserMyselfTheme = (input) => {
        if (isExpired()) {
            handelSetTheme(input)
        }
        else {
            setLoading(true)
            UserService.UpdateUserMyselfTheme(input).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('زمینه تغییر کرد', { variant: 'success' })
                        handelSetTheme(input)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    const UpdateUserMyselfLanguage = (input) => {
        if (isExpired()) {
            setLanguage(input)
        }
        else {
            setLoading(true)
            UserService.UpdateUserMyselfLanguage(input).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('زبان تغییر کرد', { variant: 'success' })
                        setLanguage(input)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    useEffect(() => {
        return () => {

            return "";
        }
    }, []);

    return (
        <div className="HeaderUser" >
            <div className="ThemeIcon SettingIcon">
                <PaletteIcon />
                {loading ? (null) : (
                    <div className="ThemeContent SettingContent">
                        {themeList().map(item => (
                            <div onClick={() => UpdateUserMyselfTheme(item.value)} style={{ backgroundColor: item.text }}>{getThemeSelect() == item.value ? (<CheckIcon />) : (null)}</div>
                        ))}
                    </div>
                )}
            </div>
            <div className="LanguageIcon SettingIcon">
                <LanguageIcon />
                {loading ? (null) : (
                    <div className="LanguageContent SettingContent">
                        {languageList().map(item => (
                            <div onClick={() => UpdateUserMyselfLanguage(item.value)} >{item.text} {getLanguageSelect() == item.value ? (<CheckIcon />) : (null)}</div>
                        ))}
                    </div>
                )}
            </div>
            <div className="UserIcon SettingIcon">
                {getImage() ? (<img src={`data:image/jpeg;base64,${getImage()}`} className="UserAvatar" />) : (<AccountCircleIcon />)}
                <div className="UserContent SettingContent">
                    {props.currentUser ?
                        (
                            <div onClick={openUser} >
                                <SettingsIcon />
                                <span>{getLocalizationTitle("UserSettings")}</span>
                            </div>
                        ) : (null)}
                    {props.currentUser && props.locationPathName == "/home" ?
                        (
                            <div onClick={() => handleHistory(HistoryPushAfterLogin())}>
                                <DashboardIcon />
                                <span>{getLocalizationTitle("Dashboard")}</span>
                            </div>
                        ) : (null)}
                    {props.locationPathName != "/home" ?
                        (
                            <div onClick={() => handleHistory('/home')}>
                                <HomeIcon />
                                <span>{getLocalizationTitle("Home")}</span>
                            </div>
                        ) : (null)}
                    {props.currentUser ?
                        (
                            <div onClick={props.logOut}>
                                <ExitToAppIcon />
                                <span>{getLocalizationTitle("LogOut")}</span>
                            </div>
                        ) : (
                            <div onClick={props.logIn}>
                                <ExitToAppIcon />
                                <span>{getLocalizationTitle("LogIn")}</span>
                            </div>
                        )}
                    {/* {(props.currentUser || !(organizationId() == 4 || organizationId() == 0)) ?
                        (null) : (
                            <div onClick={props.register}>
                                <AddIcon />
                                <span>{getLocalizationTitle("Register")}</span>
                            </div>
                        )} */}
                </div>
            </div>
            <UserMyself
                state={userState}
                close={closeUser}
            />
        </div>
    );
}

export default HeaderUser


