import React from 'react'
import './footer.css'
const Footer = (props) => {
    return (
        <footer id="myFooter" className="myFooter homeSectionColor">
            <div>
                Copyright  © {new Date().getUTCFullYear()} Majid Ghajari. All rights reserved<br />
                <a href="http://www.majidg.com" target="_blank"> www.majidg.com</a><br />
                ghajari3@gmial.com
            </div>
        </footer>
    )
}
export default Footer
