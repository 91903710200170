import entityTemplateDto from '../entityTemplateDto'

export const NoteTemplate: entityTemplateDto[] = [
    {
        title: "title",
        type: "text",
        label: "عنوان",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }, { type: "Required" }],
        showGrid: true,
        showForm: true,
        showList: 1
    },
    {
        title: "description",
        type: "text",
        label: "توضیحات",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 2000 }, { type: "Required" }],
        showGrid: true,
        showForm: true,
        //showList: 1
    },
    {
        title: "keyWords",
        type: "text",
        label: "کلمات کلیدی",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 100 }, { type: "Required" }],
        showGrid: true,
        showForm: true,
        showList: 1
    },

    {
        title: "imageFileData",
        type: "image",
        fileId: "imageId",
        label: "تصویر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "fileType", types: "jpg jpeg" }],
        showForm: true,
        showGrid: true
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        filter: {},
        minWidth: "80px",
        showGrid: true,
        showList: 1
    }
];