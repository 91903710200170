import platformMajPictureZoomDto from '../../platformMaj/platformMajPictureZoomDto'

const date1 = new Date();
const date2 = new Date(2013, 11, 22);
const diff = new Date(date1.getTime() - date2.getTime());

export const homeGhajari3AboutTemplate: platformMajPictureZoomDto =

{
    id: "AboutMeWallpaper",
    fileFormat: '.jpg',
    length: 20,
    distance: 100,
    backColor: 'var(--backColorHover)',
    minSizeFrame: { width: 300, height: 300 },
    title: "",
    description:
        `<h3><b>OBJECTIVE</b></h3>
            <p>
            To gain a challenging position where I can leverage my extensive expertise in a wide range of cutting-edge technologies, such as React JS, JavaScript, CSS, ASP.NET, and more. I am eager to contribute my skills and experience to innovative projects in web development.
            </p>
        <h3><b>Background</b></h3>
            <p>
            I have been working as a software engineer since 2013 in various positions, particularly in web development across domains such as banking, stock exchanges, financials, accounting, CRM, and HR. I have experience working in agile team environments. Currently based in Victoria, British Columbia, Canada, I am eager to enhance my expertise within the British Columbia province. I received my bachelor's degree in information technology engineering from a university in Iran. Additionally, I have experience with programming education for teenagers and adults.
            </p>
            `,
    logo: "AboutMeLogo.png"
}