import React from 'react'
import { getLocalizationTitle, GetLocalization } from '../../localization/localization'
import InstagramIcon from '@material-ui/icons/Instagram';
import CallIcon from '@material-ui/icons/Call';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const ContactUsSepand = (props) => {
    return (
        <p>
        <div>
            <InstagramIcon />
            sepand_dental_office
        </div>
        <div>
            <PhoneIphoneIcon />
            09212400471
        </div>
        <div>
            <CallIcon />
            02122801311
        </div>
        <div>
            <BusinessIcon />
            {getLocalizationTitle("SepandDentalAddress")}
        </div>
    </p>
    )
}
export default ContactUsSepand
