import axios from "axios";
import ApiURL from './api-url';

const API_URL = ApiURL();

const layoutsData = {
  lg: [{ i: 'n80', w: 3, h: 1, x: 0, y: 500, minW: 1, minH: 1, maxW: 4, maxH: 2 }],
  md: [{ i: 'n80', w: 2, h: 1, x: 0, y: 500, minW: 1, minH: 1, maxW: 4, maxH: 2 }],
  sm: [{ i: 'n80', w: 1, h: 1, x: 0, y: 500, minW: 1, minH: 1, maxW: 4, maxH: 2 }],
  xs: [{ i: 'n80', w: 1, h: 1, x: 0, y: 500, minW: 1, minH: 1, maxW: 4, maxH: 2 }],
  xxs: [{ i: 'n80', w: 1, h: 1, x: 0, y: 500, minW: 1, minH: 1, maxW: 4, maxH: 2 }]
}
const windows = [{ i: 'n80' }];

class AuthService {
  login(username, password,token) {
    return axios
      .post(API_URL + 'TokenAuth/Authenticate', {
        userNameOrEmailAddress: username,
        password: password,
        rememberClient: true,
        recaptchaToken:token
      })
      .then(response => {
        if (response.data.result.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  register(inputs) {
    return axios
      .post(API_URL + "signup", inputs)
      .then(response => {
        if (response.data.result.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  RegisterPublicUserOntime(model) {
    console.log('RegisterPublicUserOntime',model)
    return axios.post(API_URL + 'TokenAuth/RegisterPublicUserOntime', model)
    .then(response => {
        return {
            success: true,
            response: response.data.result
        };
    })
    .catch((error) => {
        return {
            success: false,
            response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
        };
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();