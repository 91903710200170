import React, { useEffect, useState } from 'react'
import ProductService from '../../../services/product.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../../formDynamic/formDynamic'

const ProductImage = (props) => {
    const { close, state, inputs } = props;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({});

    const handleClose = (id) => {
        close(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const send = () => {
        setLoading(true)
        if (state.id) {
            /*ProductService.UpdateImage(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ویرایش شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );*/
        }
        else {
            //console.log('send',inputsData,state)
            ProductService.CreateImage({
                imageFileData: inputsData.imageFileData,
                productId: state.productId,
            }).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ذخیره شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    useEffect(() => {
        if (state.id && state.state) {
            //get()
        }
        return () => {
            setInputsData({});
            return "";
        }
    }, [state.productId]);

    return (
        <FormDynamic
            formTitle={"اضافه کردن تصویر"}
            inputs={inputs}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default ProductImage


