import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const FormDynamicInputMultiSelect = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState([]);
    const { setInputsValue } = props;

    //const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        //console.log(event.target.value)
        //setPersonName(event.target.value);
        setValue(event.target.value)
        setInputsValue(event.target.value, props.id)
    };


    /*const sendValueToInputs = (value) => {
        setValue(value)
        setInputsValue(value, props.id)
    }*/

    useEffect(() => {
        //console.log('FormDynamicInputMultiSelect useEffect',(props.value ? props.value : []))
        setValue(props.value ? props.value : [])
    }, [props.value]);

    return (
        <FormControl fullWidth>
            <InputLabel id="FormDynamicInputMultiSelect-label">{props.title}</InputLabel>
            <Select
                fullWidth
                labelId="FormDynamicInputMultiSelect-label"
                id={props.id}
                multiple
                value={value}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled || props.loading}s
            >
                {props.listSelect.map((item) => (
                    <MenuItem
                        key={item.index}
                        value={item.value}
                        //style={getStyles(item, value, theme)}
                        >
                        {item.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default FormDynamicInputMultiSelect;