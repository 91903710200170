import axios from 'axios';
import authHeader from './auth-header';
import ApiURL from './api-url';

const API_URL = ApiURL() + 'services/app/user';

class UserService {
    UpdateUserMyself(model) {
        return axios.put(API_URL + '/UpdateUserMyself', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    UpdateUserMyselfTheme(model) {
        return axios.put(API_URL + '/UpdateUserMyselfTheme?input=' + model, null, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    UpdateUserMyselfLanguage(model) {
        return axios.put(API_URL + '/UpdateUserMyselfLanguage?input=' + model, null, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    GetUserMyself() {
        return axios.get(API_URL + "/GetUserMyself", { headers: authHeader() });
    }

    GetAll(skipCount, maxResultCount, search, isActive) {
        return axios.get(API_URL + "/GetAll?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&search=" + search + "&isActive=" + isActive, { headers: authHeader() });
    }

    GetRoles() {
        return axios.get(API_URL + "/GetRoles", { headers: authHeader() });
    }

    GetOrganizations() {
        return axios.get(API_URL + "/GetOrganizations", { headers: authHeader() });
    }

    Create(model) {
        return axios.post(API_URL + '/Create', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Update(model) {
        return axios.put(API_URL + '/Update', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در آپدیت، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Get(id) {
        return axios.get(API_URL + "/Get?id=" + id, { headers: authHeader() });
    }
    Delete(id) {
        return axios.delete(API_URL + '/Delete?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message ?? 'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }
}

export default new UserService();