import React, { useEffect, useState, useRef } from 'react'
import { organizationId } from '../services/organizationConfig'
import './showExperienceWithScroll.css'
import Button from '../components/button/button'
import { getLocalizationTitle } from '../components/localization/localization'
import { languageData } from '../components/home/ghajari3/experienceData'
import ShowExperienceWithScrollProject from './showExperienceWithScrollProject'
import CheckIcon from '@material-ui/icons/Check';
import { useSelector } from 'react-redux'

const ShowExperienceWithScroll = (props) => {
    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    
    const [position, setPosition] = useState("relative")//fixed//static//relative//absolute//sticky
    const [justifyContent, setJustifyContent] = useState("flex-start")//flex-end//flex-start

    const [height, setHeight] = useState(((props.experiences.length * props.item.distance) - props.item.distance) * ((props.item.imageSizeHeight / props.item.distance)) + (props.item.distance * props.experiences.length))

    const setId = () => {
        return `pmAnimationLogo${props.item.id}`;
    };

    const startItem = () => {
        return document.getElementById(setId())?.offsetTop;
    };

    const EndItem = () => {
        return startItem() + height;
    };

    const itemScrollHeight = () => {
        return height + innerSize.height;
    };

    const scrollinItem = () => {
        return scrollY - startItem();
    };

    const locationItem = (item) => {
        return startSheetItem(item) - scrollinItem();
    }


    const setScrollItem = (item) => {
        const loc = locationItem(item);

        if (loc <= startSheet()) {
            return startSheet();
        }
        else {
            return loc;
        }
    };

    const setOpacityItem = (item) => {

        const loc = locationItem(item);

        if (loc <= startSheet()) {
            if (item == (props.experiences.length - 1)) {
                return 1;
            }
            else {
                return ((loc + startSheet()) / 100);
            }
        }
        else {
            return 1;
        }
    };

    const setZommItem = (item) => {
        const loc = locationItem(item);
        if (loc <= startSheet()) {
            return true;
        }
        else {
            return false;
        }
    }
    const getlanguageWebSite = (id) => {
        return languageData.filter(row => row.id == id)[0]?.webSite
    };
    const getlanguageTitle = (id) => {
        return languageData.filter(row => row.id == id)[0]?.title
    };

    const showLanguage = (array) => {
        if (array) {
            return array.map((item, index) =>
                (<a href={getlanguageWebSite(item)} target="_blank"><img src={process.env.PUBLIC_URL + `language/${item}.png`} title={getlanguageTitle(item)} alt={getlanguageTitle(item)} /></a>))
        }
        return null;
    };


    const startSheet = () => {
        return (props.item.imageSizeHeight / 2);
    }

    const startSheetItem = (item) => {
        return (innerSize.height / 2) + ((item * (props.item.imageSizeHeight + props.item.distance)) - startSheet());
    }

    const [projectDialogState, setProjectDialogState] = useState({ state: false, tags: "" });

    const openProjectDialog = (tags) => {
        setProjectDialogState({ state: true, tags: tags })
    }

    const closeProjectDialog = () => {
        setProjectDialogState({ state: false, tags: "" })
    }


    useEffect(() => {
        //console.log('useEffect ShowExperienceWithScroll', props.experiences.length, props.programmingLanguages, props.experiences)

        if (scrollY < startItem()) {
            setPosition("relative")
            setJustifyContent("flex-start")
        }
        else if (scrollY > EndItem()) {
            setPosition("relative")
            setJustifyContent("flex-end")
        }
        else {
            setPosition("fixed")
            setJustifyContent("flex-start")
        }
        //console.log(scrollY, startItem(), EndItem())
        return () => {

            return "";
        }
    }, [scrollY, innerSize]);

    return (
        <div
            className="ShowExperienceWithScrollContainer"
            style=
            {{
                display: 'flex',
                flexDirection: 'column',
                height: itemScrollHeight(),
                minHeight: itemScrollHeight(),
                justifyContent: justifyContent,
                backgroundColor: props.item.backColor,
            }}
            id={setId()}
        >
            {props.item.title ? (
                <div style={{ position: position, }} className="ShowExperienceWithScrollTitle">
                    <h2>{props.item.title}</h2>
                    <div
                        dangerouslySetInnerHTML={{ __html: props.item.description }}
                    />
                </div>
            ) : (null)}
            {/* <div
                className="ShowExperienceWithScrollBackground"
                style={{
                    backgroundImage: `url(organization${organizationId()}/${props.item.fileTitle}${props.item.fileFormat})`,
                    position: position,
                }}
            >
            </div> */}
            <div
                style={{
                    position: position,
                    height: innerSize.height,
                    zIndex: props.item.index,
                    position: "relative"
                }}>
                {props.experiences.map((experienceItem, experienceIndex) => (
                    <div
                        className="ShowExperienceWithScrollItem"
                        style={{
                            position: (position == "relative" ? "absolute" : position),
                            top: `${setScrollItem(experienceIndex)}px`,
                            opacity: setOpacityItem(experienceIndex),
                            transform: `scale(${setZommItem(experienceIndex) ? 0.9 : 1})`,
                            zIndex: 1,
                            overflow: 'hidden',
                        }}>
                        {experienceItem.type !== 'project' ? (
                            <div className='ShowExperienceWithScrollItemBody' style={{ overflow: 'hidden' }}>
                                <div className="ShowExperienceWithScrollItemTitle">
                                    <h3>{experienceItem.title}</h3>
                                    <h4>at <a target="_blank" href={experienceItem.webSite}><span>{experienceItem.companyName}</span></a></h4>
                                </div>
                                <div className="ShowExperienceWithScrollItemPeriod">
                                    From <span style={{ fontWeight: 'bold' }}>{experienceItem.startDateS} </span>
                                    to <span style={{ fontWeight: 'bold' }}>{experienceItem.endDateS ? experienceItem.endDateS : "Now"}</span>
                                </div>
                                <div><h4>{experienceItem.address}</h4></div>
                                <div>{experienceItem.description}</div>
                            </div>
                        ) : (
                            <div>
                                <div className='ShowExperienceWithScrollItemBody' style={{ overflow: 'hidden' }}>
                                    <div className="ShowExperienceWithScrollItemTitle">
                                        <a target="_blank" href={experienceItem.webSite}><h3>{experienceItem.title}</h3></a>
                                    </div>
                                    <div>
                                        <div className="ShowExperienceWithScrollItemPeriod">Release Date: <span>{experienceItem.projects[0].releaseDate ? experienceItem.projects[0].releaseDate : "implementing"}</span></div>
                                        {experienceItem.description}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                        }}>
                            <Button
                                click={() => openProjectDialog(
                                    experienceItem.projects.map((projectItem, projectIndex) => (
                                        <div className="ShowExperienceWithScrollItemTitleProject">
                                            <h4><CheckIcon fontSize="small" /> {projectItem.title} <span>{projectItem.projectName}</span></h4>
                                            <p>
                                                <h5>{projectItem.description}</h5>
                                                <div className="ShowExperienceWithScrollItemTitleProjectLanguage">
                                                    <div><span>Technologies Utilized</span></div>
                                                    <div>
                                                        {showLanguage(projectItem.FrontEnd)}
                                                        {showLanguage(projectItem.BackEnd)}
                                                        {showLanguage(projectItem.DB)}
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    ))
                                )}
                                label={getLocalizationTitle((experienceItem.projects.length > 1 ? "More about projects" : "More about project"))}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <ShowExperienceWithScrollProject
                state={projectDialogState}
                close={closeProjectDialog}
            />
        </div>
    )
}
export default ShowExperienceWithScroll
