import axios from 'axios';
import authHeader from './auth-header';
import ApiURL from './api-url';
import  { organizationId } from './organizationConfig';

const API_URL = ApiURL() + 'services/app/CustomerFile';
class CustomerFileService {

    GetAll(skipCount, maxResultCount, search, fileType, isActive, customerId) {
        return axios.get(API_URL + "/GetAll?skipCount=" + skipCount + "&maxResultCount=" + maxResultCount + "&search=" + search + "&fileType=" + fileType + "&isActive=" + isActive + "&customerId=" + customerId, { headers: authHeader() });
    }

    Create(model) {
        //console.log('Create(model) ', model)
        return axios.post(API_URL + '/Create', model, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در ذخیره، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Delete(id) {
        return axios.delete(API_URL + '/Delete?id=' + id, { headers: authHeader() })
            .then(response => {
                return {
                    success: true,
                    response: response.data.result
                };
            })
            .catch((error) => {
                return {
                    success: false,
                    response: error?.response?.data?.error?.message??'خطا در حذف، اینترنت خود را بررسی نمایید'
                };
            });
    }
    Get(id) {
        return axios.get(API_URL + "/Get?id=" + id, { headers: authHeader() });
    }
}

export default new CustomerFileService();