import React, { useEffect, useState } from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import HttpIcon from '@material-ui/icons/Http';
import { useSnackbar } from 'notistack';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { organizationId } from '../../../services/organizationConfig';
import { getLocalizationTitle } from '../../localization/localization';
import { useSelector } from 'react-redux'

const ContactUsOntime = (props) => {
    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    
    const { enqueueSnackbar } = useSnackbar();
    const copyData = (data) => {
        navigator.clipboard.writeText(data)
        enqueueSnackbar(` ${data} کپی شد.`, { variant: 'success' })
    };

    const [showContactUs, setShowContactUs] = useState(false)
    const setId = () => {
        return `homeContactUsOntime`;
    };
    const startItem = () => {
        return document.getElementById(setId())?.offsetTop;
    };

    useEffect(() => {
        if (scrollY < (startItem() - ((innerSize?.height ?? 0) / 1.5))) {
            setShowContactUs(false);
        }
        else {
            setShowContactUs(true);
        }
        return () => {
            return "";
        }
    }, [scrollY]);

    return (
        <p id={setId()} className={`homeContactUsItemDetail ${showContactUs ? "homeContactUsItemDetailTransform" : ""}`}>
            {organizationId() == 4 ? (
            <div>
                <h3>
                    {getLocalizationTitle("OntimeOrder")}
                </h3>
            </div>
            ): (null)}
            <div>
                <InstagramIcon />
                <a href="https://www.instagram.com/ghajari3/" target="_blank">ghajari3</a>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => copyData('+1 (250) 884-8990')}>
                <PhoneIphoneIcon />
                +1 (250) 884-8990
            </div>
            <div>
                <EmailIcon />
                <a href="mailto: ghajari3@gmail.com" target="_blank">ghajari3@gmail.com</a>
            </div>
            {organizationId() == 3 ? (
                <div>
                    <LinkedInIcon />
                    <a href="https://www.linkedin.com/in/majid-ghajari-2302201b0/" target="_blank">majid ghajari</a>
                </div>
            ) : (
                <div>
                    <HttpIcon />
                    <a href="http://www.majidg.com" target="_blank">www.majidg.com</a>
                </div>
            )}
        </p>
    )
}
export default ContactUsOntime
