import React from 'react'
import { organizationId } from '../../services/organizationConfig';
import HomeIcon from '@material-ui/icons/Home';
import ImageIcon from '@material-ui/icons/Image';
import PeopleIcon from '@material-ui/icons/People';
import InfoIcon from '@material-ui/icons/Info';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TodayIcon from '@material-ui/icons/Today';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { userPermissions } from '../../services/auth-header';

export default function () {
    const permissions = userPermissions();//["Pages.Users","Pages.Customers"]
    const dashboardMenu =
        [
            { title: "DailyDashbourd", href: "/dashboard/dailyDashbourd", icon: (<TodayIcon />), isLogin: true, showDashboard: true, permission: "Pages.VisitTimes" },
            { title: "Customers", href: "/dashboard/customer", icon: (<PeopleIcon />), isLogin: true, showDashboard: true, permission: "Pages.Customers" },
            { title: "Visitors", href: "/dashboard/visitor", icon: (<PeopleOutlineIcon />), isLogin: true, showDashboard: true, permission: "Pages.Visitors" },
            { title: "Images", href: "/dashboard/image", icon: (<ImageIcon />), isLogin: true, showDashboard: true, permission: "Pages.MyFiles" },
            { title: "Users", href: "/dashboard/user", icon: (<PeopleIcon />), isLogin: true, showDashboard: true, permission: "Pages.Users" },
            { title: "Products", href: "/dashboard/product", icon: (<PeopleIcon />), isLogin: true, showDashboard: true, permission: "Pages.Users" },
            { title: "Roles", href: "/dashboard/role", icon: (<AccessibilityIcon />), isLogin: true, showDashboard: true, permission: "Pages.Roles" },
            { title: "Blogs", href: "/dashboard/blog", icon: (<AccessibilityIcon />), isLogin: true, showDashboard: true, permission: "Pages.Blogs" },
        ].filter((menuItem) => permissions?.filter((permissionItem) => permissionItem == menuItem.permission)[0]?.length > 0)

    if (organizationId() == 1) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showDashboard: true })
        return dashboardMenu;
    }
    else if (organizationId() == 2) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showDashboard: true },
            { title: "Visitors", href: "#homeVisitors", icon: (<PeopleIcon />), showHome: true },
            { title: "Images", href: "#homeImage", icon: (<ImageIcon />), showHome: true },
            { title: "AboutUs", href: "#homeAbout", icon: (<InfoIcon />), showHome: true },
            { title: "ContactUs", href: "#homeContactUs", icon: (<ContactMailIcon />), showHome: true })
        return dashboardMenu;
    }

    else if (organizationId() == 3) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showDashboard: true },
            { title: "AboutMe", href: "#homeAbout", icon: (<InfoIcon />), showHome: true },
            { title: "ContactMe", href: "#homeContactUs", icon: (<ContactMailIcon />), showHome: true })
        return dashboardMenu;
    }
    else if (organizationId() == 4) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showDashboard: true },
            { title: "Features", href: "#homeFeatures", icon: (<CheckBoxIcon />), showHome: true },
            { title: "AboutUs", href: "#homeAbout", icon: (<InfoIcon />), showHome: true },
            { title: "ContactUs", href: "#homeContactUs", icon: (<ContactMailIcon />), showHome: true })
        return dashboardMenu;
    }
    else if (organizationId() == 8) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showDashboard: true })
        return dashboardMenu;
    }
    else if (organizationId() == 9) {
        dashboardMenu.push(
            { title: "Home", href: "/home", icon: (<HomeIcon />), showHome: true , showDashboard: true },
            { title: "Images", href: "#homeImage", icon: (<ImageIcon />), showHome: true },
            { title: "AboutMe", href: "#homeAbout", icon: (<InfoIcon />), showHome: true },
            { title: "ContactMe", href: "#homeContactUs", icon: (<ContactMailIcon />), showHome: true })
        return dashboardMenu;
    }
}