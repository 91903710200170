import React, { useEffect, useState } from 'react'
import './contactUs.css'
import SendIcon from '@material-ui/icons/Send';
import { useSnackbar } from 'notistack';
import { organizationId } from '../../services/organizationConfig';
import Button from '../button/button';
import { getLocalizationTitle } from '../localization/localization';
import ContactUsService from './../../services/contactUs.service'
import { ContactUsTemplate } from './contactUsTemplate'
import FormDynamicInputs from '../formDynamic/formDynamicInputs';
import ContactUsOntime from './ontime/contactUsOntime';
import ContactUsSepand from './sepand/contactUsSepand';
import { googleReCaptcaSiteKey } from '../../services/googleReCaptcaSiteKey';
import ReCAPTCHA from "react-google-recaptcha";
import ContactUsMehrnaz from './mehrnaz/contactUsMehrnaz';
import { useSelector } from 'react-redux'

const ContactUs = (props) => {
    const recaptchaRef = React.useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [inputsData, setInputsData] = useState(
        {
            // name: "majid",
            // email: "ghajari3@gmail..com",
            // cellPhone: "12508848990",
            // message: "Majid Ghajari",
            // organizationId: organizationId()
            name: "",
            email: "",
            cellPhone: "",
            message: "",
            organizationId: organizationId()
        });

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const [validations, setValidations] = useState([]);
    const setInputsvalidations = (error, propertyId) => {
        var index = validations.findIndex((item, index) => { return item.id === propertyId })
        if (index >= 0) {
            validations[index] = {
                id: propertyId,
                error: error.error,
                message: error.message
            };
        }
        else {
            validations.push({
                id: propertyId,
                error: error.error,
                message: error.message
            });
        }
        setValidations(validations);
    }
    const [loading, setLoading] = useState(false);
    const handleSend = (token) => {
        //console.log('handleSend token',token)
        setLoading(true)

        ContactUsService.Create({
            name: inputsData.name,
            email: inputsData.email,
            cellPhone: inputsData.cellPhone,
            message: inputsData.message,
            organizationId: inputsData.organizationId,
            RecaptchaToken: token
        }).then(
            (response) => {
                setLoading(false)
                if (response.success) {
                    enqueueSnackbar('ارسال شد', { variant: 'success' })
                    setInputsData({
                        name: "",
                        email: "",
                        cellPhone: "",
                        message: "",
                        organizationId: organizationId()
                    });
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }



    const handleRecaptcha = () => {
        if (validations.filter(row => row.error)[0]?.id) {
            setLoading(false)
            validations.filter(row => row.error).map(row => (
                enqueueSnackbar(row.message, { variant: 'error' })
            ))
        }
        else {
            recaptchaRef.current.executeAsync().then((token) => {
                handleSend(token);
            })
        }
    }

    const detail = () => {
        switch (organizationId()) {
            case 2:
                return (<ContactUsSepand />);
            case 4:
                return (<ContactUsOntime/>);
            case 3:
                return (<ContactUsOntime/>);
            case 9:
                return (<ContactUsMehrnaz />);
            default:
                return (<div></div>);
        }
    }

    const title = () => {
        switch (organizationId()) {
            case 2:
                return getLocalizationTitle("ContactUs")
            case 4:
                return getLocalizationTitle("ContactUs")
            default:
                return getLocalizationTitle("ContactMe")
        }
    }

    useEffect(() => {

        return () => {
            return "";
        }
    }, []);

    return (
        <section id="homeContactUs" className="homeContactUs homeSection homeSectionColor">
            <div>
                <header>
                    <h2 className="homeSectionHeader">
                        {title()}
                    </h2>
                </header>
                <article className="homeContactUsContainer" >
                    <div className="homeContactUsItem">
                        {detail()}
                    </div>
                    <div className="homeContactUsItem">
                        <div className="homeContactUsForm">
                            {ContactUsTemplate.map((item, index) => (
                                <FormDynamicInputs
                                    key={index}
                                    input={item}
                                    setInputsValues={handleSetInputsData}
                                    value={inputsData[item.title]}
                                    setInputsvalidations={setInputsvalidations}
                                    loading={loading}
                                    initallValidationsIsNotActive={true}
                                />
                            ))}
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={googleReCaptcaSiteKey()}
                                theme="dark"
                                type="image"
                            />
                            <div>
                                <Button
                                    icon={<SendIcon />}
                                    click={() => handleRecaptcha()}
                                    label={getLocalizationTitle("Send")}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    )
}
export default ContactUs
