import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import FormDynamicInputs from './formDynamicInputs'
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import './formDynamic.css'
import { useSnackbar } from 'notistack';
import Button from '../button/button';
import { GetDirection } from '../localization/localization';
import BackButton from '../button/backButton';

const FormDynamic = (props) => {
    const [validations, setValidations] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const setInputsValues = (target, property) => {
        //console.log('FormDynamic', target, property)
        props.handleSetInputsData(target, property)
        //setInputsData(state => ({ ...state, [property]: target }))

        //console.log(inputsData)
    }

    const handleSend = () => {
        if (validations.filter(row => row.error)[0]?.id) {
            validations.filter(row => row.error).map(row => (
                enqueueSnackbar(row.message, { variant: 'error' })
            ))
        }
        else {
            props.send()
        }

    }

    const setInputsvalidations = (error, propertyId) => {
        var index = validations.findIndex((item, index) => { return item.id === propertyId })
        if (index >= 0) {
            validations[index] = {
                id: propertyId,
                error: error.error,
                message: error.message
            };
        }
        else {
            validations.push({
                id: propertyId,
                error: error.error,
                message: error.message
            });
        }
        setValidations(validations);
    }

    useEffect(() => {
        return () => {
            return "";
        }
    }, [props.state.state]);

    return (
        <Dialog fullWidth={true} open={props.state.state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => props.handleClose()}
                />
                <div>{props.formTitle}</div>
                <Button
                    icon={<DoneSharpIcon />}
                    click={() => handleSend()}
                    disabled={props.loading}
                />
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                <div className="row rowBuySellOpinionDialog" >
                    <div className="col " >
                        <div className="row" >
                            {props.inputs?.filter(r => (r.showForm == true && !(props.state.id > 0)) || ((r.showEdit ?? r.showForm) == true && props.state.id > 0)).map((item, index) => (
                                <FormDynamicInputs
                                    key={index}
                                    input={item}
                                    setInputsValues={setInputsValues}
                                    value={props.inputsData[item.title]}
                                    setInputsvalidations={setInputsvalidations}
                                    loading={props.loading}
                                />
                            ))}
                            {props.inputs?.filter(r => r.showFormDetail == true)?.length ?
                                <details>
                                    <summary>جزئیات</summary>
                                    <p>
                                        {props.inputs?.filter(r => r.showFormDetail == true).map((item, index) => (
                                            <FormDynamicInputs
                                                key={index}
                                                input={item}
                                                setInputsValues={setInputsValues}
                                                value={props.inputsData[item.title]}
                                                setInputsvalidations={setInputsvalidations}
                                                loading={props.loading}
                                            />
                                        ))}
                                    </p>
                                </details>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default FormDynamic
