import platformMajMovePicturesDto from '../../platformMaj/platformMajMovePicturesDto'

export const homeGhajari3InterestsTemplate: platformMajMovePicturesDto =

{
    id: "interests",
    title: "My interests",
    fileFormat: '.jpg',
    descriptions: [
        'Family',
        'Jay’s Shoes',
        'Hiking',
        'Hip hop',
        'Technology',
        'Sport',
        'Movie',
        'Animation',
        'Programming',
        'Cooking',
/**/

        //'Mountaineering',
        //'Basketball',
        //'Photography',
        //'Travel',
        //'hoodie',
    ],
    distanceTime: 25,
    distanceWidth: 20,
    width: 200,
    widthPhone: 200,
}