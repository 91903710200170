import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { GetLocalization } from '../localization/localization';
import CloseIcon from '@material-ui/icons/Close';

const BackButton = (props) => {
    return (
        GetLocalization()=="fa" ? (<ArrowForwardIosIcon />) : (<ArrowBackIosIcon />)
        //<CloseIcon />
    )
}
export default BackButton
