export function organizationId() {
    //organization3
    return 3;
}

export function HistoryPushAfterLogin() {
    switch (organizationId()) {
        case 1: //دکتر حاجی بابایی
            return "/dashboard/customer";
        case 2: //دندان پزشکی سپند
            return "/dashboard/customer";
        case 3: //ghajari3
            return "/dashboard/note";
        case 4: //0nTime
            return "/dashboard/customer";
        case 5: //NedaHb
            return "/dashboard";
        case 6: //In4
            return "/dashboard";
        case 8: //sell
            return "/dashboard/product";
        case 9: //mehrnaznailart
            return "/dashboard/image";
        default:
            return "/dashboard";
    }
}

export function HistoryPushAfterLogout() {
    switch (organizationId()) {
        case 1: //دکتر حاجی بابایی
            return "/home";
        case 2: //دندان پزشکی سپند
            return "/home";
        case 3: //ghajari3
            return "/login";
        case 4: //0nTime
            return "/home";
        case 5: //NedaHb
            return "/home";
        case 6: //In4
            return "/home";
        case 8: //sell
            return "/home";
        default:
            return "/home";
    }
}