import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Button from './../button/button';
import { useSnackbar } from 'notistack';
import './Login.css'
import { organizationId } from '../../services/organizationConfig';
import { GetTheme } from '../theme/theme';
import { RegisterTemplate } from './registerTemplate'
import FormDynamicInputs from '../formDynamic/formDynamicInputs';
import { googleReCaptcaSiteKey } from '../../services/googleReCaptcaSiteKey';
import ReCAPTCHA from "react-google-recaptcha";
import { GetDirection, getLocalizationTitle } from '../localization/localization';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Footer from '../footer/footer'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Register = (props) => {
    const recaptchaRef = React.useRef();
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [acceptRules, setAcceptRules] = useState(false)
        const [inputsData, setInputsData] = useState(
        {
            name: "",
            surname: "",
            emailAddress: "",
            userName: "",
            password: "",
            confirmPassword: "",
            organizationTitle: "",
            organizationEnTitle: "",
            organizationCellPhoneNumber: "",
            organizationWorkPhoneNumber: "",
            organizationAddress: "",
            organizationType: ""
        });

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const [validations, setValidations] = useState([]);
    const setInputsvalidations = (error, propertyId) => {
        var index = validations.findIndex((item, index) => { return item.id === propertyId })
        if (index >= 0) {
            validations[index] = {
                id: propertyId,
                error: error.error,
                message: error.message
            };
        }
        else {
            validations.push({
                id: propertyId,
                error: error.error,
                message: error.message
            });
        }
        setValidations(validations);
    }
    const [loading, setLoading] = useState(false);
    const RegisterPublicUserOntime = (token) => {
        //console.log('handleSend token',token)
        setLoading(true)

        AuthService.RegisterPublicUserOntime({
            name: inputsData.name,
            surname: inputsData.surname,
            emailAddress: inputsData.emailAddress,
            userName: inputsData.userName,
            password: inputsData.password,
            confirmPassword: inputsData.confirmPassword,
            organizationTitle: inputsData.organizationTitle,
            organizationEnTitle: inputsData.organizationEnTitle,
            organizationCellPhoneNumber: inputsData.organizationCellPhoneNumber,
            organizationWorkPhoneNumber: inputsData.organizationWorkPhoneNumber,
            organizationAddress: inputsData.organizationAddress,
            organizationType: inputsData.organizationType,

            recaptchaToken: token
        }).then(
            (response) => {
                setLoading(false)
                if (response.success) {
                    enqueueSnackbar('ثبت نام انجام شد، شما می توانید ورود نمایید.', { variant: 'success' })
                    setInputsData({
                        name: "",
                        surname: "",
                        emailAddress: "",
                        userName: "",
                        password: "",
                        confirmPassword: "",
                        organizationTitle: "",
                        organizationEnTitle: "",
                        organizationCellPhoneNumber: "",
                        organizationWorkPhoneNumber: "",
                        organizationAddress: "",
                        organizationType: ""
                    });
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }



    const handleRecaptcha = () => {
        if (validations.filter(row => row.error)[0]?.id) {
            setLoading(false)
            validations.filter(row => row.error).map(row => (
                enqueueSnackbar(row.message, { variant: 'error' })
            ))
        }
        else {
            recaptchaRef.current.executeAsync().then((token) => {
                RegisterPublicUserOntime(token);
            })
        }
    }

    const handleHome = () => {
        history.push("/home");
    }

    const organizationName = () => {
        switch (organizationId()) {
            case 1:
                return (
                    <p>
                        <h1>Amin Dental</h1>
                        <h4>Dr. Mohammad HajiBabaei</h4>
                    </p>
                );
            case 2:
                return (
                    <p>
                        <h1>Sepand Dental</h1>
                        <h4>DR. Mahtab Chaharkameh</h4>
                    </p>
                );
            case 3:
                return (
                    <p>
                        <h1>Engineer Majid Ghajari</h1>
                        <h4>Senior Software Developer</h4>
                    </p>
                );
            case 4:
                return (
                    <p>
                        <h1 style={{ display: "none" }}>Ontime</h1>
                        <h4>{getLocalizationTitle("ReserveAppointmentSystem")}</h4>
                    </p>
                );
            case 5:
                return (
                    <p>
                        <h1>MA Neda HajiBabaei</h1>
                        <h4>Senior Front-End Developer</h4>
                    </p>
                );
            case 6:
                return (
                    <p>
                        <h1>in4mationIn1</h1>
                        <h4>By Majid Ghajari</h4>
                    </p>
                );
            default:
                return (
                    <p>
                        <h1 >Majid Ghajari</h1>
                    </p>
                );
        }
    }

    const handleLogin = () => {
        history.push("/login");
    }

    useEffect(() => {

        return () => {
            return "";
        }
    }, []);

    return (
        <div className="registerContainer" dir={GetDirection()} >
            <div className="registerHeader" onClick={() => handleHome()}>
                <img src={process.env.PUBLIC_URL + `organization${organizationId()}/homeTopTheme${GetTheme()}.png`} />
                {organizationName()}
            </div>
            <div className="registerForm">
                <div>{getLocalizationTitle("OntimeRegisterHelp")}</div>
                <div className="registerInputs">
                    {RegisterTemplate.map((item, index) => (
                        <FormDynamicInputs
                            key={index}
                            input={item}
                            setInputsValues={handleSetInputsData}
                            value={inputsData[item.title]}
                            setInputsvalidations={setInputsvalidations}
                            loading={loading}
                            initallValidationsIsNotActive={true}
                        />
                    ))}
                </div>
                <div>{getLocalizationTitle("OntimeRegisterRules")}</div>
                <div>
                <FormControlLabel
                                control={
                                    <Switch
                                        checked={acceptRules}
                                        onChange={() => setAcceptRules(!acceptRules)}
                                        name="AcceptRules"
                                    />
                                }
                                label={getLocalizationTitle("AcceptRules")}
                            />
                </div>
                <div className="registerButton">
                    <Button
                        label={getLocalizationTitle("Register")}
                        disabled={loading || !acceptRules}
                        click={() => handleRecaptcha()} >
                    </Button>
                    <Button
                        variant="outlined"
                        icon={<NavigateNextIcon />}
                        label={getLocalizationTitle("LogIn")}
                        click={() => handleLogin()} >
                    </Button>
                </div>
            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={googleReCaptcaSiteKey()}
                theme="dark"
                type="image"
            />
            <Footer />
        </div>
    )
}
export default Register