import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux'
import store from './stateManagement/store';

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
