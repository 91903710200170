import React, { useEffect, useState } from 'react'
import VisitorService from '../../../services/visitor.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../../formDynamic/formDynamic'

const Visitor = (props) => {
    const { close, state, inputs } = props;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({});

    const handleClose = (id) => {
        close(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const send = () => {
        setLoading(true)
        if (state.id) {
            VisitorService.Update(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ویرایش شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
        else {
            VisitorService.Create(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ذخیره شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    const get = () => {
        setLoading(true)
        VisitorService.Get(state.id)
            .then(response => {
                setLoading(false)
                setInputsData(response.data.result);
            },
                error => {
                    setLoading(false)
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }

    useEffect(() => {
        if (state.id && state.state) {
            get()
        }
        return () => {
            setInputsData({});
            return "";
        }
    }, [state.id]);

    return (
        <FormDynamic
            formTitle={"اضافه کردن ملاقات کننده"}
            inputs={inputs}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default Visitor


