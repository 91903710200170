import React, { useEffect, useState } from 'react'
import { getLocalizationTitle } from '../../localization/localization';
import { educationData } from './educationData';
import './education.css'
import { organizationId } from '../../../services/organizationConfig';
import { useSelector } from 'react-redux'

const Education = (props) => {
    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    
    const [showEducation, setShowEducation] = useState(false)

    const setId = () => {
        return `homeEducation`;
    };

    const startItem = () => {
        return document.getElementById(setId())?.offsetTop;
    };


    useEffect(() => {
        if (scrollY < (startItem() - (innerSize.height / 2))) {
            setShowEducation(false);
        }
        else {
            setShowEducation(true);
        }
        return () => {
            return "";
        }
    }, [scrollY]);

    return (
        <section id={setId()} className="homeEducation homeSection homeSectionColor"
        style={{
            
            //backgroundImage: `url(organization${organizationId()}/${props.item.id}${props.item.fileFormat})`,
            //background: linear-gradient('var(--backColorShadow)', 'var(--backColorShadow))', `url(organization${organizationId()}/education.jpg)`)
            backgroundImage: `url(organization${organizationId()}/education.jpg)`
            
            //background: `linear-gradient(var(--backColorShadow), var(--backColorShadow)), url(organization${organizationId()}/education.jpg))`
        }}
        >
            <div>
                {/*<header>
                    <h2 className="homeSectionHeader">
                        {getLocalizationTitle("Education")}
                    </h2>
                </header>*/}
                <article className={`homeEducationContainer ${showEducation ? "homeEducationContainerTransform" : ""}`}>
                    {educationData.map((item, index) => (
                        <div>
                            <h2 >
                                {getLocalizationTitle("Education")}
                            </h2>
                            <h4><span>{item.field}</span></h4>
                            <h5>at <span>{item.school}</span></h5>
                            <h5><span>{`${item.address} - ${item.endDate}`}</span></h5>
                            {/*<h5><span>CGPA = </span>{item.degree}</h5>*/}
                            <h5><span>Focus Areas: </span>{item.description}</h5>
                        </div>
                    ))}
                </article>
            </div>
        </section>
    )
}
export default Education
