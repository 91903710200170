import entityTemplateDto from '../dashbourd/entityTemplateDto'

export const RegisterTemplate: entityTemplateDto[] = [
    {
        title: "name",
        type: "text",
        label: "نام",
        labelEn: "Name",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "surname",
        type: "text",
        label: "نام خانوادگی",
        labelEn: "Surname",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "emailAddress",
        type: "text",
        label: "ایمیل",
        labelEn: "Email",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Email" }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "userName",
        type: "text",
        label: "نام کاربری",
        labelEn: "User Name",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 20 }, { type: "MinLength", length: 8 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "password",
        type: "password",
        label: "کلمه عبور",
        labelEn: "Password",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 8 }, { type: "MinLength", length: 8 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "confirmPassword",
        type: "password",
        label: "تکرار کلمه عبور",
        labelEn: "Confirm Password",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 8 }, { type: "MinLength", length: 8 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "organizationType",
        type: "text",
        label: "نوع دفتر",
        labelEn: "Organization Type",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "organizationTitle",
        type: "text",
        label: "نام دفتر",
        labelEn: "Office Title",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    /*  {
          title: "organizationEnTitle",
          type: "text",
          label: "نام انگلیسی دفتر",
          labelEn: "Office English Title",
          filter: {},
          showList: 0,
          minWidth: "130px",
          validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
          showGrid: true,
          showForm: true
      },*/
    {
        title: "organizationCellPhoneNumber",
        type: "text",
        label: "شماره همراه",
        labelEn: "Organization CellPhoneNumber",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 }, { type: "Required" }, { type: "JustNumber" }],
        showGrid: true,
        showForm: true
    },
    /*{
        title: "organizationWorkPhoneNumber",
        type: "text",
        label: "شماره تماس",
        labelEn: "Organization WorkPhoneNumber",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 }, { type: "Required" },{type:"JustNumber"}],
        showGrid: true,
        showForm: true
    },
    {
        title: "organizationAddress",
        type: "text",
        label: "آدرس",
        labelEn: "Organization Address",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },*/

];