import React, { useEffect, useState } from 'react'
import { Switch } from "react-router-dom";
import GridContainer from '../../grid/gridContainer.component'
import CustomerService from '../../../services/customer.service'
import { CustomerTemplate } from './customerTemplate.tsx'
import Customer from './customer'
import { useSnackbar } from 'notistack';
import VisitTimes from '../visitTime/visitTimes'
import VisitTime from '../visitTime/visitTime'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ReorderIcon from '@material-ui/icons/Reorder';
import Button from '../../button/button';

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PhotoIcon from '@material-ui/icons/Photo';

import CallIcon from '@material-ui/icons/Call';
import SmsIcon from '@material-ui/icons/Sms';
import CustomerImage from './customerImage/customerImage';
import CustomerImages from './customerImage/customerImages';
import { getLocalizationTitle } from '../../localization/localization';

const Customers = (props) => {
    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({ text: "", isActive: true });
    const { enqueueSnackbar } = useSnackbar();


    const [state, setState] = useState({ state: false, id: 0 });
    const [refresh, setRefresh] = useState(0);
    function open(id) {
        setState({ state: true, id: id })
    }
    function close(id) {
        setState({ state: false, id: 0 });

        if (id) {
            setRefresh(refresh + 1)
        }
    }

    function handleDelete(id) {
        //console.log(id);

        CustomerService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }



    const [stateVisitTimes, setStateVisitTimes] = useState({ state: false, customerId: 0 });
    function openVisitTimes(customerId) {
        setStateVisitTimes({ state: true, customerId: customerId });
    }
    function closeVisitTimes() {
        setStateVisitTimes({ state: false, customerId: 0 });
    }

    const [stateVisitTime, setStateVisitTime] = useState({ state: false, id: 0 });
    function openVisitTime(customerId) {
        setStateVisitTime({ state: true, id: 0, customerId: customerId })
    }
    function closeVisitTime() {
        setStateVisitTime({ state: false, id: 0, customerId: 0 });
    }

    const [stateCustomerImages, setStateCustomerImages] = useState({ state: false, customerId: 0 });
    function openCustomerImages(customerId) {
        setStateCustomerImages({ state: true, customerId: customerId })
    }
    function closeCustomerImages() {
        setStateCustomerImages({ state: false, customerId: 0 });
    }

    const [stateCustomerImage, setStateCustomerImage] = useState({ state: false, id: 0 });
    function openCustomerImage(customerId) {
        setStateCustomerImage({ state: true, id: 0, customerId: customerId })
    }
    function closeCustomerImage() {
        setStateCustomerImage({ state: false, id: 0, customerId: 0 });
    }

    const getAll = (skipCount, maxResultCount) => {
        setTotal(-1);
        CustomerService.GetAll(skipCount, maxResultCount, search.text, search.isActive).then(
            response => {
                setContent(response.data.result.items.map((item, index) => ({
                    id: item.id,
                    specialty: item.specialty,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    fatherName: item.fatherName,
                    birthDate: item.birthDate,
                    cellPhoneNumber: item.cellPhoneNumber,
                    code: item.code,
                    cardNumber: item.cardNumber,
                    colorIonicId: item.colorIonicId,
                    visitTime: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<ReorderIcon />} click={() => openVisitTimes(item.id)} />
                            <Button icon={<PlaylistAddIcon />} click={() => openVisitTime(item.id)} />
                        </div>
                    ),
                    image: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<PhotoLibraryIcon />} click={() => openCustomerImages(item.id)} />
                            <Button icon={<PhotoIcon />} click={() => openCustomerImage(item.id)} />
                        </div>
                    ),
                    contact: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<CallIcon />} click={() => copyCellPhoneNumber(item.cellPhoneNumber)} />
                            <Button icon={<SmsIcon />} click={() => chooseMessage(item.id)} />
                        </div>
                    )
                })));
                setTotal(response.data.result.totalCount);
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        getAll(skipCount, maxResultCount)
    };

    const chooseMessage = (customerId) => {
        //console.log('customerId', customerId)
    };

    const copyCellPhoneNumber = (cellPhoneNumber) => {
        navigator.clipboard.writeText(cellPhoneNumber)
        enqueueSnackbar(`شماره تماس ${cellPhoneNumber} کپی شد.`, { variant: 'success' })
    };

    useEffect(() => {
        getAll(0, 10)
        return () => {
            return "";
        }
    }, [search, refresh]);

    return (
        <div>
            <Switch>
                <div>
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={CustomerTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        open={open}
                        delete={handleDelete}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Customers")}`}
                    />
                    <Customer
                        state={state}
                        close={close}
                    />
                    <VisitTimes
                        state={stateVisitTimes}
                        close={closeVisitTimes}
                    />
                    <VisitTime
                        stateVisitTime={stateVisitTime}
                        closeVisitTime={closeVisitTime}
                    />
                    <CustomerImage
                        state={stateCustomerImage}
                        close={closeCustomerImage}
                    />
                    <CustomerImages
                        state={stateCustomerImages}
                        close={closeCustomerImages}
                    />
                </div>
            </Switch>
        </div>
    );
}

export default Customers
