import React, { useEffect, useState } from 'react'
import { GetDirection } from '../../localization/localization'
import PlatformMaj from '../../platformMaj/platformMaj'
import { HomeGhajari3TemplatePlatformMaj } from './homeGhajari3TemplatePlatformMaj'
import './homeGhajari3.css'
import ContactUs from './../contactUs'
import Education from './education'
import Experience from './experience'
import PlatformMajAnimationLogo from '../../platformMaj/platformMajAnimationLogo'
import { homeGhajari3AboutTemplate } from './homeGhajari3AboutTemplate'
import { homeGhajari3InterestsTemplate } from './homeGhajari3InterestsTemplate'
import Footer from '../../footer/footer'
import OtherProjects from './otherProjects'
import PlatformMajPictureZoom from '../../platformMaj/platformMajPictureZoom'
import PlatformMajSheetPicture from '../../platformMaj/platformMajSheetPicture'
import PlatformMajMovePictures from '../../platformMaj/platformMajMovePictures'
import ChatGPTComponent from './ChatGPTComponent'
import ShakeImageWithScroll from '../../../shakeImageWithScroll/shakeImageWithScroll'
import { shakeTemplate } from './shakeTemplate'
import { useDispatch } from 'react-redux'
import { setWindowScrollY } from '../../../stateManagement/windowScrollYSlice'
import { homeGhajari3TopTemplate } from './homeGhajari3TopTemplate'
import ShowEducationWithScroll from '../../../showExperienceWithScroll/showExperienceWithScroll'
import { experienceData, languageData, homeGhajari3PrivateProjectsTemplate } from './experienceData';
import { setWindowInnerSize } from '../../../stateManagement/windowInnerSizeSlice'
import { useSelector } from 'react-redux'

const HomeGhajari3 = (props) => {
    const innerSize = useSelector((state) => state.windowInnerSize.value)
    const dispatch = useDispatch()

    window.addEventListener('scroll', () => {
        dispatch(setWindowScrollY(window.scrollY))
    });

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (innerSize.height != window.innerHeight || innerSize.width != window.innerWidth) {
                dispatch(setWindowInnerSize({ height: window.innerHeight, width: window.innerWidth }))
            }
        });
        return () => {
            return "";
        }

    }, [window.innerHeight, window.innerWidth]);


    return (
        <div className="homeClass homeGajari3" dir={GetDirection()}>
            {/* <ChatGPTComponent /> */}
            
           <ShakeImageWithScroll item={shakeTemplate} />
           <PlatformMajAnimationLogo
                item={homeGhajari3TopTemplate}/>
            <PlatformMajPictureZoom
                item={homeGhajari3AboutTemplate}/>
            {/* <Experience/> */}
             <ShowEducationWithScroll
                item={homeGhajari3PrivateProjectsTemplate}
                experiences={experienceData}
                programmingLanguages={languageData}
            /> 
            {/* <OtherProjects
            /> */}
            <Education/>
            
            <PlatformMajMovePictures
                item={homeGhajari3InterestsTemplate}
            />
            {/*<PlatformMajSheetPicture
                item={homeGhajari3InterestsTemplateOld}
            />*/}

            <ContactUs/>
            <Footer />
            {/*<PlatformMaj teplates={HomeGhajari3TemplatePlatformMaj} />*/}

        </div>
    )
}
export default HomeGhajari3
