import entityTemplateDto from '../dashbourd/entityTemplateDto'

export const ContactUsTemplate: entityTemplateDto[] = [
    {
        title: "name",
        type: "text",
        label: "نام",
        labelEn: "Name",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "MinLength", length: 3 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "email",
        type: "text",
        label: "ایمیل",
        labelEn: "Email",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Email" }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "cellPhone",
        type: "text",
        label: "شماره تماس",
        labelEn: "CellPhone",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"}, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "message",
        type: "text",
        label: "پیغام",
        labelEn: "Message",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 500 }, { type: "MinLength", length: 10 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    }
];