import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { dateFilter } from '../gridData/dateFilter'
import { bitwiseOperators } from '../gridData/bitwiseOperators'
import Button from '@material-ui/core/Button';
import './gridfFilter.css'
import RefreshIcon from '@material-ui/icons/Refresh';

//import moment from "moment";
import jMoment from "moment-jalaali";
import JalaliUtils from "@date-io/jalaali";

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

const GridfFilterDate = (props) => {

  const [filterValue, setInputs] = useState(props.filterValue);
  const { onClose, open, couumnLabel } = props;
  const [value1Date, setValue1Date] = useState(null);
  const [value2Date, setValue2Date] = useState(null);

  const setOperator1 = (value) => {
    if (value) {
      setInputs(state => ({ ...state, value1: value.toDate() }))
    }
    else {
      setInputs(state => ({ ...state, value1: "" }))
    }
    setValue1Date(value)
  };

  const setOperator2 = (value) => {
    if (value) {
      setInputs(state => ({ ...state, value2: value.toDate() }))
    }
    else {
      setInputs(state => ({ ...state, value2: "" }))
    }
    setValue2Date(value)
  };

  const handleClose = () => {
    onClose(filterValue);
  };

  const clear = () => {
    setInputs(state => ({ state, value1: "" }))
    setInputs(state => ({ state, value2: "" }))
    onClose({
      value1: "",
      operator1: "eq",
      value2: "",
      operator2: "eq",
      logic: 'and'
    });
  };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={open}>
      <DialogTitle>
        <div className="filterTitle">
          نشان دادن&nbsp;<span style={{ fontWeight: "bold" }}>{couumnLabel}</span>&nbsp;در شرایطی که:
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="container filterContainer">
          <div className="row">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.operator1} onChange={({ target }) => setInputs(state => ({ ...state, operator1: target.value }))}>
                {dateFilter.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-sm-6">
              {/*<TextField autoFocus fullWidth size="small" type="text"
                value={filterValue.value1} onChange={({ target }) => setInputs(state => ({ ...state, value1: target.value }))}>
                </TextField>*/}
              <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
                <DatePicker
                  clearable
                  okLabel="تأیید"
                  cancelLabel="لغو"
                  clearLabel="پاک کردن"
                  labelFunc={date => (date ? date.format("jYYYY/jMM/jDD") : "")}
                  value={value1Date}
                  onChange={setOperator1}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.logic} onChange={({ target }) => setInputs(state => ({ ...state, logic: target.value }))}>
                {bitwiseOperators.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField fullWidth size="small" select
                value={filterValue.operator2} onChange={({ target }) => setInputs(state => ({ ...state, operator2: target.value }))}>
                {dateFilter.map(item => (
                  <MenuItem key={item.value} value={item.value}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="col-sm-6">
              {/*<TextField fullWidth size="small" type="text"
                value={filterValue.value2} onChange={({ target }) => setInputs(state => ({ ...state, value2: target.value }))}>
                </TextField>*/}
              <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
                <DatePicker
                  clearable
                  okLabel="تأیید"
                  cancelLabel="لغو"
                  clearLabel="پاک کردن"
                  labelFunc={date => (date ? date.format("jYYYY/jMM/jDD") : "")}
                  value={value2Date}
                  onChange={setOperator2}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <Button fullWidth variant="contained" size="small" onClick={() => handleClose()}>
                اعمال
            </Button>
            </div>
            <div className="col-sm-3">
              <Button fullWidth variant="contained" size="small" onClick={() => clear()}>
                <RefreshIcon />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default GridfFilterDate
