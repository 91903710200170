import React, { useEffect ,useState} from 'react'
import { useHistory ,useParams} from "react-router-dom";
import RouteWithSubRoutes from '../../routing/RouteWithSubRoutes'
import { Switch } from "react-router-dom";
import HeaderSideBar from './../header/headerSideBar'
import { isExpired } from '../../services/auth-header';
import { useSnackbar } from 'notistack';
import AuthService from "../../services/auth.service";
import { SetLocalization, GetDirection } from '../localization/localization'

const Dashboard = (props) => {
    const [currentUser, setCurrentUser] = useState(undefined);
    const { enqueueSnackbar } = useSnackbar();
    
    let history = useHistory();

    function checkAuthentication() {
        if (isExpired()) {
            localStorage.removeItem("user");
            history.push('/login');
            enqueueSnackbar("User Expired", { variant: 'error' })
            return false;
        }
        else {
            return true;
        }
    }
    useEffect(() => {
        if (checkAuthentication()) {
            const user = AuthService.getCurrentUser();
            if (user) {
                setCurrentUser(user)
            }
        }
        /*window.addEventListener('scroll', () => {
            //console.log(window.scrollY)
            if (window.scrollY > 70) {
                handleShow(true);
            } else handleShow(false);
        });*/
        return () => {
            return "";
        }
    }, []);

    return (
        <div className="myDashboard" dir={GetDirection()} >
            <HeaderSideBar isDashboard={true} justSideBar={true}/>
            <div style={{height: "50px"}}></div>
            <Switch>
                {props.routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
            </Switch>
        </div>
    )
}

export default Dashboard
