import shakeImageWithScrollDto from "../../../shakeImageWithScroll/shakeImageWithScrollDto";


export const shakeTemplate: shakeImageWithScrollDto =
{
    id: "organization3/shakeImage",
    length: 51,
    distance: 50,
    fileFormat: '.jpg',
    header:"Majid Ghajari",
    description:"Senior web developer"
};