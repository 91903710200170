import entityTemplateDto from '../entityTemplateDto'

export const VisitTimeTemplate: entityTemplateDto[] = [
    {
        title: "date_Time",
        type: "date",
        label: "تاریخ",
        filter: {},
        showList: 1,
        minWidth: "60px",
        validations: [{ type: "Required" }],
        showGrid: true,
        showForm: true
    },
    /*{
        title: "visits",
        type: "multiSelect",
        label: "وظیفه",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "Required" }],
        showGrid: true,
        showForm: true
    },*/
    {
        title: "axpenseAmount",
        type: "currency",
        label: "صورتحساب",
        showList: 1,
        filter: {},
        minWidth: "70px",
        showGrid: true
    },
    {
        title: "discountAmount",
        type: "currency",
        label: "تخفیف",
        showList: 1,
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 10 }],
        showGrid: false,
        showForm: true
    },
    {
        title: "totalAmount",
        type: "currency",
        label: "مبلغ پرداختی",
        showList: 1,
        filter: {},
        minWidth: "70px",
        showGrid: true
    },
    {
        title: "visitor",
        type: "text",
        showList: 1,
        label: "ملاقات کننده",
        filter: {},
        minWidth: "100px",
        showGrid: true
    },
    {
        title: "description",
        type: "text",
        label: "توضیحات",
        filter: {},
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showForm: true
    },
    /*{
        title: "payment_totalAmount",
        type: "text",
        label: "مبلغ پرداختی",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },*/
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true
    }
];