import React from 'react';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from './../button/button';
import { useSnackbar } from 'notistack';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';

const GridColumn = (props) => {
     const { enqueueSnackbar } = useSnackbar();

     const copyData = (data) => {
          navigator.clipboard.writeText(data)
          enqueueSnackbar(` ${data} کپی شد.`, { variant: 'success' })
     };

     switch (props.type) {
          case "date":
               return (
                    <td>
                         {(new Date(props.coulmn).toLocaleDateString('fa-IR'))}
                    </td>
               );
          case "image":
               return (
                    <td>
                         {props.coulmn ? (
                              <img src={`data:image/jpeg;base64,${props.coulmn}`} style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                         ) : (
                              ""
                         )}
                    </td>
               );
         /* case "images":
               return (
                    <td>
                         {props.coulmn?.length > 0 ? 
                              props.coulmn?.map((imageItem, imageIndex) => 
                                   console.log(imageItem)
                              (<img src={`data:image/jpeg;base64,${imageItem.imageFileData}`} style={{ width: "100px", height: "100px", objectFit: "cover" }} />)
                         ) : (
                              null
                         )}
                    </td>
               );*/
          case "currency":
               return (
                    <td>
                         {(parseInt(props.coulmn).toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 0 }))}
                    </td>
               );
          case "enum":
               return (
                    <td>
                         {props.enums.filter(x => x.value == props.coulmn)[0]?.text}
                    </td>
               );
          case "enumColor":
               return (
                    <td >
                         <div style={{ width: "25px", height: "25px", backgroundColor: (props.enums.filter(x => x.value == props.coulmn)[0]?.text), borderRadius: "50px" }}></div>
                    </td>
               );
          case "select":
               return (
                    <td>
                         {props.listSelect.filter(x => x.value == props.coulmn)[0]?.text}
                    </td>
               );
          case "editColumn":
               return (
                    <td>
                         <div>
                              {!props.noEdit ? (
                                   <Button
                                        //label={"ویرایش"} 
                                        icon={<EditOutlined />}
                                        click={() => props.open()} />) : (null)}
                              {!props.noDelete ? (
                                   <Button
                                        //label={"حذف"} 
                                        icon={props.isActive ? <DeleteOutlineIcon /> : <RestoreFromTrashIcon />}
                                        click={() => props.delete()} />) : (null)}
                         </div>
                    </td>
               );
          case "editColumnCustom":
               return (
                    <td>
                         {props.coulmn}
                    </td>
               );
          default:
               return (
                    <td
                         className="GridColumnText"
                         onClick={() => copyData(props.coulmn)}
                    >
                         {props.coulmn}
                    </td>
               );
     }
}
export default GridColumn;