import localizationDto from './localizationDto'

export const localizationData: localizationDto[] = [
  { title: "Home", en: "Home", fa: "خانه", },
  { title: "Visitors", en: "Experts", fa: "متخصصان", },
  { title: "Images", en: "Images", fa: "تصاویر", },
  { title: "AboutUs", en: "About us", fa: "درباره ما", },
  { title: "AboutMe", en: "About Me", fa: "درباره من", },
  { title: "ContactUs", en: "Contact us", fa: "تماس با ما", },
  { title: "ContactMe", en: "Contact Me", fa: "تماس با من", },
  { title: "DailyDashbourd", en: "Daily dashbourd", fa: "داشبورد روزانه", },
  { title: "Customers", en: "Customers", fa: "مراجعه کنندگان", },
  { title: "LogOut", en: "Log Out", fa: "خروج", },
  { title: "LogIn", en: "Log In", fa: "ورود", },
  { title: "Dashboard", en: "Dashboard", fa: "داشبورد", },
  { title: "UserSettings", en: "User settings", fa: "تنظیمات", },
  { title: "Send", en: "Send", fa: "ارسال", },
  { title: "More", en: "More", fa: "بیشتر", },
  { title: "Register", en: "Register", fa: "ثبت نام", },
  { title: "Username", en: "Username", fa: "نام کاربری", },
  { title: "Password", en: "Password", fa: "کلمه عبور", },
  { title: "Name", en: "Name", fa: "نام", },
  { title: "Email", en: "Email", fa: "ایمیل", },
  { title: "CellPhone", en: "Cell Phone", fa: "شماره تماس", },
  { title: "Message", en: "Message", fa: "پیغام", },
  { title: "SepandDentalAddress", en: "17/1 Cheraghi Street, Niavran", fa: "نیاوران، نرسیده به سه راه یاسر، کوچه چراغی پ 17/1", },
  { title: "MehrnazAddress", en: "Pasdaran, Tehran, Iran", fa: "تهران پاسداران بین بوستان نهم و دهم ", },
  {
    title: "SepandDentalAboutUs",
    en: "Our goal is the health of your mouth and teeth",
    fa: "هدف ما سلامت دهان و دندان شما است",
  },
  {
    title: "MehrnazAboutUs",
    en: "",
    fa: "هدف من زیبایی و سلامت ناخن های شماست",
  },
  {
    title: "OntimeAboutUs",
    en: "With Ontime, User easily, friendly manage or reserve appointments.",
    fa: "تمرکز سیستم Ontime به روی ثبت ملاقات و مدیریت هر چه راحت تر توسط کاربر باشد",
  },
  {
    title: "OntimeOrder",
    en: "Contact us to order a professional version or more features or features individually.",
    fa: "برای سفارش ورژن حرفه ای و یا امکانات بیشتر و یا امکانات به صورت تکی با ما تماس حاصل فرمایید.",
  },
  { title: "ReserveAppointmentSystem", en: "Reserve Appointment System", fa: "سامانه ثبت ملاقات", },
  { title: "WhyUs", en: "Why us?", fa: "چرا ما؟", },
  { title: "WhyMe", en: "Why us?", fa: "چرا من", },
  { title: "All", en: "All", fa: "همه", },
  { title: "Search", en: "Search", fa: "جستجو", },
  { title: "Add", en: "Add", fa: "ایجاد", },
  { title: "Yes", en: "Yes", fa: "بله", },
  { title: "No", en: "No", fa: "خیر", },
  { title: "Delete", en: "Delete", fa: "حذف", },
  { title: "AreYouDelete", en: "Are you sure delete?", fa: "مطمئنی حذف شود؟", },
  { title: "Active", en: "Active", fa: "فعال", },
  { title: "Inactive", en: "Inactive", fa: "غیرفعال", },
  { title: "Total", en: "Total", fa: "کل داده", },
  { title: "Meeting", en: "Meeting", fa: "ملاقات", },
  { title: "Features", en: "Features", fa: "امکانات", },
  { title: "Feature", en: "Feature", fa: "ویژگی", },
  { title: "BasicVersion", en: "Basic version", fa: "ورژن پایه", },
  { title: "ProVersion", en: "Pro version", fa: "ورژن حرفه ای", },
  { title: "ComingSoon", en: "Coming soon", fa: "به زودی", },
  { title: "Users", en: "Users", fa: "کاربران", },
  { title: "Roles", en: "Roles", fa: "نقش ها", },
  { title: "AcceptRules", en: "I accept the rules", fa: "قوانین را می پذیرم", },
  { title: "OntimeRegisterRules", en: "Rules: Registration and use of the basic version is free, and please contact us to use the professional version.", fa: "قوانین: ثبت نام و استفاده از ورژن پایه رایگان است، و برای استفاده از ورژن حرفه ای با ما تماس حاصل فرمایید", },
  { title: "OntimeRegisterHelp", en: "To register at www.0ntime.ir, complete the information and then click on register.", fa: "جهت ثبت نام در www.0ntime.ir اطلاعات زیر را تکمیل نمایید و سپس به روی ثبت نام کلیک نمایید.", },
  { title: "Education", en: "Education", fa: "تحصیلات", },
  { title: "Blogs", en: "Blogs", fa: "نوشته ها", },
  /*{ title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },
  { title: "", en: "", fa: "", },*/
];