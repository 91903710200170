import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GridfFilterText from './gridfFilterText.component'
import GridfFilterDate from './gridfFilterDate.component'
import GridfFilterEnum from './gridfFilterEnum.component'
import { getLocalizationTitleForInput } from '../localization/localization';
const GridHead = (props) => {
   const [open, setOpen] = React.useState(false);
   const [filterValue, setFilterValue] =
      React.useState(props.couumn.type == "date" ?
         {
            value1: "",
            operator1: "gte",
            value2: "",
            operator2: "lte",
            logic: 'and'
         }
         : props.couumn.type == "text" ?
            {
               value1: "",
               operator1: "contains",
               value2: "",
               operator2: "contains",
               logic: 'and'
            }
            :
            {
               value1: "",
               operator1: "eq",
               value2: "",
               operator2: "eq",
               logic: 'and'
            }
      );

   const handleClickOpen = () => {
      setOpen(true);
   };

   const onClose = (value) => {
      setOpen(false);
      setFilterValue(value);
      let filter = {};
      if (value.value1 && value.value2) {
         filter = {
            filters: [
               { field: props.couumn.title, operator: value.operator1, value: value.value1 },
               { field: props.couumn.title, operator: value.operator2, value: value.value2 }
            ],
            logic: value.logic
         }
      }
      else if (value.value1) {
         filter = {
            filters: [
               { field: props.couumn.title, operator: value.operator1, value: value.value1 }
            ],
            logic: value.logic
         }
      }
      else if (value.value2) {
         filter = {
            filters: [
               { field: props.couumn.title, operator: value.operator2, value: value.value2 }
            ],
            logic: value.logic
         }
      }
      else {
         filter = {}
      }

      props.dataFromHead(props.couumn.title, filter)
   };

   return (
      <th scope="col" style={{ minWidth: (props.couumn.minWidth ? props.couumn.minWidth : "100px") }}>
         {getLocalizationTitleForInput(props.couumn)}
         {/*
            props.couumn.type == "template" ?
               (<div></div>)
               :
               filterValue.value1 || filterValue.value2 ?
                  (<ClearAllIcon onClick={handleClickOpen} style={{ cursor: "pointer" }} />)
                  :
                  (<FilterListIcon onClick={handleClickOpen} style={{ cursor: "pointer" }} />)
         */}
         {props.couumn.type == "date" ?
            (<GridfFilterDate filterValue={filterValue} open={open} onClose={onClose} couumnLabel={props.couumn.label} />)
            :
            props.couumn.type == "enum" ?
               (<GridfFilterEnum filterValue={filterValue} open={open} onClose={onClose} couumnLabel={props.couumn.label} enums={props.couumn.enums} />)
               :
               props.couumn.type == "template" ?
                  (<div></div>)
                  :
                  (<GridfFilterText filterValue={filterValue} open={open} onClose={onClose} couumnLabel={props.couumn.label} />)}
      </th>
   );
}
export default GridHead;

