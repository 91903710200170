import { useEffect, useState } from 'react'
import './shakeImageWithScroll.css'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

const ShakeImageWithScroll = (props) => {
    const [imgsLoaded, setImgsLoaded] = useState(false)

    const scrollY = useSelector((state) => (state.windowScrollY.value ?? 0))

    const innerSize = useSelector((state) => state.windowInnerSize.value)

    const [imageIndex, setImageIndex] = useState(0)

    const [itemPosition, setItemPosition] = useState("relative")
    const [containerJustifyContent, setContainerJustifyContent] = useState("flex-start")

    const [height] = useState(((props.item.length * props.item.distance) - props.item.distance))

    const setId = () => {
        return `imageItem${props.item.id}`;
    };

    const startItem = () => {
        //console.log(document.getElementById(setId())?.offsetTop)
        return (document.getElementById(setId())?.offsetTop ?? 0);
    };

    const EndItem = () => {
        return (startItem() + height ?? 0);
    };

    const itemScrollHeight = () => {
        return height + innerSize.height;
    };

    const lastImage = () => {
        return (Math.floor(props.item.length - 1) ?? 0);
    };

    const setImage = () => {
        //console.log('setImage',(Math.floor((scrollY - startItem()) / props.item.distance)??0))
        let r = Math.floor(((scrollY - startItem()) / props.item.distance) ?? 0);
        return (r < 0 ? 0 : r);
    };

    const images = () => {
        let menuItems = [];

        for (var i = 0; i < props.item.length; i++) {
            menuItems.push(
                <img style={{
                    display: imageIndex != i ? "none" : "inline-block",
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                }}
                    src={process.env.PUBLIC_URL + `${props.item.id}/${i}${props.item.fileFormat}`}>
                </img>
            )
        }

        return <div>{menuItems}</div>;
    }

    const loadImage = image => {
        return new Promise((resolve, reject) => {
            const loadImg = new Image()
            loadImg.src = image.url
            // wait 2 seconds to simulate loading time
            loadImg.onload = () =>
                setTimeout(() => {
                    resolve(image.url)
                }, 2000)

            loadImg.onerror = err => reject(err)
        })
    }

    useEffect(() => {
        //console.log('ShakeImageWithScroll',innerSize)
        if (!imgsLoaded) {
            let IMAGES = [];
            for (var i = 0; i < props.item.length; i++) {
                IMAGES.push({ url: process.env.PUBLIC_URL + `${props.item.id}/${i}${props.item.fileFormat}` })
            }
            //console.log(IMAGES)
            Promise.all(IMAGES.map(image => loadImage(image)))
                .then(() => setImgsLoaded(true))
                .catch(err => console.log("Failed to load images", err))
        }
        if (scrollY < startItem()) {
            setImageIndex(0)
            setItemPosition("relative")
            setContainerJustifyContent("flex-start")
        }
        else if (scrollY > EndItem()) {
            setImageIndex(lastImage())
            setItemPosition("relative")
            setContainerJustifyContent("flex-end")
        }
        else {
            setImageIndex(setImage())
            setItemPosition("fixed")
            setContainerJustifyContent("flex-start")
        }
        return () => {
            return "";
        }
    }, [scrollY, innerSize]);

    return (
        <div className='ShakeImageWithScrollContainer sectionColor'
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: (containerJustifyContent ?? "flex-start"),
                height: itemScrollHeight(),
                minHeight: itemScrollHeight(),
                backgroundColor: props.item.backColor
            }}
            id={setId()}>
            <div className='ShakeImageWithScrollHeader'
                style={{
                    position: containerJustifyContent == "flex-end" ? "relative" : 'absolute',
                    zIndex: 2,
                    marginLeft: '50px',
                    marginTop: '50px',
                }}>
                <h1>{props.item.header}{!imgsLoaded ? (<CircularProgress />) : (null)}</h1>
                <h2>{props.item.description}</h2>
            </div>
            <div className="ShakeImageWithScrollItem"
                style={{
                    top: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    position: (itemPosition ?? "fixed"),
                    zIndex: 1,
                }}>
                {imgsLoaded ? (images()) : (null)}
            </div>
        </div>
    )
}

export default ShakeImageWithScroll