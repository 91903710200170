import React, { useEffect, useState } from 'react'
import UserService from '../../../services/user.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../../formDynamic/formDynamic'
import { UserTemplate } from './userTemplate'

const User = (props) => {
    const { close, state } = props;
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState(UserTemplate);
    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({ MinSize: 200, MaxSize: 300 ,relationType:1});

    const handleClose = (id) => {
        close(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const send = () => {
        setLoading(true)
        if (state.id) {
            UserService.Update(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ویرایش شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
        else {
            UserService.Create(inputsData).then(
                (response) => {
                    setLoading(false)
                    if (response.success) {
                        enqueueSnackbar('ذخیره شد', { variant: 'success' })
                        close(response.response.id)
                    }
                    else {
                        enqueueSnackbar(response.response, { variant: 'error' })
                    }
                }
            );
        }
    }

    const get = () => {
        
        //console.log('get',state.id)
        setLoading(true)
        UserService.Get(state.id)
            .then(response => {
                //console.log(response.data.result)
                setLoading(false)
                setInputsData(response.data.result);
            },
                error => {
                    setLoading(false)
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }
    const GetRoles = () => {
        UserService.GetRoles().then(
            response => {
                /*console.log(response.data.result.items.map((item, index) => (
                    { value: item.name, text: item.displayName }
                )))*/
                let inputsCopy = [...inputs];
                inputsCopy.filter(v => v.title === "roleNames")[0].listSelect = response.data.result.items.map((item, index) => (
                    { value: item.normalizedName, text: item.normalizedName }
                ));
                setInputs(inputsCopy)

            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const GetOrganizations = () => {
        UserService.GetOrganizations().then(
            response => {
                /*console.log(response.data.result.items.map((item, index) => (
                    { value: item.name, text: item.displayName }
                )))*/
                let inputsCopy = [...inputs];
                inputsCopy.filter(v => v.title === "organizationId")[0].listSelect = response.data.result.items.map((item, index) => (
                    { value: item.id, text: item.title }
                ));
                setInputs(inputsCopy)

            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    useEffect(() => {
        //console.log('User useEffect',state)
        if (state.id && state.state) {
            get()
        }
        if (state.state) {
            GetRoles()
            GetOrganizations()
        }
        return () => {
            setInputsData({relationType:1});
            return "";
        }
    }, [state]);

    return (
        <FormDynamic
            formTitle={"اضافه کردن کاربر"}
            inputs={inputs}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default User


