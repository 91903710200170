import React, { useEffect, useState } from 'react'
import { Switch } from "react-router-dom";
import GridContainer from '../../grid/gridContainer.component'
import UserService from '../../../services/user.service'
import { UserTemplate } from './userTemplate'
import { useSnackbar } from 'notistack';
import User from './user'
import { getLocalizationTitle } from '../../localization/localization';

const Users = (props) => {
    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({text:"",isActive:true});
    const { enqueueSnackbar } = useSnackbar();


    const [state, setState] = useState({ state: false, id: 0 });

    function open(id) {
        setState({ state: true, id: id })
    }
    function handleDelete(id) {
        //console.log(id);

        UserService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const [refresh, setRefresh] = useState(0);
    function close(id) {
        setState({ state: false, id: 0 });

        if (id) {
            setRefresh(refresh + 1)
        }
    }


    const getAll = (skipCount, maxResultCount) => {
        setTotal(-1);
        UserService.GetAll(skipCount, maxResultCount,  search.text, search.isActive).then(
            response => {
                //console.log('UserService.GetAll',response.data.result.items)
                setContent(response.data.result.items.map((item, index) => ({
                    id: item.id,
                    creationTime: item.creationTime,
                    emailAddress: item.emailAddress,
                    fullName: item.fullName,
                    isActive: item.isActive,
                    lastLoginTime: item.lastLoginTime,
                    name: item.name,
                    surname: item.surname,
                    userName: item.userName,
                    roleNamesGrid: item.roleNames.map((roleItem) => {return roleItem + ','}),
                    organizationTitle:item.organizationTitle
                })));
                setTotal(response.data.result.totalCount)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        getAll(skipCount, maxResultCount)
    };

    useEffect(() => {
        getAll(0, 10)
        return () => {
            return "";
        }
    }, [search, refresh]);

    return (
        <div>
            <Switch>
                <div>
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={UserTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        open={open}
                        delete={handleDelete}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Users")}`}
                    />
                    <User
                        state={state}
                        close={close}
                    />

                </div>
            </Switch>
        </div>
    );
}

export default Users


