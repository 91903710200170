import React, { useEffect, useState } from 'react'
import UserService from '../../services/user.service'
import { useSnackbar } from 'notistack';
import FormDynamic from '../formDynamic/formDynamic'
import { UserMyselfTemplate } from './userMyselfTemplate';

const UserMyself = (props) => {
    const { close, state } = props;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const [inputsData, setInputsData] = useState({});

    const handleClose = (id) => {
        close(id);
    };

    const handleSetInputsData = (target, property) => {
        setInputsData(state => ({ ...state, [property]: target }))
    };

    const send = () => {
        setLoading(true)
        UserService.UpdateUserMyself(inputsData).then(
            (response) => {
                setLoading(false)
                if (response.success) {
                    enqueueSnackbar('ویرایش شد', { variant: 'success' })
                    close(response.response.id)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const get = () => {
        setLoading(true)
        UserService.GetUserMyself()
            .then(response => {
                setLoading(false)
                setInputsData(response.data.result);
            },
                error => {
                    setLoading(false)
                    enqueueSnackbar('خطا در دریافت، اینترنت خود را بررسی نمایید', { variant: 'error' })
                }
            );
    }

    useEffect(() => {
        if (state.state) {
            get()
        }
        return () => {
            setInputsData({});
            return "";
        }
    }, [state]);

    return (
        <FormDynamic
            formTitle={"ویرایش کاربر"}
            inputs={UserMyselfTemplate}
            inputsData={inputsData}
            send={send}
            state={state}
            handleSetInputsData={handleSetInputsData}
            handleClose={handleClose}
            loading={loading}
        />
    );
}

export default UserMyself


