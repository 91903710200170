import platformMajSheetPictureDto from '../../platformMaj/platformMajSheetPictureDto'
import { getLocalizationTitle } from '../../localization/localization';
import experienceDto, { experienceProjectDto } from './experienceDto'

export const homeGhajari3PrivateProjectsTemplate: platformMajSheetPictureDto =

{
    id: "My experiences",
    distance: 75,
    backColor: 'var(--backColor)',
    itemBackColor: 'var(--backColor)',
    fileFormat: '.jpg',
    fileTitle: 'bishi',
    imageSizeHeight: 300,
    title: getLocalizationTitle("My experiences"),
}


export const experienceData: experienceDto[] = [
    {
        title: "Senior Software Developer",
        companyName: "Charisma",
        webSite: "https://charisma.ir/",
        address: "Tehran, Iran",
        startDate: "February 27, 2021",
        endDate: "December 10, 2022",
        description: "In this company, I worked extensively on both backend and frontend aspects in an agile team environment. I enhanced my expertise in platforms and frameworks such as ReactJS, ASP.NET, etc.",
        startDateS: "Feb 2021",
        endDateS: "Dec 2022",
        projects: [
            {
                title: "Collaboration in Developing ",
                projectName: "Payment Web Application",
                description: "Designed to facilitate group payments for companies. The accountant can initiate group payments for its beneficiaries. Subsequently, these payments are approved within the workflow by the CEO or other designated managers. Upon approval, payments are executed conveniently via e-transfer, leveraging services facilitated through OpenBanking protocols.",
                FrontEnd: ['ReactJS'],
                BackEnd: ['ASPDotNet'],
                DB: ['ElasticSearch', 'SQLDB']
            },
            {
                title: "Collaboration in Developing ",
                projectName: "Insurance Web Application",
                description: "The platform enables customers to select insurance plans, input their information or their beneficiary information, and seamlessly complete the purchase process.",
                FrontEnd: ['ReactJS'],
                BackEnd: ['ASPDotNet'],
                DB: ['ElasticSearch', 'SQLDB']
            },
        ]
    },
    {
        title: "My startup project - Ontime web app",
        type: 'project',
        webSite: "http://www.0ntime.ir/",
        description: "In this project, I played dual roles as the product owner and main developer. I handled both the backend and frontend development of the web app. My wife managed comprehensive reporting and currently oversees project support. Throughout, I utilized cutting-edge technologies such as ReactJS, ASP.NET, and SQL DB.",
        projects: [{
            title:"Implementation and development of ",
            projectName: "Ontime web application",
            description: "Developed and launched a web application tailored for medical offices and clinics, facilitating appointment bookings and customer club management while providing comprehensive reporting for CEOs. As the product owner, I led R&D efforts and oversaw development. This application has a freemium model, offering basic features for free with optional monetized features.",
            FrontEnd: ['ReactJS'],
            BackEnd: ['ASPDotNet'],
            DB: ['SQLDB'],
            releaseDate: "Nov 2019",
            webSite: "http://www.0ntime.ir/",
        }]
    },
    {
        title: "Software Developer",
        companyName: "Refah Bank",
        webSite: "https://refah-td.com/",
        address: "Tehran, Iran",
        startDate: "January 21, 2018",
        endDate: "February 26, 2021",
        description: "In this bank, I got familiar with ReactJS, Angular, VueJS, and Ionic, focusing on ReactJS for single-page applications. I initiated RESTful APIs using DotNet Core and gained knowledge of banking software.",
        startDateS: "Jan 2018",
        endDateS: "Feb 2021",
        projects: [
            {
                title: "Collaboration on the development of the front end of",
                projectName: "Virtual Assistant - Android app",
                description: "This application enables bank customers to conveniently manage their accounts through voice commands. Features included balance inquiries, transfers between accounts, e-transfers, bill payments, etc.",
                FrontEnd: ['Ionic']
            },
            {
                title: "Implementation and development of",
                projectName: "Subsidiaries web application",
                description: "This application streamlines data entry and approval processes for bank subsidiary companies while providing comprehensive reports to the main bank CEO and other managers, including HR, financial, official, audit, purchase, sales, etc.",
                FrontEnd: ['ReactJS'],
                BackEnd: ['ASPDotNetCore'],
                DB: ['ElasticSearch', 'SQLDB']
            },
            {
                title: "Implementation and development of",
                projectName: "ATM web application",
                description: "Refah Bank users are able to manage all Refah Bank ATM data while providing oversight and reporting to the main bank CEO and other managers.",
                FrontEnd: ['ReactJS'],
                BackEnd: ['ASPDotNetCore'],
                DB: ['SQLDB']
            },
            {
                title: "Collaboration on the development of",
                projectName: "Moein web application",
                description: "Facilitated access for high-value bank clients to numerous comprehensive transaction reports.",
                FrontEnd: ['HTML', 'CSS', 'Bootstrap', 'JavaScript', 'JQuery'],
                BackEnd: ['ASPDotNetCore'],
                DB: ['SQLDB']
            }
        ]
    },
    {
        title: "Web Developer",
        companyName: "Narm negaran pishro",
        webSite: "http://narmnegaran.com/",
        address: "Tehran, Iran",
        startDate: "October 23, 2015",
        endDate: "January 20, 2018",
        description: "In this company, I implemented web application based on skills such as the Dot Net Framework, JavaScript, HTML, CSS, jQuery, Bootstrap, and SQL DB. I gained HR knowledge.",
        startDateS: "Oct 2015",
        endDateS: "Jan 2018",
        projects: [
            {
                title: "Collaboration on the development of",
                projectName: "HR web application",
                description: "This application enables large corporations to efficiently manage their human resources while providing comprehensive reporting to CEOs and other managers.",
                FrontEnd: ['HTML', 'CSS', 'Bootstrap', 'JavaScript', 'JQuery'],
                BackEnd: ['ASPDotNetFramework'],
                DB: ['SQLDB']
            }
        ]
    },
    {
        title: "Windows Developer",
        companyName: "Persian Technology",
        webSite: "https://parsiansoftware.com/",
        address: "Tehran, Iran",
        startDate: "September 23, 2014",
        endDate: "October 22, 2015",
        description: "In this company, I enhanced my skills in C# and SQL DB, learned more about communication with physical devices, and gained CRM knowledge.",
        startDateS: "Sep 2014",
        endDateS: "Oct 2015",
        projects: [
            {
                title: "Collaboration on the development of ",
                projectName: "Gym Software",
                description: " This software was implemented to enable gyms to efficiently manage their customers while providing comprehensive reporting to CEOs.",
                BackEnd: ['CWindowsForm'],
                DB: ['SQLDB']
            },
            {
                title: "Collaboration on the development of ",
                projectName: "CRM Software",
                description: "This software enables large corporations to efficiently manage their customer relationship management while providing comprehensive reporting to CEOs.",
                BackEnd: ['CWindowsForm'],
                DB: ['SQLDB']
            }
        ]
    },
    {
        title: "Windows Developer",
        companyName: "Jahan Gostar",
        webSite: "https://www.jahan-gostar.com/",
        address: "Tehran, Iran",
        startDate: "December 22, 2013",
        endDate: "September 9, 2014",
        description: "In this company, I developed programming skills using C# and SQL DB for Windows-based software. I integrated attendance device communications with Windows software platforms.",
        startDateS: "Dec 2013",
        endDateS: "Sep 2014",
        projects: [
            {
                title: "Collaboration on the development of",
                projectName: "Attendance Software",
                description: "This software enables large corporations to efficiently manage their employees attendance while providing comprehensive reporting to CEOs.",
                BackEnd: ['CWindowsForm'],
                DB: ['SQLDB']
            }
        ]
    },
];

export const experienceProjectData: experienceProjectDto[] = [
    {
        title: "",
        projectName: "Ontime System",
        description: "Developed and launched a web application tailored for medical offices and clinics, facilitating appointment bookings and customer club management while providing comprehensive reporting for CEOs. As the product owner, I led R&D efforts and oversaw development. This application has a freemium model, offering basic features for free with optional monetized features.",
        FrontEnd: ['ReactJS'],
        BackEnd: ['ASPDotNet'],
        DB: ['SQLDB'],
        releaseDate: "Nov 2019",
        webSite: "http://www.0ntime.ir/"
    }
    // ,
    // {
    //     title: "",
    //     projectName:"Information in 1 System",
    //     description: "This system helps you store and keep your Information in together",
    //     FrontEnd: ['ReactJS'],
    //     BackEnd: ['ASPDotNet'],
    //     DB: ['ElasticSearch', 'SQLDB'],
    // },
    // {
    //     title: "",
    //     projectName:"SEPAND DENTAL OFFICE",
    //     FrontEnd: ['ReactJS'],
    //     BackEnd: ['ASPDotNet'],
    //     DB: ['SQLDB'],
    //     releaseDate: "Nov 2019",
    //     webSite: "http://sepanddental.com/"
    // }
]

export const languageData = [
    {
        id: "ASPDotNet",
        title: "Microsoft ASP.Net",
        webSite: "https://docs.microsoft.com/en-us/aspnet/core/?view=aspnetcore-6.0",
    },
    {
        id: "ASPDotNetCore",
        title: "Microsoft ASP.Net Core",
        webSite: "https://docs.microsoft.com/en-us/aspnet/core/?view=aspnetcore-3.1",
    },
    {
        id: "ASPDotNetFramework",
        title: "Microsoft ASP.Net Framework",
        webSite: "https://docs.microsoft.com/",
    },
    {
        id: "Bootstrap",
        title: "Bootstrap",
        webSite: "https://getbootstrap.com/",
    },
    {
        id: "CSS",
        title: "CSS",
        webSite: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    },
    {
        id: "CWindowsForm",
        title: "Microsoft C# Windows Form",
        webSite: "https://docs.microsoft.com/",
    },
    {
        id: "ElasticSearch",
        title: "Elastic Search",
        webSite: "https://www.elastic.co/",
    },
    {
        id: "HTML",
        title: "HTML",
        webSite: "https://html.com/",
    },
    {
        id: "Ionic",
        title: "Ionic",
        webSite: "https://ionicframework.com/",
    },
    {
        id: "JavaScript",
        title: "Java Script",
        webSite: "https://www.javascript.com/",
    },
    {
        id: "JQuery",
        title: "jQuery",
        webSite: "https://jquery.com/",
    },
    {
        id: "ReactJS",
        title: "React JS",
        webSite: "https://reactjs.org/",
    },
    {
        id: "ReactNative",
        title: "React Native",
        webSite: "https://reactnative.dev/",
    },
    {
        id: "SQLDB",
        title: "Microsoft SQL Server",
        webSite: "https://www.microsoft.com/en-us/sql-server",
    },

]