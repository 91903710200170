import React from 'react'
import Chip from '@material-ui/core/Chip';

const Button = (props) => {
    return (
        props.label ? (
            <Chip
                avatar={props.avatar?props.avatar:null}
                variant={props.variant ?? "default" == "outlined" ? "outlined" : "default"}
                className="myButton"
                size="large"
                icon={props.icon}
                deleteIcon={props.deleteIcon ? props.deleteIcon : null}
                label={props.label}
                disabled={props.disabled}
                onClick={props.click ? () => props.click() : null}
                onDelete={props.deleteIcon ? () => props.delete() : null}
            />
        ) : (
            <Chip
                variant={props.variant ?? "default" == "outlined" ? "outlined" : "default"}
                className="myButtonNoIcon"
                size="large"
                icon={props.icon}
                disabled={props.disabled}
                onClick={props.click ? () => props.click() : null}
            />
        )
    )
}
export default Button
