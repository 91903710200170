import React from 'react';
import { getLocalizationTitleForInput } from '../localization/localization';
import GridColumnList from './gridColumnList.component';

const GridRowList = (props) => {
     function open() {
          props.open(props.rowData.id)
     }
     function handleDelete() {
          props.delete(props.rowData.id)
     }

     return (
          <div onClick={() => props.dataFromRow(props.rowData)}>
               {props.gridCouumn.filter(o => o.showList).map(coulmn => (
                    <GridColumnList
                         type={coulmn.type}
                         enums={coulmn.enums}
                         listSelect={coulmn.listSelect}
                         coulmn={props.rowData[coulmn.title]}
                         label={getLocalizationTitleForInput(coulmn)}
                         open={open}
                         delete={handleDelete}
                         noDelete={props.noDelete}
                         noEdit={props.noEdit}
                         isActive={props.isActive}
                    />
               ))}
               {props.gridCouumn.filter(o => !(o.showList))?.length ?
                    <details>
                         <summary>جزئیات</summary>
                         <p>
                              {props.gridCouumn.filter(o => !(o.showList)).map(coulmn => (
                                   <GridColumnList
                                        type={coulmn.type}
                                        enums={coulmn.enums}
                                        listSelect={coulmn.listSelect}
                                        coulmn={props.rowData[coulmn.title]}
                                        label={getLocalizationTitleForInput(coulmn)}
                                        open={open}
                                        delete={handleDelete}
                                        noDelete={props.noDelete}
                                        noEdit={props.noEdit}
                                        isActive={props.isActive}
                                   />
                              ))}
                         </p>
                    </details>
                    :
                    ""
               }
          </div>
     );
}
export default GridRowList;
