import entityTemplateDto from '../entityTemplateDto'

export const VisitorTemplate: entityTemplateDto[] = [
    {
        title: "nationalCode",
        type: "text",
        label: "کد ملی",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 10 }, { type: "MinLength", length: 10 }, { type: "Required" }],
        showForm: true
    },
    {
        title: "specialty",
        type: "text",
        label: "تخصص",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "firstName",
        type: "text",
        label: "نام",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "lastName",
        type: "text",
        label: "نام خانوادگی",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "fatherName",
        type: "text",
        label: "نام پدر",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "birthDate",
        type: "date",
        label: "تاریخ تولد",
        filter: {},
        showList: 1,
        minWidth: "130px",
        validations:[{type:"Required"}],
        showGrid: true,
        showForm: true
    },
    {
        title: "cellPhoneNumber",
        type: "text",
        label: "شماره تماس",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"}],
        showGrid: true,
        showForm: true
    },
    {
        title: "homePhoneNumber",
        type: "text",
        label: "شماره منزل",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"}],
        showForm: true
    },
    {
        title: "address",
        type: "text",
        label: "آدرس",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "MaxLength", length: 50 }],
        showForm: true
    },
    {
        title: "imageFileData",
        type: "image",
        fileId:"imageId",
        label: "تصویر",
        filter: {},
        showList: 0,
        minWidth: "130px",
        validations: [{ type: "fileType", types:"jpg jpeg" }],
        showForm: true,
        showGrid: true,
    },
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        filter: {},
        minWidth: "80px",
        showGrid: true
    }
];