import entityTemplateDto from '../entityTemplateDto'

export const CustomerTemplate: entityTemplateDto[] = [
    {
        title: "code",
        type: "text",
        label: "شماره",
        filter: {},
        showList: 1,
        minWidth: "100px",
        validations: [{ type: "MaxLength", length: 50 }],
        showGrid: true,
        showForm: true
    },
    {
        title: "firstName",
        type: "text",
        label: "نام",
        filter: {},
        showList: 1,
        minWidth: "110px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "lastName",
        type: "text",
        label: "نام خانوادگی",
        filter: {},
        showList: 1,
        minWidth: "110px",
        validations: [{ type: "MaxLength", length: 50 }, { type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "cellPhoneNumber",
        type: "text",
        label: "شماره همراه",
        filter: {},
        showList: 1,
        minWidth: "100px",
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"},{ type: "Required" }],
        showGrid: true,
        showForm: true
    },
    {
        title: "cardNumber",
        type: "text",
        label: "شماره کارت",
        filter: {},
        showList: 1,
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 20 }/*, { type: "Required" }*/],
        showGrid: false,
        showForm: true
    },






    {
        title: "colorIonicId",
        type: "enumColor",
        label: "رنگ",
        filter: {},
        minWidth: "50px",
        showFormDetail: true,
        enums: [
            { value: 0, text: "" },
            { value: 4, text: "white" },
            { value: 5, text: "blue" },
            { value: 6, text: "green" },
            { value: 3, text: "yellow" },
            { value: 2, text: "red" },
            { value: 7, text: "gray" },
        ],
        showList: 1,
        showGrid: true
    },
    {
        title: "nationalCode",
        type: "text",
        label: "کد ملی",
        filter: {},
        minWidth: "70px",
        validations: [{ type: "MaxLength", length: 10 }, { type: "MinLength", length: 10 }],
        showFormDetail: true
    },
    {
        title: "fatherName",
        type: "text",
        label: "نام پدر",
        filter: {},
        validations: [{ type: "MaxLength", length: 50 }],
        showFormDetail: true
    },
    {
        title: "birthDate",
        type: "date",
        label: "تاریخ تولد",
        filter: {},
        showFormDetail: true
    },
    {
        title: "homePhoneNumber",
        type: "text",
        label: "شماره منزل",
        filter: {},
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"}],
        showFormDetail: true
    },
    {
        title: "workPhoneNumber",
        type: "text",
        label: "شماره محل کار",
        filter: {},
        validations: [{ type: "MaxLength", length: 11 }, { type: "MinLength", length: 11 },{type:"JustNumber"}],
        showFormDetail: true
    },
    {
        title: "address",
        type: "text",
        label: "آدرس",
        filter: {},
        validations: [{ type: "MaxLength", length: 50 }],
        showFormDetail: true
    },
    {
        title: "genderId",
        type: "enum",
        label: "جنسیت",
        filter: {},
        showFormDetail: true,
        enums: [
            { value: 1, text: "مرد" },
            { value: 2, text: "خانم" },
        ]
    },
    {
        title: "maritalId",
        type: "enum",
        label: "وضعیت تاهل",
        filter: {},
        showFormDetail: true,
        enums: [
            { value: 1, text: "مجرد" },
            { value: 2, text: "متاهل" },
        ]
    },
    {
        title: "educationId",
        type: "enum",
        label: "مقطع تحصیلی",
        filter: {},
        showFormDetail: true,
        enums: [
            { value: 1, text: "متحصل" },
            { value: 2, text: "دبیرستان" },
            { value: 3, text: "دیپلم" },
            { value: 4, text: "فوق دیپلم" },
            { value: 5, text: "کارشناسی" },
            { value: 6, text: "فوق کارشناسی" },
            { value: 7, text: "دکترا" },
        ]
    },
    {
        title: "field",
        type: "text",
        label: "رشته تحصیلی",
        filter: {},
        validations: [{ type: "MaxLength", length: 20 }],
        showFormDetail: true
    },
    {
        title: "job",
        type: "text",
        label: "شغل",
        filter: {},
        validations: [{ type: "MaxLength", length: 20 }],
        showFormDetail: true
    },
    {
        title: "bankCard",
        type: "شماره کارت بانکی",
        label: "شغل",
        filter: {},
        validations: [{ type: "MaxLength", length: 16 }, { type: "MinLength", length: 16 }],
        showFormDetail: true
    },
    /*
presenterId
    */
    {
        title: "editColumn",
        type: "editColumn",
        label: "ویرایش",
        showList: 1,
        filter: {},
        minWidth: "80px",
        showGrid: true,
    },
    {
        title: "visitTime",
        type: "editColumnCustom",
        showList: 1,
        label: "ملاقات",
        filter: {},
        minWidth: "80px",
        showGrid: true,
    },
    {
        title: "image",
        type: "editColumnCustom",
        showList: 1,
        label: "تصاویر",
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }/*,
    {
        title: "contact",
        type: "editColumnCustom",
        showList: 1,
        label: "تماس",
        filter: {},
        minWidth: "80px",
        showGrid: true,
    }*/
];