import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../button/button';

const FormDynamicInputImage = (props) => {
  const { setInputsValue } = props;
  const [imagePreview, setImagePreview] = useState(props.value ? `data:image/jpeg;base64,${props.value}` : "");

  const sendValueToInputs = (value) => {
    //console.log('FormDynamicInputImage',value, props.id,props.fileId,props.id,(props.fileId ? props.fileId : (props.id + "Id")))
    setInputsValue(value, props.id)
    setInputsValue(0, (props.fileId ? props.fileId : (props.id + "Id")))
  }
  const deleteFile = () => {
    setInputsValue(null, props.id)
    setInputsValue(null, (props.fileId ? props.fileId : (props.id + "Id")))
    setImagePreview("")
  }

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    onChange(file)
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file);
    }
  }

  const onChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded
      reader.readAsBinaryString(file)
    }
  }

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;

    sendValueToInputs(btoa(binaryString).toString())
  }

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    //console.log('props.value', props.value)
    if (props.value) {
      setImagePreview(`data:image/jpeg;base64,${props.value}`)
    }
    return () => {
      return "";
    }
  }, [props.value]);

  return (
    <div className={(props.className ?? "col-12 col-sm-12")} >
      <div className="FormDynamicInputImageContainer" >
        <div className="FormDynamicInputImageInputItem" >
          <TextField
            autoFocus={props.autoFocus}
            fullWidth
            size="small"
            type="file"
            accept=".jpeg, .png, .jpg"
            id={props.id}
            label={props.title}
            onChange={photoUpload}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled || props.loading}
          />
        </div>
        {imagePreview ? (
          <div>
              <Button 
              icon={<DeleteIcon />}
              click={() => deleteFile()} 
              />
          </div>
        ) : ("")}
      </div>
      {imagePreview ? (
        <div className="FormDynamicInputImageItem" >
          <img src={imagePreview} />
        </div>
      ) : ("")}
    </div>
  );
}

export default FormDynamicInputImage;