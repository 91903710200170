import React, { useEffect, useState, useRef } from 'react'
import { organizationId } from '../../services/organizationConfig'
import './platformMajMovePictures.css'
import { useSelector } from 'react-redux'

const PlatformMajMovePictures = (props) => {
    const innerSize = useSelector((state) => state.windowInnerSize.value)

    const [leftItem, setLeftItem] = useState(0)
    const [side, setSide] = useState(-1)
    const [lengthItems, setLengthItems] = useState(((props.item.descriptions.length * (props.item.width + props.item.distanceWidth)) - innerSize.width +( props.item.distanceWidth*2)))

    const setId = () => {
        return `pmMovePictures${props.item.id}`;
    };

    const getWidth = () => {
        return (innerSize.width <500 ? props.item.widthPhone: props.item.width )
    };

    function updateTime() {
        if ((side === -1 && leftItem === (lengthItems * side)) || (side === 1 && leftItem === 0)) {
            setSide(side * -1)
        }
        else {
            setLeftItem(leftItem + side)
        }
    }

    useEffect(() => {
        const token = setTimeout(updateTime, props.item.distanceTime)
        return () => {
            clearTimeout(token);
            return "";
        }
    }, [leftItem, side]);

    return (
        <section id={setId()} className="pmMovePictures homeSection homeSectionColor">
            <div>
                <header>
                    <h2 className="homeSectionHeader">
                        {props.item.title}
                    </h2>
                </header>
                <article className='pmMovePicturesContainer'>
                    {props.item.descriptions.map((item, index) => (
                        <div className='pmMovePicturesItem' style={{ left: leftItem, marginRight: props.item.distanceWidth }}>
                            <img
                                style={{ width: props.item.width }}
                                src={process.env.PUBLIC_URL + `organization${organizationId()}/${props.item.id}/${index}${props.item.fileFormat}`}
                            />
                            <div className="pmMovePicturesItemDescription" >
                                <h3>{item}</h3>
                            </div>
                        </div>
                    ))}
                </article>
            </div>
        </section>
    )
}
export default PlatformMajMovePictures
