import React, { useEffect, useState } from 'react'
import { organizationId } from '../../../services/organizationConfig';
import { getLocalizationTitle, getLocalizationTitleByData } from '../../localization/localization';
import { featuresData } from './featuresData';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
const FeaturesOntime = (props) => {

    useEffect(() => {
        return () => {
            return "";
        }
    }, []);

    function getCheckIcon(input) {
        if (input) {
            return (<CheckIcon />)
        }
        else {
            return (<CloseIcon />)
            
        }
    }

    return (
        <section id="homeFeatures" className="homeFeatures homeSection homeSectionColor">
            <div>
                <header>
                    <h2 className="homeSectionHeader">
                        {getLocalizationTitle("Features")}
                    </h2>
                </header>
                <article className="homeFeaturesContainer">
                    {/*  <div className="homeFeaturesItem">
                        <img src={process.env.PUBLIC_URL + `organization${organizationId()}/features.jpg`}></img>
                    </div>*/}
                    <div className="homeFeaturesItem">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "150px" }}>{getLocalizationTitle("Feature")}</th>
                                    <th style={{ minWidth: "100px", textAlign: "center" }}>{getLocalizationTitle("BasicVersion")}</th>
                                    <th style={{ minWidth: "100px", textAlign: "center" }}>{getLocalizationTitle("ProVersion")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {featuresData.map(row => (
                                    <tr>
                                        <td>{getLocalizationTitleByData(row.feature)}</td>
                                        <td style={{ textAlign: "center" }}>{getCheckIcon(row.BasicVersion)}</td>
                                        <td style={{ textAlign: "center" }}>{getCheckIcon(row.ProVersion)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </article>
            </div>
        </section>
    );
}
export default FeaturesOntime