import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../button/button';

export default function QuestionDialog(props) {
    const { questionState } = props;

    const handleFalse = () => {
        props.handleFalse();
    };
    const handleTrue = () => {
        props.handleTrue();
    };

    return (
        <Dialog open={questionState.open} >
            <DialogTitle>{questionState.content}</DialogTitle>
            <DialogActions>
                <Button click={handleFalse} label={questionState.falseButton}>
                </Button>
                <Button click={handleTrue} label={questionState.trueButton} autoFocus>
                </Button>
            </DialogActions>
        </Dialog>
    );
}