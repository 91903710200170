import React, { useEffect, useState } from 'react'
import GridContainer from '../../grid/gridContainer.component'
import VisitTimeService from '../../../services/visitTime.service'
import { VisitTimeTemplate } from './visitTimeTemplate'
import { useSnackbar } from 'notistack';
import VisitTime from './visitTime'
import Dialog from '@material-ui/core/Dialog';
import Button from '../../button/button';
import { GetDirection, getLocalizationTitle } from '../../localization/localization';
import BackButton from '../../button/backButton';

const VisitTimes = (props) => {

    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({ text: "", isActive: true });
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        props.close();
    };

    const [stateVisitTime, setStateVisitTime] = useState({ state: false, id: 0 });

    function openVisitTime(id) {
        setStateVisitTime({ state: true, id: id, customerId: props.state.customerId })
    }
    function handleDelete(id) {
        VisitTimeService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10, props.state.customerId)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const [refresh, setRefresh] = useState(0);
    function closeVisitTime(id) {
        //console.log('closeVisitTime',id)
        setStateVisitTime({ state: false, id: 0, customerId: 0 });

        if (id) {
            setRefresh(refresh + 1)
        }
    }


    const getAll = (skipCount, maxResultCount, customerId) => {
        setTotal(-1);
        VisitTimeService.GetAll(customerId, skipCount, maxResultCount, search.text, search.isActive).then(
            response => {
                //console.log('response.data.result.items', response.data.result.items)
                setContent(response.data.result.items.map((item, index) => ({
                    id: item.id,
                    date_Time: item.date_Time,
                    axpenseAmount: item.axpenseAmount,
                    discountAmount: item.discountAmount,
                    totalAmount: item.totalAmount,
                    description: item.description,
                    visitor: item.visitor.firstName + ' ' + item.visitor.lastName,
                })));
                setTotal(response.data.result.totalCount)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        if (props.state.customerId) {
            getAll(skipCount, maxResultCount, props.state.customerId)
        }
    };

    useEffect(() => {
        //console.log('useEffect', 'props.state.customerId', props.state.customerId)
        if (props.state.customerId) {
            getAll(0, 10, props.state.customerId)
        }
        return () => {
            return "";
        }
    }, [search, refresh, props.state]);

    return (

        <Dialog fullWidth={true} open={props.state.state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => handleClose()}
                />
                <div>لیست ملاقات ها</div>
                <div></div>
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                <div>
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={VisitTimeTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        open={openVisitTime}
                        delete={handleDelete}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Customers")} / ${getLocalizationTitle("Meeting")}`}
                    />
                    <VisitTime
                        stateVisitTime={stateVisitTime}
                        closeVisitTime={closeVisitTime}
                    />
                </div>
            </div>
        </Dialog>

    );
}

export default VisitTimes


