import { isExpired, setUserTheme, userTheme } from "../../services/auth-header";
import { organizationId } from "../../services/organizationConfig";

export const DefaultTheme = () => {
    if (organizationId()===9) {
        return 5;
    }
    return 1;
}

export const SetTheme = (input) => {

    localStorage.setItem("Theme", input);
    if (!isExpired()) {
        setUserTheme(input)
    }
}

export const GetTheme = () => {
    if (isExpired()) {
        return GetThemeGuest();
    }
    else {
        return userTheme();
    }
}

export const GetThemeGuest = () => {
    let theme = localStorage.getItem('Theme')
    if (theme) {
        return theme;
    }
    else {
        localStorage.setItem("Theme", DefaultTheme());
        return DefaultTheme();
    }
}

export const themeList = () => {
    return [
        { value: 1, text: "#707070" },
        { value: 2, text: "#000000" },
        { value: 3, text: "#8aacc8" },
        { value: 4, text: "#97b498" },
        { value: 5, text: "#a094b7" },
    ]
}