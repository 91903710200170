import decode from 'jwt-decode';

function getUserFromLocalStorage() {
  return JSON.parse(localStorage.getItem('user'));
}

export default function authHeader() {
  const user = getUserFromLocalStorage();
  if (user && user.result.accessToken) {
    return { Authorization: 'Bearer ' + user.result.accessToken };
  } else {
    return {};
  }
}

export function isExpired() {
  const user = getUserFromLocalStorage();
  if (user && user?.result?.accessToken) {
    var decodedToken = decode(user.result.accessToken, { complete: true });

    if ((decodedToken.exp * 1000) - 60000 <= Date.now()) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }
}

export function userImage() {
  const user = getUserFromLocalStorage();
  if (user && user?.result?.accessToken) {
    return user.result.image;
  }
  else {
    return null;
  }
}

export function userTheme() {
  const user = getUserFromLocalStorage();
  if (user && user?.result?.accessToken) {
    return user.result.theme;
  }
  else {
    return null;
  }
}

export function userLanguage() {
  const user = getUserFromLocalStorage();
  if (user && user?.result?.accessToken) {
    return user.result.language;
  }
  else {
    return null;
  }
}

export function userPermissions() {
  const user = getUserFromLocalStorage();
  if (user && user?.result?.accessToken) {
    return user.result.permissions;
  }
  else {
    return null;
  }
}

export function setUserLanguage(lng) {
  const user = getUserFromLocalStorage();

  if (user && user?.result?.accessToken) {

    user.result.language = lng;

    localStorage.setItem("user", JSON.stringify(user));
  }
}

export function setUserTheme(input) {
  const user = getUserFromLocalStorage();

  if (user && user?.result?.accessToken) {

    user.result.theme = input;

    localStorage.setItem("user", JSON.stringify(user));
  }
}
