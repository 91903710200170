import React, { useEffect } from 'react'
import './home.css'
import { organizationId } from '../../services/organizationConfig';
import HomeSepand from './sepand/HomeSepand'
import HomeOntime from './ontime/HomeOntime'
import HomeGhajari3 from './ghajari3/homeGhajari3'
import HomeProduct from './product/HomeProduct';
import HomeMehrnaz from './mehrnaz/HomeMehrnaz';

const Home = (props) => {
    useEffect(() => {
        return () => {
            return "";
        }
    }, []);

    switch (organizationId()) {
        case 2:
            return (
                <HomeSepand />
            );
        case 3:
            return (
                <HomeGhajari3 />
            );
        case 4:
            return (
                <HomeOntime />
            );
        case 8:
            return (
                <HomeProduct />
            );
        case 9:
            return (
                <HomeMehrnaz />
            );
        default:
            return (
                <div>
                </div>
            );
    }
}
export default Home
