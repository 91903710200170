import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const FormDynamicInputSelect = (props) => {
    const [value, setValue] = useState(props.value ? props.value : "");
    const { setInputsValue } = props;

    const sendValueToInputs = (value) => {
        setValue(value)
        setInputsValue(value, props.id)
    }

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    return (
        <div className={(props.className ?? "col-12 col-sm-12")}   >
            <TextField style={{ padding: "5px" }}
                fullWidth
                size="small"
                select
                id={props.id}
                label={props.title}
                value={value}
                onChange={e => sendValueToInputs(e.target.value)}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled || props.loading}
            >
                {props.listSelect
                    .map((item) => (
                        <MenuItem
                            key={item.index}
                            value={item.value}
                            style={{backgroundColor:(props.isEnumColor?item.text:null)}}
                        >
                            {item.text}
                        </MenuItem>
                    ))}
            </TextField>
        </div>
    );
}

export default FormDynamicInputSelect;