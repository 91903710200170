import React, { useEffect } from 'react'
import { Switch } from "react-router-dom";

const Organization = (props) => {

    useEffect(() => {

    }, []);

    return (
        <div>
            <div style={{height: "50px"}}></div>
            <Switch>
               <div>
               Organization
               </div>
            </Switch>
        </div>
    )
}

export default Organization
