import React, { useEffect, useState } from 'react'
import GridContainer from '../../../grid/gridContainer.component'
import CustomerFileService from '../../../../services/customerFile.service'
import { CustomerImageTemplate } from './customerImageTemplate'
import { useSnackbar } from 'notistack';
import CustomerImage from './customerImage'
import Dialog from '@material-ui/core/Dialog';
import Button from '../../../button/button';
import HomeImage from '../../../home/sepand/homeImageSepand';
import PhotoIcon from '@material-ui/icons/Photo';
import { GetDirection, getLocalizationTitle } from '../../../localization/localization';
import BackButton from '../../../button/backButton';

const CustomerImages = (props) => {

    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(-1);
    const [search, setSearch] = useState({ text: "", isActive: true });
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        props.close();
    };

    const [stateCustomerImage, setStateCustomerImage] = useState({ state: false, id: 0 });
    const [refresh, setRefresh] = useState(0);
    function openCustomerImage(id) {

        setStateCustomerImage({ state: true, id: id, customerId: props.state.customerId })
    }
    function closeCustomerImage(id) {
        setStateCustomerImage({ state: false, id: 0, customerId: 0 });
        if (id) {
            setRefresh(refresh + 1)
        }
    }

    function handleDelete(id) {
        CustomerFileService.Delete(id).then(
            (response) => {
                if (response.success) {
                    enqueueSnackbar('حذف شد', { variant: 'success' })
                    getAll(0, 10, props.state.customerId)
                }
                else {
                    enqueueSnackbar(response.response, { variant: 'error' })
                }
            }
        );
    }

    const getAll = (skipCount, maxResultCount, customerId) => {
        setTotal(-1);
        CustomerFileService.GetAll(skipCount, maxResultCount, search.text, 2, search.isActive, customerId).then(
            response => {
                //setContent(response.data.result.items);
                setContent(response.data.result.items.map((item, index) => ({
                    id: item.id,
                    takeDateTime: item.takeDateTime,
                    fileData: item.fileData,
                    fileId: item.fileId,
                    image: (
                        <div style={{ display: "inline-block" }}>
                            <Button icon={<PhotoIcon />} click={() => openImage(item.parentId)} />
                        </div>
                    ),
                })));
                setTotal(response.data.result.totalCount)
            },
            error => {
                enqueueSnackbar('خطا در دریافت لیست، اینترنت خود را بررسی نمایید', { variant: 'error' })
            }
        );
    }

    const dataFromGrid = (skipCount, maxResultCount) => {
        if (props.state.customerId) {
            getAll(skipCount, maxResultCount, props.state.customerId)
        }
    };

    const [imageState, setImageState] = useState({ state: false, id: 0 });
    const openImage = (parentId) => {
        setImageState({ state: true, id: parentId })
    }
    const closeImage = () => {
        setImageState({ state: false, id: 0 })
    }

    useEffect(() => {
        //console.log('useEffect', 'props.state.customerId', props.state.customerId)
        if (props.state.customerId) {
            getAll(0, 10, props.state.customerId)
        }
        return () => {
            return "";
        }
    }, [search, refresh, props.state]);

    return (

        <Dialog fullWidth={true} open={props.state.state}>
            <div className="dialogHeader" dir={GetDirection()}>
                <Button
                    icon={<BackButton />}
                    click={() => handleClose()}
                />
                <div>لیست  تصاویر مراجعه کننده</div>
                <div></div>
            </div>
            <div className="dialogContent" dir={GetDirection()}>
                <div>
                    <GridContainer
                        gridData={content}
                        total={total}
                        gridSort={[{ field: "DueDate", dir: "desc" }]}
                        gridCouumn={CustomerImageTemplate}
                        dataFromGrid={dataFromGrid}
                        setTotal={setTotal}
                        setSearch={setSearch}
                        search={search}
                        open={openCustomerImage}
                        delete={handleDelete}
                        noEdit={true}
                        title={`${getLocalizationTitle("Dashboard")} / ${getLocalizationTitle("Customers")} / ${getLocalizationTitle("Images")}`}
                    />
                    <CustomerImage
                        state={stateCustomerImage}
                        close={closeCustomerImage}
                    />
                    <HomeImage
                        state={imageState}
                        close={closeImage}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default CustomerImages


