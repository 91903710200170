import React, { useEffect } from 'react'
import './App.css';
import '../node_modules/react-grid-layout/css/styles.css'
import Routing from './routing/Routing';

const App = (props) => {

  useEffect(() => {
  }, []);

  return (
    <Routing />
  )
}
export default App
