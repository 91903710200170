import platformMajAnimationLogoDto from '../../platformMaj/platformMajAnimationLogoDto'

export const homeGhajari3TopTemplate: platformMajAnimationLogoDto =
{
    id: "homeTop",
    length: 15,
    distance: 100,
    fileFormat: '.png',
    withDescription: true,
    descriptionType: 1,
    descriptions: [
        { start: 0, description: "<h1>Majid Ghajari</h1>" },
        // { start: 150, description: "<h2>Senior Software Developer</h2>" },
        { start: 150, description: "<h3>Professional skills:</h3>" },
        { start: 100, description: "<ol>" },
        { start: 200, description: "<li>React JS, Material UI</li>" },
        { start: 300, description: "<li>JavaScript, ES5, ES6, Type Script</li>" },
        { start: 450, description: "<li>CSS3, HTML5</li>" },
        { start: 600, description: "<li>Single-page application</li>" },
        { start: 750, description: "<li>Responsive Web Page</li>" },
        { start: 900, description: "<li>C#, DotNet EF</li>" },
        { start: 1050, description: "<li>OOP, DDD</li>" },
        { start: 1200, description: "<li>Design Patterns</li>" },
        { start: 1350, description: "<li>T-SQL, Elasticsearch</li>" },
        { start: 150, description: "</ol>" },
    ]
};