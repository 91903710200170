import React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Button from '../button/button';
import { GetLocalization, getLocalizationTitle } from '../localization/localization';

const GridPaging = (props) => {

    const nextPage = () => {
        let resault = []
        for (let i = props.page + 1; i < props.page + 5; i++) {
            if (i <= props.pages) {
                resault.push(
                    <Button
                        label={i}
                        click={() => props.setPageFromGridPaging(i)}
                        variant="outlined"
                    />
                )
            }
        }
        return resault
    }
    const previousPage = () => {
        let resault = []
        for (let i = props.page - 4; i < props.page; i++) {
            if (i >= 1) {
                resault.push(
                    <Button
                        label={i}
                        click={() => props.setPageFromGridPaging(i)}
                        variant="outlined"
                    />
                )
            }
        }
        return resault
    }
    return (
        <div className="gridPagingContainer">
            <Button
                click={() => props.setPageFromGridPaging(1)}
                icon={GetLocalization() == "fa" ? (<LastPageIcon />) : (<FirstPageIcon />)}
                variant="outlined"
            />
            {previousPage()}
            <Button
                label={props.page}
            />
            {nextPage()}
            <Button
                click={() => props.setPageFromGridPaging(props.pages)}
                icon={GetLocalization() == "fa" ? (<FirstPageIcon />) : (<LastPageIcon />)}
                variant="outlined"
            />
            <Button
                label={(`${getLocalizationTitle("Total")}:${props.total}`)}
            />
        </div>
    );
}
export default GridPaging;
